<template>
    <div
        :class="'bg-' + color + '-300'"
        class="rounded p-4 mt-3 mb-3"
        role="alert"
    >
        <div class="flex items-center justify-end">
            <fa-icon
                v-if="canClose"
                id="close-mark"
                :class="'text-' + color + '-700'"
                class="cursor-pointer"
                icon="times"
                @click="$emit('close')"
            ></fa-icon>
        </div>
        <slot name="body">
            <p :class="'text-' + color + '-700'" class="text-base">
                {{ $t(text) }}
            </p>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'AlertInfo',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        text: {
            type: String,
            default: 'login_error',
        },
        canClose: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
