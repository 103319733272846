<!-- eslint-disable new-cap -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
    <div class="mb-2" id="description-container">
        <quill-editor
            id="editor"
            v-model:content="content"
            theme="snow"
            toolbar="#custom-toolbar"
            :readOnly="readOnly"
            :placeholder="readOnly === false ? $t('write_note') : ''"
            @update="$emit('setDescription', content)"
            contentType="html"
            @textChange="$emit('setDescription', content)"
            @blur="$emit('setDescription', content)"
        >
            <template #toolbar>
                <div id="custom-toolbar" class="w-[791px] rounded-t-lg">
                    <!-- Add buttons as you would before -->
                    <!-- <select class="ql-size">
                        <option value="small"></option>
                        <option selected></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                    </select> -->
                    <select class="ql-header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                    </select>
                    <!-- Add a bold button -->
                    <!-- <select class="ql-font">
                        <option selected></option>
                        <option value="serif"></option>
                        <option value="monospace"></option>
                    </select> -->
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>

                    <select class="ql-color">
                        <option selected="selected"></option>
                        <option value="#e60000"></option>
                        <option value="#ff9900"></option>
                        <option value="#ffff00"></option>
                        <option value="#008a00"></option>
                        <option value="#0066cc"></option>
                        <option value="#9933ff"></option>
                        <option value="#ffffff"></option>
                        <option value="#facccc"></option>
                        <option value="#ffebcc"></option>
                        <option value="#ffffcc"></option>
                        <option value="#cce8cc"></option>
                        <option value="#cce0f5"></option>
                        <option value="#ebd6ff"></option>
                        <option value="#bbbbbb"></option>
                        <option value="#f06666"></option>
                        <option value="#ffc266"></option>
                        <option value="#ffff66"></option>
                        <option value="#66b966"></option>
                        <option value="#66a3e0"></option>
                        <option value="#c285ff"></option>
                        <option value="#888888"></option>
                        <option value="#a10000"></option>
                        <option value="#b26b00"></option>
                        <option value="#b2b200"></option>
                        <option value="#006100"></option>
                        <option value="#0047b2"></option>
                        <option value="#6b24b2"></option>
                        <option value="#444444"></option>
                        <option value="#5c0000"></option>
                        <option value="#663d00"></option>
                        <option value="#666600"></option>
                        <option value="#003700"></option>
                        <option value="#002966"></option>
                        <option value="#3d1466"></option>
                    </select>

                    <select class="ql-background">
                        <option selected="selected"></option>
                        <option value="#e60000"></option>
                        <option value="#ff9900"></option>
                        <option value="#ffff00"></option>
                        <option value="#008a00"></option>
                        <option value="#0066cc"></option>
                        <option value="#9933ff"></option>
                        <option value="#ffffff"></option>
                        <option value="#facccc"></option>
                        <option value="#ffebcc"></option>
                        <option value="#ffffcc"></option>
                        <option value="#cce8cc"></option>
                        <option value="#cce0f5"></option>
                        <option value="#ebd6ff"></option>
                        <option value="#bbbbbb"></option>
                        <option value="#f06666"></option>
                        <option value="#ffc266"></option>
                        <option value="#ffff66"></option>
                        <option value="#66b966"></option>
                        <option value="#66a3e0"></option>
                        <option value="#c285ff"></option>
                        <option value="#888888"></option>
                        <option value="#a10000"></option>
                        <option value="#b26b00"></option>
                        <option value="#b2b200"></option>
                        <option value="#006100"></option>
                        <option value="#0047b2"></option>
                        <option value="#6b24b2"></option>
                        <option value="#444444"></option>
                        <option value="#5c0000"></option>
                        <option value="#663d00"></option>
                        <option value="#666600"></option>
                        <option value="#003700"></option>
                        <option value="#002966"></option>
                        <option value="#3d1466"></option>
                    </select>
                    <button class="ql-list" value="ordered">
                        <!-- <fa-icon icon="list-ul" class="fa-sm" /> -->
                    </button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-list" value="check">
                        <!-- <fa-icon icon="list-ul" class="fa-sm" /> -->
                    </button>

                    <button class="ql-indent" value="+1"></button>
                    <button class="ql-indent" value="-1"></button>
                    <select class="ql-align">
                        <option></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                    <button class="ql-link"></button>
                    <button class="ql-image"></button>

                    <!-- Add subscript and superscript buttons -->
                    <!-- <button class="ql-script" value="sub"></button>
                    <button class="ql-script" value="super"></button>
                    <button class="ql-bold"></button> -->

                    <!-- But you can also add your own -->
                    <span class="flex justify-end items-center">
                        <button
                            id="custom-button"
                            @click.prevent="importFromDoc()"
                            :title="$t('import_from_doc')"
                        >
                            <fa-icon icon="file-arrow-down" class="fa-xs" />
                        </button>
                        |
                        <button
                            id="custom-button"
                            class="ml-2"
                            @click.prevent="saveAsPdf()"
                            :title="$t('save_as_pdf')"
                        >
                            <fa-icon icon="file-pdf" class="fa-xs" />
                        </button>
                        <button
                            id="custom-button"
                            @click.prevent="saveAsWord()"
                            :title="$t('save_as_word')"
                        >
                            <fa-icon icon="file-word" class="fa-xs" />
                        </button>
                    </span>
                </div>
            </template>
        </quill-editor>
        <!-- v-model="content" -->
        <!-- ref="quillEditor" -->
        <!-- :options="editorOption" -->
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill'
import html2pdf from 'html2pdf.js'

export default {
    name: 'QuillEditorCustom',
    components: {
        QuillEditor,
    },
    props: {
        prevContent: {
            type: String,
            default: '',
        },
        clientName: {
            type: String,
            default: '',
        },
        meetingDate: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            content: '',
        }
    },
    mounted() {
        this.content = ''
        this.content = this.prevContent
    },
    watch: {
        prevContent: {
            handler() {
                this.content = this.prevContent
            },
            immediate: true,
        },
    },
    methods: {
        saveAsPdf() {
            const editorContent =
                document.getElementsByClassName('ql-editor')[0]
            const opt = {
                margin: 1,
                filename: 'document.pdf', // `note-${this.clientName}-${this.meetingDate}.pdf`,
                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait',
                },
            }
            html2pdf().set(opt).from(editorContent).save()
        },
        saveAsWord() {
            const header =
                "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
                "xmlns:w='urn:schemas-microsoft-com:office:word' " +
                "xmlns='http://www.w3.org/TR/REC-html40'>" +
                "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>"
            const footer = '</body></html>'
            const sourceHTML =
                header +
                document.getElementsByClassName('ql-editor')[0].innerHTML +
                footer

            const source = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
                sourceHTML
            )}`
            const fileDownload = document.createElement('a')
            document.body.appendChild(fileDownload)
            fileDownload.href = source
            fileDownload.download = 'document.doc' // `note-${this.clientName}-${this.meetingDate}.doc`
            fileDownload.click()
            document.body.removeChild(fileDownload)
        },
        importFromDoc() {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = '.doc, .docx'
            fileInput.click()
            fileInput.onchange = (e) => {
                const file = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const doc = new DOMParser().parseFromString(
                        e.target.result,
                        'text/html'
                    )
                    const content = doc.body.innerHTML
                    this.content = content
                }
                reader.readAsText(file)
            }
        },
    },
}
</script>
<style scoped>
.ql-picker {
    z-index: 20;
}
</style>
