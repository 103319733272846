<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-order"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <form
                    class="mb-2"
                    @keydown.enter.prevent
                    @submit.prevent="
                        $route.params.id ? updateSupplier() : createSupplier()
                    "
                >
                    <div>
                        <div class="mb-2">
                            <span
                                v-if="$route.params.id"
                                class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                            >
                                <span class="items-center custom-link ml-1">
                                    {{ ` - ${formData.Name}` }}
                                </span>
                            </span>
                        </div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('informations') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('company_name') + ' *' }}</label
                                    >
                                    <input
                                        v-model="formData.Name"
                                        @blur="v$.formData.Name.$touch"
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.Name.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >TVA</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.VATIntra"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >Siret</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.Siret"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('comments') }}</label
                                    >
                                    <textarea
                                        type="textarea"
                                        v-model="formData.Information"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('software_type') }}
                                    </label>
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="formData.SoftwareType"
                                        :options="typeOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        label="name"
                                        track-by="name"
                                    ></Multiselect>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('api_url') }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.SoftwareAPI"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('login_only') }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.SoftwareLogin"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <span v-if="needPwdReset">
                                    <div
                                        class="mb-3 items-center flex flex-row"
                                    >
                                        <label
                                            class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                            >{{ $t('password') + ' *' }}</label
                                        >
                                        <div class="relative w-full">
                                            <div
                                                class="absolute inset-y-0 right-0 flex items-center px-2"
                                            >
                                                <icon-button
                                                    class="ml-2 cursor-pointer"
                                                    @click="
                                                        togglePasswordVisibility()
                                                    "
                                                    :icon="passwordVisibility"
                                                    :noStyle="true"
                                                    :iconStyle="'text-zinc-500 pt-2'"
                                                ></icon-button>
                                            </div>
                                            <input
                                                id="passwordInput"
                                                v-model="password"
                                                tabindex="1"
                                                :type="passwordType"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                    </div>
                                    <password-score
                                        :value="password"
                                        class="mt-2 mb-6 justify-center ml-40 mr-4"
                                        @passed="isPasswordStrong = true"
                                        @failed="isPasswordStrong = false"
                                    />
                                    <div
                                        class="mb-3 items-center flex flex-row"
                                    >
                                        <label
                                            class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                            >{{
                                                $t('confirm_password') + ' *'
                                            }}</label
                                        >
                                        <div class="relative w-full">
                                            <div
                                                class="absolute inset-y-0 right-0 flex items-center px-2"
                                            >
                                                <icon-button
                                                    @click="
                                                        togglePasswordVisibility()
                                                    "
                                                    :icon="passwordVisibility"
                                                    class="ml-2 cursor-pointer"
                                                    :noStyle="true"
                                                    :iconStyle="'text-zinc-500 pt-2'"
                                                ></icon-button>
                                            </div>
                                            <input
                                                id="confirmPasswordInput"
                                                tabindex="2"
                                                v-model="confirmPassword"
                                                :type="passwordType"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />

                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$
                                                    .confirmPassword.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{
                                                        $t(
                                                            'passwords_dont_match'
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="$route.params.id"
                                        class="flex justify-center"
                                    >
                                        <button
                                            type="button"
                                            @click="needPwdReset = false"
                                            class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                        >
                                            {{
                                                `${$t('cancel')} ${$t(
                                                    'pw_reset'
                                                )}`
                                            }}
                                        </button>
                                    </div>
                                </span>
                                <div class="flex justify-center" v-else>
                                    <button
                                        type="button"
                                        class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                        @click="needPwdReset = !needPwdReset"
                                    >
                                        {{ $t('reset_pw_modal_title') }}
                                    </button>
                                </div>
                            </div>
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('postal_address') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('address') + ' 1 *' }}
                                    </label>
                                    <input
                                        v-model="formData.Address1"
                                        id="autocomplete"
                                        @blur="v$.formData.Address1.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.Address1
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('address') }} 2</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.Address2"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('postal_code') + ' *' }}</label
                                    >
                                    <input
                                        id="postal_code"
                                        autocomplete="postal-code"
                                        v-model="formData.PostalCode"
                                        @blur="v$.formData.PostalCode.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.PostalCode
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('city') + ' *' }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.City"
                                        id="locality"
                                        @blur="v$.formData.City.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.City.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('contact') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('firstname') }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.ContactFirstName"
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('lastname') }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.ContactLastName"
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('email_address') }}</label
                                    >
                                    <input
                                        type="email"
                                        autocomplete="email"
                                        v-model="formData.ContactEmail"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('phone') }}</label
                                    >
                                    <input
                                        type="tel"
                                        v-model="formData.ContactPhone"
                                        autocomplete="tel"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('billing') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('prov_of_service') }}</label
                                    >
                                    <input
                                        type="text"
                                        disabled
                                        :placeholder="$t('eur_per_h')"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('pricing_per_km') }}</label
                                    >
                                    <input
                                        type="number"
                                        disabled
                                        :placeholder="$t('eur_per_km')"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold text-sm text-gray-600 basis-1/4 pb-1 mr-2 block"
                                        >{{ $t('pricing_per_tour') }}</label
                                    >
                                    <input
                                        type="number"
                                        disabled
                                        :placeholder="$t('eur_per_step')"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="
                                        creating ||
                                        (needPwdReset === true &&
                                            !isPasswordStrong)
                                    "
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>
</template>
<script>
import IconButton from '@/components/atoms/IconButton.vue'
import keys from '@/resources/keys'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required, sameAs } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { ref } from 'vue'

import PasswordScore from '@/components/atoms/PasswordScore.vue'
import BackTo from '@/components/elements/BackTo.vue'
import googleScriptLoader from '@/mixins/googleScript'
import { useSupplierStore } from '@/stores/supplierStore'
import { useUserStore } from '@/stores/userStore'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)
const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
)
export default {
    name: 'CreateOrUpdateSupplier',
    components: { Multiselect, IconButton, PasswordScore, BackTo },
    mixins: [googleScriptLoader],

    setup() {
        const isPasswordStrong = ref(false)
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]

        return { key, isPasswordStrong, v$: useVuelidate() }
    },

    async mounted() {
        window.scrollTo(0, 0)
        this.currentSupplier = null

        this.currentSupplierInterval = setInterval(() => {
            if (this.currentSupplier) {
                clearInterval(this.currentSupplierInterval)
                this.needPwdReset = false
                this.formData = {
                    Id: this.currentSupplier.id,
                    Name: this.currentSupplier.name,
                    VATIntra: this.currentSupplier.vat,
                    Address1: this.currentSupplier.address,
                    Address2: this.currentSupplier.address2,
                    Country: this.currentSupplier.country,
                    PostalCode: this.currentSupplier.postal_code,
                    City: this.currentSupplier.city,
                    ContactFirstName: this.currentSupplier.contact_first,
                    ContactLastName: this.currentSupplier.contact_last,
                    ContactEmail: this.currentSupplier.contact_email,
                    ContactPhone: this.currentSupplier.contact_phone,
                    Siret: this.currentSupplier.registration_number,
                    Information: this.currentSupplier.info,
                    SoftwareType: this.currentSupplier.software_type,
                    SoftwareAPI: this.currentSupplier.software_api,
                    SoftwareLogin: this.currentSupplier.login,
                    // SoftwarePassword: '',
                    CreationDate: this.currentSupplier.creation_date,
                    CreationUserId: this.currentSupplier.creation_user_id,
                    ModificationDate: this.currentSupplier.modification_date,
                    ModificationUserId:
                        this.currentSupplier.modification_user_id,
                }
            }
        }, 500)
        this.loadGoogleScript('autocomplete')
    },
    data() {
        return {
            password: '',
            needPwdReset: true,
            confirmPassword: '',
            passwordType: 'password',
            passwordVisibility: 'eye',
            creating: false,
            formData: {
                Name: '',
                VATIntra: '',
                Address1: '',
                Address2: '',
                Country: 'FRA',
                PostalCode: '',
                City: '',
                ContactFirstName: '',
                ContactLastName: '',
                ContactEmail: '',
                ContactPhone: '',
                Siret: '',
                Information: '',
                SoftwareType: 'biiim',
                SoftwareAPI: '',
                SoftwareLogin: '',
                SoftwarePassword: '',
                CreationUserId: 1,
                CreationDate: '',
                ModificationUserId: '',
                ModificationDate: '',
            },
            currentSupplierInterval: null,
            typeOptions: [
                { name: 'Becycle', value: 'becycle' },
                { name: 'Biiim', value: 'biiim' },
            ],
        }
    },
    // TODO arreter de bloquer la soumission avec la forcedu mdp
    // TODO ajouter un bouton pour si update , modifier le mdp ?
    // TODO vérifier si le mdp est màj si on ne passe pas le champ
    validations() {
        return {
            formData: {
                Name: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode
                    ),
                },
                City: { required },
                ContactEmail: { email },
                ContactPhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
            },
            password: {
                required,
            },
            confirmPassword: { sameAs: sameAs(this.password) },
        }
    },

    computed: {
        ...mapWritableState(useSupplierStore, {
            currentSupplier: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    methods: {
        ...mapActions(useSupplierStore, ['create', 'update']),
        back() {
            this.$router.push({ name: 'suppliers' })
            this.$emit('back', 'suppliers')
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
            })
        },
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        async createSupplier() {
            this.creating = true
            this.formData.CreationUserId = this.user.id
            this.formData.ModificationUserId = this.user.id
            this.formData.SoftwarePassword = this.password
            const pwOk =
                (await this.v$.password.$validate()) &&
                (await this.v$.confirmPassword.$validate())
            if (pwOk === true) {
                const isFormCorrect = await this.v$.$validate()

                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (isFormCorrect === true) {
                    try {
                        const response = await this.create(this.formData)
                        if (response) {
                            this.needPwdReset = false

                            this.currentSupplier = null

                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)

                            this.$emit('created', 'suppliers')
                            this.$toast.success(this.$t('supplier_create_ok'))
                        }
                    } catch (err) {
                        this.creating = false
                        this.$toast.error(`${this.$t('supplier_create_ko')}`)
                        this.$toast.error(err.data.message)
                    }
                } else {
                    this.creating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateSupplier() {
            this.creating = true
            this.formData.ModificationUserId = this.user.id
            this.formData.ModificationDate = new Date()
            this.formData.SoftwarePassword = this.password
            const pwOk =
                (await this.v$.password.$validate()) &&
                (await this.v$.confirmPassword.$validate())
            if (this.needPwdReset === false || pwOk === true) {
                const isFormCorrect = await this.v$.formData.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (isFormCorrect === true) {
                    const params = {
                        id: this.$route.params.id,
                        form: this.formData,
                    }
                    try {
                        const response = this.update(params)
                        if (response) {
                            this.needPwdReset = false
                            this.currentSupplier = null
                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)
                            this.$toast.success(this.$t('supplier_update_ok'))

                            this.$emit('updated', 'suppliers')
                        }
                    } catch (err) {
                        this.creating = false

                        console.log(err.data.message)
                        this.$toast.error(`${this.$t('supplier_update_ko')}`)
                        this.$toast.error(err.data.message)
                    }
                } else {
                    this.creating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.currentSupplier = null
    },
}
</script>
