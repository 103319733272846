import { defineStore } from 'pinia'

import { useClientStore } from './clientStore'
import { useDriverStore } from './driverStore'

const usePaymentStore = defineStore('payment', {
    state: () => ({
        session: null,
        all: null,
        fetchError: false,
        totalPages: 1,
        count: null,
    }),

    actions: {
        search(payload) {
            this.fetchError = false

            this.loading = true
            this.current = null
            this.currentContacts = null
            this.currentClientContactIds = null
            const clientStore = useClientStore()
            const driverStore = useDriverStore()

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    }
                    if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`,
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/checkoutsession/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/checkoutsession/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const sessions = []
                            res.data.Records.forEach((session) => {
                                const ocs = []
                                // todo ajouter si driver ou non
                                const allOcs = session.OrderClients.concat(
                                    session.RefundedOcs,
                                )
                                allOcs.forEach((oc) => {
                                    let pickUp = {}
                                    let delivery = {}

                                    const stepsWoTechSteps = []
                                    console.log(oc)
                                    if (oc.Orders.length > 0) {
                                        oc.Orders[0].OrderSteps.forEach(
                                            (step, index) => {
                                                if (step.Step !== 'technical') {
                                                    stepsWoTechSteps.push(step)
                                                }
                                            },
                                        )
                                        this.formatcurrentDeliveryDateTimes(oc)

                                        pickUp = {
                                            date: new Date(
                                                oc.PickupStepDate,
                                            ).toLocaleDateString(
                                                this.i18n.locale,
                                            ),
                                            address: `${oc.PickupAddress1} ${
                                                oc.PickupAddress2 !== 'null' &&
                                                oc.PickupAddress2 !== ''
                                                    ? oc.PickupAddress2
                                                    : ''
                                            }`,
                                            zipCity: `${oc.PickupPostalCode} ${oc.PickupCity}`,
                                        }

                                        delivery = {
                                            date:
                                                +oc.RoundTrip === 0
                                                    ? new Date(
                                                          oc.DeliveryStepDate,
                                                      ).toLocaleDateString(
                                                          this.i18n.locale,
                                                      )
                                                    : new Date(
                                                          stepsWoTechSteps[1].stepDate,
                                                      ).toLocaleDateString(
                                                          this.i18n.locale,
                                                      ),
                                            address:
                                                +oc.RoundTrip === 0
                                                    ? `${oc.DeliveryAddress1} ${
                                                          oc.DeliveryAddress2 !==
                                                              'null' &&
                                                          oc.DeliveryAddress2 !==
                                                              ''
                                                              ? oc.DeliveryAddress2
                                                              : ''
                                                      }`
                                                    : `${
                                                          stepsWoTechSteps[1]
                                                              .Address1
                                                      } ${
                                                          stepsWoTechSteps[1]
                                                              .Address2 !==
                                                              'null' &&
                                                          stepsWoTechSteps[1]
                                                              .Address2 !== ''
                                                              ? stepsWoTechSteps[1]
                                                                    .Address2
                                                              : ''
                                                      }`,
                                            zipCity:
                                                +oc.RoundTrip === 0
                                                    ? `${oc.DeliveryPostalCode} ${oc.DeliveryCity}`
                                                    : `${stepsWoTechSteps[1].PostalCode} ${stepsWoTechSteps[1].City}`,
                                        }
                                        oc.groupedPackages = []
                                        oc.OrderPackages.forEach(
                                            (op, index) => {
                                                op.Quantity = 1

                                                const indexInGroupedPackages =
                                                    oc.groupedPackages.findIndex(
                                                        (gp) =>
                                                            gp.IdContainer ===
                                                                op.IdContainer &&
                                                            gp.Weight ===
                                                                op.Weight &&
                                                            gp.Value ===
                                                                op.Value &&
                                                            gp.Height ===
                                                                op.Height &&
                                                            gp.Length ===
                                                                op.Length &&
                                                            gp.Width ===
                                                                op.Width &&
                                                            gp.TemperatureOption ===
                                                                op.TemperatureOption,
                                                    )
                                                if (
                                                    oc.groupedPackages
                                                        .length === 0 ||
                                                    indexInGroupedPackages ===
                                                        -1
                                                ) {
                                                    oc.groupedPackages.push(op)
                                                } else {
                                                    oc.groupedPackages[
                                                        indexInGroupedPackages
                                                    ].Quantity += 1
                                                }
                                            },
                                        )
                                        const packs = []
                                        oc.groupedPackages.forEach((pck) => {
                                            const container =
                                                pck.IdContainer &&
                                                +pck.IdContainer !== 0 &&
                                                clientStore.containers.find(
                                                    (cntr) =>
                                                        cntr.id ===
                                                        +pck.IdContainer,
                                                )
                                                    ? clientStore.containers.find(
                                                          (cntr) =>
                                                              cntr.id ===
                                                              +pck.IdContainer,
                                                      ).name
                                                    : 'no_package_selected'

                                            packs.push({
                                                quantity: pck.Quantity,
                                                container,
                                            })
                                        })

                                        let driver = null
                                        let stepStatus = null
                                        // ajouter le statut du step 1
                                        if (
                                            stepsWoTechSteps[0].IdDriver &&
                                            +stepsWoTechSteps[0].IdDriver !==
                                                0 &&
                                            session.Status === 'paid'
                                        ) {
                                            const dr = driverStore.all.find(
                                                (drvr) =>
                                                    drvr.id ===
                                                    +stepsWoTechSteps[0]
                                                        .IdDriver,
                                            )
                                            driver = `${dr.firstname} ${dr.lastname}`
                                        }
                                        stepStatus = stepsWoTechSteps[0].Status

                                        ocs.push({
                                            id: oc.Id,
                                            driver,
                                            stepStatus,
                                            pickUp,
                                            delivery,
                                            packs,
                                            status:
                                                oc.PaymentStatus === 'none'
                                                    ? 'forced_by_operator'
                                                    : oc.PaymentStatus ===
                                                            'waiting' &&
                                                        session.Status ===
                                                            'waiting'
                                                      ? 'waiting_for_payment'
                                                      : oc.PaymentStatus ===
                                                              'waiting' &&
                                                          session.Status ===
                                                              'full_refund'
                                                        ? 'refunded'
                                                        : oc.PaymentStatus ===
                                                                'waiting' &&
                                                            session.Status ===
                                                                'partial_refund'
                                                          ? 'partial_refund'
                                                          : oc.PaymentStatus,
                                        })
                                    }
                                })
                                const formattedSession = {
                                    payment_ref: session.Id,
                                    id: session.Id,

                                    client: session.Client.Name,
                                    provider: session.Provider,
                                    status:
                                        session.Status === 'waiting'
                                            ? 'waiting_for_payment'
                                            : session.Status,
                                    amount: session.Amount / 100, // le montant enregistré est en centimes
                                    refundable_amount:
                                        session.RefundableAmount / 100, // le montant enregistré est en centimes

                                    refunded_amount:
                                        session.RefundedAmount / 100, // le montant enregistré est en centimes
                                    // refunded_ocs: refOcs,
                                    ocs,
                                    creation_date: session.CreationDate,
                                    modification_date: session.ModificationDate,

                                    id_universe: 7,
                                    // session.OrderCLients[0].IdUniverse,
                                    refund_date: session.RefundDate,
                                    refund_com: session.RefundComment,
                                    last_modified_by: `${this.i18n.global.t(
                                        session.ModificationUser.Type,
                                    )} - ${
                                        session.ModificationUser.FirstName
                                    } ${session.ModificationUser.LastName}`,
                                }
                                sessions.push(formattedSession)
                            })
                            this.all = sessions
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createStripeCheckoutSession(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        '/api/v1/checkoutsession/createCheckoutSession',
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        checkoutSessionResult(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        '/api/v1/checkoutsession/checkoutSessionResult',
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        allRefunds() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get('/api/v1/checkoutsession/searchRefunds', {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        createRefund(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/checkoutsession/refundWebPayment`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        cancelRefund(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/checkoutsession/cancelRefundRequest/${payload}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        validateRefund(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/checkoutsession/refundWebPayment/${payload}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        // console.log(res)
                        // this.session = res

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        formatcurrentDeliveryDateTimes(orderClient) {
            if (orderClient.DeliveryMoment === 'between') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3,
                    )
            } else if (orderClient.DeliveryMoment === 'from') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )
                orderClient.DeliveryBeforeDateTime = ''
            } else if (orderClient.DeliveryMoment === 'before') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryBeforeDateTime.substring(
                        0,
                        orderClient.DeliveryBeforeDateTime.indexOf(' '),
                    )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3,
                    )
                orderClient.DeliveryFromDateTime = ''
            }
            if (orderClient.PickupMoment === 'between') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
            } else if (orderClient.PickupMoment === 'from') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )
                orderClient.PickupBeforeDateTime = ''
            } else if (orderClient.PickupMoment === 'before') {
                orderClient.PickupStepDate =
                    orderClient.PickupBeforeDateTime.substring(
                        0,
                        orderClient.PickupBeforeDateTime.indexOf(' '),
                    )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
                orderClient.PickupFromDateTime = ''
            }
        },
    },
})

export { usePaymentStore }
