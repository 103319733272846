<template>
    <div
        class="flex mt-1 darky p-2 rounded-md items-center"
        :class="small ? 'h-2' : 'border'"
        :style="customHeight ? 'padding:0.7rem !important;' : ''"
    >
        <fa-icon
            :icon="searchIcon"
            class="mr-2"
            :class="
                searchIcon !== 'magnifying-glass'
                    ? 'cursor-pointer theme-color'
                    : ''
            "
            @click="
                searchIcon !== 'magnifying-glass' ? $emit('changeSearch') : ''
            "
        ></fa-icon>
        <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 text-zinc-400"
            :class="small ? 'h-3' : 'h-5'"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
            />
        </svg> -->
        <input
            class="search text-xs outline-none w-fit overflow-auto"
            type="text"
            v-model="term"
            :placeholder="$t(searchBy)"
            @input="$emit('searched', query)"
        />
    </div>
</template>
<script>
export default {
    name: 'SearchInput',
    data() {
        return {
            term: this.searchQuery,
        }
    },
    computed: {
        query() {
            return { term: this.term, dbField: this.dbField }
        },
    },
    props: {
        searchQuery: {
            type: String,
            default: '',
        },
        searchBy: {
            type: String,
            default: 'by_name',
        },
        customHeight: {
            type: Boolean,
            default: false,
        },
        searchIcon: {
            type: String,
            default: 'magnifying-glass',
        },
        dbField: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
