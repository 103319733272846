import Shepherd from 'shepherd.js'

export default {
    methods: {
        startTour() {
            const shepherd = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shadow-md bg-purple-dark darky',
                    scrollTo: true,
                },
            })
            const vm = this
            const buttons = [
                {
                    action() {
                        return this.back()
                    },
                    classes: `shepherd-button-cancel`,
                    text: vm.$t('back'),
                },
                {
                    action() {
                        return this.next()
                    },
                    text: vm.$t('next'),
                },
            ]
            shepherd.addSteps([
                {
                    title: vm.$t('shepherd.create_account.start.title'),
                    text: vm.$t('shepherd.create_account.start.text'),
                    attachTo: {
                        element: '#signup',
                    },
                    cancelIcon: {
                        enabled: true,
                    },
                    buttons: [
                        {
                            action() {
                                return this.complete()
                            },
                            classes: `shepherd-button-cancel`,
                            text: vm.$t('shepherd.no_thanks'),
                        },
                        {
                            action() {
                                return this.next()
                            },
                            text: vm.$t('shepherd.ok_go'),
                        },
                    ],
                    id: 'start',
                },
                {
                    title: vm.$t('shepherd.create_account.step_1.title'),
                    text: vm.$t('shepherd.create_account.step_1.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#nightswitch',
                        on: 'top',
                    },
                    buttons,
                    id: 'step1',
                },
                {
                    title: vm.$t('shepherd.create_account.step_2.title'),
                    text: vm.$t('shepherd.create_account.step_2.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#langswitch',
                        on: 'top',
                    },
                    buttons,
                    id: 'step2',
                },
                {
                    title: vm.$t('shepherd.create_account.step_3.title'),
                    text: vm.$t('shepherd.create_account.step_3.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#restart',
                        on: 'top',
                    },
                    buttons,
                    id: 'step3',
                },
                {
                    title: vm.$t('shepherd.create_account.step_4.title'),
                    text: vm.$t('shepherd.create_account.step_4.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#email',
                        on: 'left',
                    },
                    buttons,
                    id: 'step4',
                },
                {
                    title: vm.$t('shepherd.create_account.step_5.title'),
                    text: vm.$t('shepherd.create_account.step_5.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#eye',
                        on: 'top',
                    },
                    buttons,
                    id: 'step5',
                },
                {
                    title: vm.$t('shepherd.create_account.step_6.title'),
                    text: vm.$t('shepherd.create_account.step_6.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#pwdStrength',
                        on: 'top',
                    },
                    buttons,
                    id: 'step6',
                },
                {
                    title: vm.$t('shepherd.create_account.step_7.title'),
                    text: vm.$t('shepherd.create_account.step_7.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#name',
                        on: 'right',
                    },
                    buttons,
                    id: 'step7',
                },
                {
                    title: vm.$t('shepherd.create_account.step_8.title'),
                    text: vm.$t('shepherd.create_account.step_8.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#address',
                        on: 'right',
                    },
                    buttons,
                    id: 'step8',
                },
                {
                    title: vm.$t('shepherd.create_account.step_9.title'),
                    text: vm.$t('shepherd.create_account.step_9.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#cancel',
                        on: 'top',
                    },
                    buttons,
                    id: 'step9',
                },
                {
                    title: vm.$t('shepherd.create_account.step_10.title'),
                    text: vm.$t('shepherd.create_account.step_10.text'),
                    cancelIcon: {
                        enabled: true,
                    },
                    attachTo: {
                        element: '#validate',
                        on: 'top',
                    },
                    buttons: [
                        {
                            action() {
                                return this.back()
                            },
                            classes: `shepherd-button-cancel`,
                            text: vm.$t('back'),
                        },
                        {
                            action() {
                                return this.next()
                            },
                            text: `${vm.$t('shepherd.final_button')} !`,
                        },
                    ],
                    id: 'step10',
                },
            ])
            // attachTO  'auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end'
            shepherd.start()
        },
    },
}
