<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'invoices'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="billingsList"
                :items="billings"
                :columns="columns"
                :storeToSort="'invoices'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="false"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @dateFilterUpdate="setDateFilter($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @sendByMail="sendInvoiceReminder($event)"
                @sendManyReminders="sendInvoiceReminder($event, true)"
                @download="downloadInvoice($event)"
                @print="printInvoice($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import { useBillingStore } from '@/stores/billingStore'
import { useClientStore } from '@/stores/clientStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useUniverseStore } from '@/stores/universeStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Bills',
    mixins: [filterList],
    inject: ['eventBus'],

    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            dateFilter: null,
            dueDateFilter: null,
            paymentDateFilter: null,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            billingsInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        billings() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        clearInterval(this.billingsInterval)
        const filteredList = this.$cookies.get('filteredGenerated')

        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        if (filteredList) {
            this.search = filteredList.query

            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page

            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
            const dateFiltered = this.filter.find(
                (filt) => filt.dbField === 'DateInvoice'
            )
            if (dateFiltered !== undefined) {
                this.dateFilter = dateFiltered.value
            }
            const dueDateFiltered = this.filter.find(
                (filt) => filt.dbField === 'DueDateInvoice'
            )
            if (dueDateFiltered !== undefined) {
                this.dueDateFilter = dueDateFiltered.value
            }
            const paymentDateFiltered = this.filter.find(
                (filt) => filt.dbField === 'PaymentDate'
            )
            if (paymentDateFiltered !== undefined) {
                this.paymentDateFilter = paymentDateFiltered.value
            }
        }

        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)
                if (this.userStore.isB2C === true) {
                    this.filter.push({
                        dbField: 'IdClient',
                        value: this.userStore.current.idClient,
                    })
                } else {
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            const universeFilter = {
                                dbField: 'IdUniverse',
                                value:
                                    this.selectedUniverses.length > 0
                                        ? this.selectedUniverses
                                        : this.universesOptions,
                            }
                            if (
                                !this.filter.find(
                                    (f) => f.dbField === 'IdUniverse'
                                )
                            ) {
                                this.filter.push(universeFilter)
                            }
                        }
                    }, 10)
                }
                this.setResults()

                // const realSearch = {
                //     query: this.search,
                //     filter: this.filter,
                //     sortDirection: this.sortDirection,
                //     sortField: this.sortField,
                //     perPage: this.perPage,
                //     page: this.page,
                // }
                // this.searchBillings(realSearch)
                // this.billingsInterval = setInterval(() => {
                //     this.searchBillings(realSearch)
                // }, 20000)
            }
        }, 10)
    },

    unmounted() {
        clearInterval(this.billingsInterval)
        this.eventBus.all.clear()
    },
    computed: {
        billingsActionsAccess() {
            return actionsAccess.billings
        },
        ...mapState(useBillingStore, {
            billings: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),
        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        columns() {
            return [
                {
                    name: 'bill_number',
                    filtrable: true,
                    // isLink: checkAccess(
                    //     this.userStore,
                    //     this.billingsActionsAccess.update
                    // ),
                    // routeName: 'update_billing',

                    dbField: 'Number',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'client_name',
                    filtrable: true,
                    dbField: 'ClientName',
                    type: 'search',
                    sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                // {
                //     name: 'ht',
                //     filtrable: false,
                //     // isLink: true,
                //     dbField: 'TotalHT',
                //     sortable: false,
                // },
                {
                    name: 'ttc',
                    filtrable: false,
                    // isLink: true,
                    dbField: 'TotalTTC',
                    sortable: false,
                },
                {
                    name: 'date_invoice',
                    sortable: true,
                    // isLink: true,
                    dbField: 'DateInvoice',
                    withButton: false,
                    datePicker: true,
                    pickedDate: this.dateFilter,
                },
                {
                    name: 'due_date',
                    sortable: true,
                    // isLink: true,
                    dbField: 'DueDateInvoice',

                    withButton: false,
                    datePicker: true,
                    pickedDate: this.dueDateFilter,
                },
                {
                    name: 'payment_date',
                    sortable: true,
                    // isLink: true,
                    dbField: 'PaymentDate',

                    withButton: false,
                    datePicker: true,
                    pickedDate: this.paymentDateFilter,
                },
                {
                    name: 'payment_type',
                    filtrable: true,
                    type: 'select',
                    dbField: 'PaymentType',

                    sortable: false,
                    options: [
                        {
                            name: 'cash',
                            value: 'cash',
                            dbField: 'PaymentType',
                        },
                        {
                            name: 'check',
                            value: 'check',

                            dbField: 'PaymentType',
                        },
                        {
                            name: 'credit_card',
                            value: 'creditcard',
                            dbField: 'PaymentType',
                        },
                        {
                            name: 'notselected',
                            value: 'notselected',
                            dbField: 'PaymentType',
                        },

                        {
                            name: 'transfer',
                            value: 'transfer',

                            dbField: 'PaymentType',
                        },
                        {
                            name: 'web',
                            value: 'web',
                            dbField: 'PaymentType',
                        },
                    ],
                },
                {
                    name: 'type',
                    filtrable: true,
                    dbField: 'Type',
                    type: 'select',
                    sortable: false,
                    options: [
                        {
                            name: 'end_month',
                            value: 'month',
                            dbField: 'Type',
                        },
                        {
                            name: 'occasional',
                            value: 'occasional',
                            dbField: 'Type',
                        },
                        {
                            name: 'voucher_book',
                            value: 'voucher',
                            dbField: 'Type',
                        },
                        {
                            name: 'web',
                            value: 'web',
                            dbField: 'Type',
                        },
                    ],
                },
                {
                    name: 'PDF',
                    filtrable: false,
                    dwnldOrSend: true,
                    sortable: false,
                },
            ]
        },
        itemActions() {
            // if (
            //     checkAccess(this.userStore, this.billingsActionsAccess.delete)
            // ) {
            //     return [
            //         {
            //             name: 'delete',
            //             action: 'delete',
            //             icon: 'trash-can',
            //         },
            //     ]
            // }
            return []
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.update)
            ) {
                actions.push({
                    name: 'send_many_invoices',
                    action: 'sendManyReminders',
                    icon: 'envelope',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useBillingStore, [
            'searchBillings',
            'download',
            'sendInvoice',
        ]),
        upFilter(event) {
            clearInterval(this.billingsInterval)
            this.updateFilter(event)
        },
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        downloadInvoice(invoice) {
            this.axios
                .get(`/api/v1/invoice/${invoice.id}/pdf`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session'
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', invoice.file)
                    document.body.appendChild(link)
                    link.click()
                    // document.body.removeChild(link)
                    window.open(url, '_blank').focus()
                    URL.revokeObjectURL(url)
                })
        },

        sendInvoiceReminder(invoices, many = false) {
            const invoicesPayload = []
            if (many === false) {
                invoicesPayload.push({ Id: invoices })
            } else {
                invoices.forEach((invoice) => {
                    invoicesPayload.push({ Id: invoice })
                })
            }
            this.sendInvoice({ Invoices: invoicesPayload })
                .then((res) => {
                    this.$toast.success(
                        this.$tc(
                            'success_sending_invoice',
                            invoicesPayload.length === 1 ? 1 : 2
                        )
                    )
                })
                .catch((err) => {
                    this.$toast.error(
                        this.$tc(
                            'error_sending_invoice',
                            invoicesPayload.length === 1 ? 1 : 2
                        )
                    )
                })
            // this.$toast.info(this.$t('not_implemented'))
        },

        setDateFilter(dFilter) {
            this.searchingCursor = true
            const filterExistsIndex = this.filter.findIndex(
                (df) => df.dbField === dFilter.dbField
            )
            if (filterExistsIndex !== -1) {
                this.filter[filterExistsIndex] = dFilter
            } else {
                this.filter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
                if (dFilter.dbField === 'DueDateInvoice') {
                    this.dueDateFilter = dFilter.value
                } else if (dFilter.dbField === 'DateInvoice') {
                    this.dateFilter = dFilter.value
                } else {
                    this.paymentDateFilter = dFilter.value
                }
            }
            this.setResults()
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredGenerated')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredGenerated')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            if (this.userStore.isB2C === true) {
                this.filter.push({
                    dbField: 'IdClient',
                    value: this.userStore.current.idClient,
                })
            }
            this.setRealSearch(this.search)

            this.searchBillings(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredGenerated', search)
            this.billingsInterval = setInterval(() => {
                this.searchBillings(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredGenerated')
            this.perPage = perPage
            this.page = 1

            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
