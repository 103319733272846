<template>
    <main style="width: 96vw" class="px-4 rounded-md h-full">
        <button
            class="mt-12 transition duration-200 themed-button focus:shadow-sm focus:ring-opacity-50 text-white px-10 py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
            :title="copyTitle"
            @click.stop="copyToClipboard($cookies.get('user_session'))"
        >
            <fa-icon icon="copy" class="fa-md mr-2 cursor-copy"></fa-icon>
            {{ $t('copy_jwt') }}
        </button>
        <div class="swagger" id="swagger"></div>
    </main>
</template>

<script>
import SwaggerUI from 'swagger-ui'
// import { SwaggerUIStandalonePreset } from 'swagger-ui-dist'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import 'swagger-ui/dist/swagger-ui.css'

export default {
    name: 'ApiDoc',
    mixins: [copyToClipboard],

    mounted() {
        this.axios
            .get(`/api/v1/apidoc/documentation`, {
                headers: {
                    Authorization: `Bearer ${this.$cookies.get(
                        'user_session'
                    )}`,
                },
            })
            .then((res) => {
                SwaggerUI({
                    spec: res.data,
                    dom_id: '#swagger',
                    presets: [SwaggerUI.presets.apis],
                    // headers: {
                    //     Authorization: `Bearer ${this.$cookies.get(
                    //         'user_session'
                    //     )}`,
                    // },
                })
            })
            .catch((err) => {
                console.log(err)
            })
    },
}
</script>
