export default {
    data() {
        return {
            weekOptionsNotice: [
                {
                    name: this.$t('none'),
                    value: 'none',
                    dbField: 'NoticePeriod',
                },

                {
                    name: `1 ${this.$tc('week', 1)}`,
                    value: 1,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `2 ${this.$tc('week', 2)}`,
                    value: 2,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `3 ${this.$tc('week', 2)}`,
                    value: 3,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `4 ${this.$tc('week', 2)}`,
                    value: 4,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `5 ${this.$tc('week', 2)}`,
                    value: 5,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `6 ${this.$tc('week', 2)}`,
                    value: 6,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `7 ${this.$tc('week', 2)}`,
                    value: 7,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `8 ${this.$tc('week', 2)}`,
                    value: 8,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `9 ${this.$tc('week', 2)}`,
                    value: 9,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `10 ${this.$tc('week', 2)}`,
                    value: 10,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `11 ${this.$tc('week', 2)}`,
                    value: 11,
                    dbField: 'NoticePeriod',
                },
                {
                    name: `12 ${this.$tc('week', 2)}`,
                    value: 12,
                    dbField: 'NoticePeriod',
                },
            ],
            weekOptionsAlert: [
                {
                    name: this.$t('none'),
                    value: 'none',
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `1 ${this.$tc('week', 1)}`,
                    value: 1,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `2 ${this.$tc('week', 2)}`,
                    value: 2,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `3 ${this.$tc('week', 2)}`,
                    value: 3,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `4 ${this.$tc('week', 2)}`,
                    value: 4,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `5 ${this.$tc('week', 2)}`,
                    value: 5,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `6 ${this.$tc('week', 2)}`,
                    value: 6,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `7 ${this.$tc('week', 2)}`,
                    value: 7,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `8 ${this.$tc('week', 2)}`,
                    value: 8,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `9 ${this.$tc('week', 2)}`,
                    value: 19,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `10 ${this.$tc('week', 2)}`,
                    value: 10,
                    dbField: 'AlertBeforeEnd',
                },
                {
                    name: `11 ${this.$tc('week', 2)}`,
                    value: 11,
                    dbField: 'AlertBeforeEnd',
                },

                {
                    name: `12 ${this.$tc('week', 2)}`,
                    value: 12,
                    dbField: 'AlertBeforeEnd',
                },
            ],
            monthOptions: [
                {
                    name: this.$t('open_ended'),
                    value: 'openEnded',
                    dbField: 'Duration',
                },

                {
                    name: `6 ${this.$tc('month', 2)}`,
                    value: 6,
                    dbField: 'Duration',
                },
                {
                    name: `12 ${this.$tc('month', 2)}`,
                    value: 12,
                    dbField: 'Duration',
                },
                {
                    name: `18 ${this.$tc('month', 2)}`,
                    value: 18,
                    dbField: 'Duration',
                },
                {
                    name: `24 ${this.$tc('month', 2)}`,
                    value: 24,
                    dbField: 'Duration',
                },
                {
                    name: `28 ${this.$tc('month', 2)}`,
                    value: 28,
                    dbField: 'Duration',
                },
                {
                    name: `36 ${this.$tc('month', 2)}`,
                    value: 36,
                    dbField: 'Duration',
                },
                {
                    name: `42 ${this.$tc('month', 2)}`,
                    value: 42,
                    dbField: 'Duration',
                },
                {
                    name: `48 ${this.$tc('month', 2)}`,
                    value: 48,
                    dbField: 'Duration',
                },
            ],
            renewalOptions: [
                {
                    name: this.$t('no'),
                    value: 'no',
                    dbField: 'AutomaticRenewal',
                },
                {
                    name: this.$t('yes'),
                    value: 'yes',
                    dbField: 'AutomaticRenewal',
                },
                {
                    name: this.$t('yes_with_mail'),
                    value: 'yesWithEmail',
                    dbField: 'AutomaticRenewal',
                },
                {
                    name: this.$t('not_applicable'),
                    value: 'notApplicable',
                    dbField: 'AutomaticRenewal',
                },
            ],
            contractTypeOptions: [
                {
                    name: this.$t('client'),
                    value: 'client',
                    dbField: 'Type',
                },
                {
                    name: this.$t('rental'),
                    value: 'rental',
                    dbField: 'Type',
                },
                {
                    name: this.$t('work_fixed_term'),
                    value: 'workFixedTerm',
                    dbField: 'Type',
                },
                {
                    name: this.$t('work_open_ended'),
                    value: 'workOpenEnded',
                    dbField: 'Type',
                },
            ],
        }
    },
}
