import Dashboard from '../views/Dashboard.vue'

const dashboard = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            authorisations: [
                'DASHBOARD_OPERATOR',
                'FULL_ACCESS',
                'DASHBOARD_CLIENT',
            ],
            showUniverseSearch: true,
        },
    },
]

export default dashboard
