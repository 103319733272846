export default {
    methods: {
        formatDistance(distance) {
            let dist = null
            if (distance) {
                dist = (distance / 1000).toString()
                dist = dist.replace('.', ',')
                dist = dist.substring(0, dist.indexOf(',') + 3)
            }
            return dist
        },
        formatDuration(duration) {
            if (duration) {
                const seconds = Number(duration)

                const d = Math.floor(seconds / (3600 * 24))
                const h = Math.floor((seconds % (3600 * 24)) / 3600)
                const m = Math.floor((seconds % 3600) / 60)
                const dDisplay =
                    d > 0
                        ? d +
                          (d === 1
                              ? ` ${this.$tc('day', 1)}, `
                              : ` ${this.$tc('day', 2)}, `)
                        : ''
                const hDisplay =
                    h > 0
                        ? h +
                          (h === 1
                              ? ` ${this.$tc('hour', 1)}, `
                              : ` ${this.$tc('hour', 2)}, `)
                        : ''
                const mDisplay =
                    m > 0
                        ? m +
                          (m === 1
                              ? ` ${this.$tc('minute', 1)} `
                              : ` ${this.$tc('minute', 2)} `)
                        : ''

                return dDisplay + hDisplay + mDisplay
            }
            return null
        },
    },
}
