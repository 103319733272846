<template>
    <div class="flex space-x-2 justify-center">
        <button
            type="button"
            :disabled="isDisabled"
            :class="
                noStyle
                    ? 'style-less-btn px-1 text-zinc'
                    : customButtonStyle
                    ? 'inline-block px-6 py-2.5 text-zinc font-medium text-xs leading-tight uppercase rounded hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 custom-button-bg active:shadow-lg transition duration-150 ease-in-out flex items-center'
                    : 'inline-block px-6 py-2.5 text-zinc font-medium text-xs leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out flex items-center'
            "
        >
            <div class="inline-flex relative w-fit">
                <div
                    v-if="withNotificationBadge"
                    class="notification-bagde absolute inline-block translate-x-6 -translate-y-1/2 top-0 right-0 bottom-auto left-auto rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-0.5 px-2 text-xs leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full z-10"
                >
                    {{ notifCount }}
                </div>
                <fa-icon :icon="icon" :class="iconStyle" />
            </div>
        </button>
    </div>
</template>
<script>
export default {
    name: 'IconButton',
    props: {
        icon: {
            type: String,
            default: 'user',
        },
        withNotificationBadge: {
            type: Boolean,
            default: false,
        },
        notifCount: {
            type: Number,
            default: 8,
        },
        noStyle: {
            type: Boolean,
            default: false,
        },
        iconStyle: {
            type: String,
            default: 'fa-xl',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        customButtonStyle: {
            type: Boolean,
            deafult: false,
        },
    },
}
</script>
<style lang="scss">
.style-less-btn {
    all: unset;
    &:hover,
    &:focus {
        background-color: unset;
    }
}
</style>
