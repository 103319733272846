<template>
    <modal
        :headerText="'order_group_modal_header'"
        :buttonText="'save'"
        id="updateGroupModal"
        :mxWidth="'max-w-2xl'"
        :buttonAlign="'justify-center'"
        @sendFromModal="checkForm()"
        >
        <!-- @closeModal="close()" -->
        <template #body>
            <div class="mx-8">
                <div class="flex justify-center italic my-4">
                    {{ $t('order_group_modal_info') }}
                </div>
                <!-- <div
                                class="grid grid-cols-6 gap-2 items-center my-2"
                            > -->
                <div class="justify-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 mb-1 pb-1 mr-2"
                        >{{ $t('name') + ' *' }}</label
                    >
                    <input
                        :id="`groupName`"
                        type="text"
                        v-model="groupForm.Name"
                        class="border rounded px-3 py-2 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 ml-2 mb-2"
                        v-for="error of v$.groupForm.Name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <!-- <span class="col-span-3 justify-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mb-1 mr-2"
                                        >{{ $t('group_date') }}</label
                                    >
                                    <input
                                        :id="`groupDate`"
                                        type="date"
                                        disabled
                                        v-model="groupForm.DateTour"
                                        class="border rounded px-3 py-2 w-full"
                                    />
                                </span> -->
                <!-- </div> -->
                <div class="justify-center my-2">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mb-1 mr-2"
                        >{{ $t('tour_pricing_scenario') + ' *' }}</label
                    >
                    <Multiselect
                        id="tourPricingMS"
                        class="darky"
                        v-model="selectedTourPricing"
                        :options="tourPricingOptions"
                        label="name"
                        track-by="name"
                        :placeholder="$t('tour_pricing_scenario')"
                        :searchable="false"
                        :can-clear="false"
                        :can-deselect="false"
                        :allow-empty="false"
                        :object="true"
                    >
                    </Multiselect>
                    <div
                        class="text-xs italic mt-1 ml-2 mb-2"
                        v-for="error of v$.groupForm.IdPricing.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-6 gap-2 my-2 items-center">
                    <span class="col-span-3 justify-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mb-1 mr-2"
                            >{{ $t('demo_price') }}</label
                        >
                        <input
                            :id="`price`"
                            disabled
                            type="text"
                            v-model="groupForm.Price"
                            class="border rounded px-3 py-2 w-full"
                        />
                    </span>
                    <span class="col-span-3 justify-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mb-1 mr-2"
                            >{{ $t('forced_price') }}</label
                        >
                        <input
                            :id="`forcedPrice`"
                            type="number"
                            v-model="groupForm.ForcedPrice"
                            class="border rounded px-3 py-2 w-full"
                        />
                    </span>
                </div>

                <!-- <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div> -->
            </div></template
        ></modal
    >
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import Modal from '@/components/atoms/Modal.vue'

export default {
    name: 'OrderGroupModal',
    setup() {
        return { v$: useVuelidate() }
    },
    components: {
        Multiselect,
        Modal
    },
    data() {
        return {
            groupForm: structuredClone(this.form),
            selectedTourPricing: structuredClone(this.selectedPricing),
        }
    },
    props: {
        form: {
            type: Object,
            default: () => ({}),
        },
        selectedPricing: {
            type: Object,
            default: () => ({}),
        },
        tourPricingOptions: {
            type: Array,
            default: () => [],
        },
    },
    validations() {
        return {
            groupForm: {
                Name: { required },
                IdPricing: { required },
            },
        }
    },
    watch: {
        selectedTourPricing() {
            this.groupForm.IdPricing = this.selectedTourPricing.value
        },
    },
    methods: {
        // close() {
        //     console.log('close')
        //     this.$emit('closeModal')
        // },
        async checkForm() {
            const isValid = await this.v$.groupForm.$validate()
            if (isValid === true) {
                this.$emit('updateFromModal', this.groupForm)
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
}
</script>
