<template>
    <main
        :class="isMobile === true ? '' : 'mt-12'"
        class="rounded-md darky h-full"
    >
        <div
            v-if="mapLoaded === false"
            class="items-center justify-center absolute text-xl pt-12"
            style="width: 100%; height: 100vh"
        >
            <fa-icon class="fa-lg mr-2" icon="spinner" spin-pulse></fa-icon>
            {{ $t('map_loading') }}
        </div>

        <div id="wrapper" class="h-screen">
            <div
                id="map"
                class="z-1"
                :style="
                    isMobile === true
                        ? 'width: 100%; height: 100vh'
                        : 'width: 100%; height: 95vh'
                "
            ></div>
            <div id="custom-panel" class="flex w-full justify-end pl-2 pr-2">
                <span
                    @click="setGoogleMapStyle()"
                    class="over-map text-zinc-400 mt-11 justify-center ml-1 rounded-sm h-10 w-10 px-2 items-center flex cursor-pointer"
                    style="margin-right: 2px"
                    :title="$t('hide_show_google_pois')"
                >
                    <fa-icon :icon="'arrows-to-eye'" class="fa-lg"></fa-icon>
                </span>
            </div>
            <div
                id="custom-panel-2"
                class="flex w-full mt-11 justify-end mr-2 pl-2 h-screen"
            >
                <div
                    :key="refresh"
                    class="light-over-map flex overflow-x-auto overflow-y-hidden"
                >
                    <div
                        v-if="trackedPackages[0]"
                        class="light-in-over-map text-xs text-zinc-900 border-2 rounded mt-2 mx-2 themed-border"
                        style="color: black !important"
                    >
                        <!-- height: 479px; -->
                        <!-- min-w-[15%]  -->
                        <span>
                            <div
                                class="my-4 grid text-sm grid-cols-4 px-2 items-center"
                                style="color: black !important"
                            >
                                <!-- alignement du carré -->

                                <span
                                    class="col-start-2 col-span-2 whitespace-nowrap font-semibold"
                                >
                                    {{ $t('informations') }}
                                </span>
                            </div>

                            <div class="overflow-y-auto h-[74%]">
                                <ol
                                    :class="`relative border-l ml-6 themed-border`"
                                    style="
                                        background: rgba(
                                            236,
                                            240,
                                            241,
                                            0.85
                                        ) !important;
                                    "
                                >
                                    <li
                                        class="mb-6 ml-6"
                                        style="
                                            background: rgba(
                                                236,
                                                240,
                                                241,
                                                0.85
                                            ) !important;
                                        "
                                    >
                                        <span
                                            id="themed"
                                            style="color: black !important"
                                            class="flex absolute -left-3 themed-bg justify-center items-center w-6 h-6 rounded-full"
                                        >
                                            <fa-icon icon="home"></fa-icon>
                                        </span>

                                        <span
                                            style="color: black !important"
                                            class="block max-w-full themed-border mr-3 rounded border p-3"
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    {{
                                                        trackedPackages[0].steps
                                                            .pickup.name
                                                    }}
                                                </strong>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${trackedPackages[0].steps.pickup.address} - ${trackedPackages[0].steps.pickup.zipCity} 
                                                       `
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (trackedPackages[0].steps
                                                        .pickup.distance &&
                                                        trackedPackages[0].steps
                                                            .pickup.duration) ||
                                                    trackedPackages[0].steps
                                                        .pickup.moment
                                                "
                                            />

                                            <div
                                                class="flex items-center justify-between mt-2"
                                            >
                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .pickup.distance &&
                                                        trackedPackages[0].steps
                                                            .pickup.duration
                                                    "
                                                    class="whitespace-nowrap flex mr-2 items-center"
                                                    :title="
                                                        $t(
                                                            'dur_n_dist_last_step'
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'dur_n_dist_last_step'
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/kilometre.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .pickup.distance +
                                                        ' km - ' +
                                                        trackedPackages[0].steps
                                                            .pickup.duration
                                                    }}
                                                </span>

                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .pickup.moment
                                                    "
                                                    style="
                                                        color: black !important;
                                                    "
                                                    class="text-xs items-center flex whitespace-nowrap"
                                                    :title="$t('opening_hours')"
                                                >
                                                    <img
                                                        :alt="
                                                            $t('opening_hours')
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/clock.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .pickup.moment
                                                    }}
                                                </span>
                                            </div>
                                            <span class="mt-2">
                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .pickup.executedOn
                                                    "
                                                    class="flex items-center mt-2"
                                                    :title="$t('picked_up')"
                                                >
                                                    <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                    <img
                                                        :alt="$t('picked_up')"
                                                        svg-inline
                                                        src="/biiimIcons/check.svg"
                                                        class="mr-1"
                                                    />
                                                    <span
                                                        class="font-medium text-blue-500"
                                                    >
                                                        {{
                                                            new Date(
                                                                trackedPackages[0].steps.pickup.executedOn
                                                            ).toLocaleString(
                                                                $i18n.locale,
                                                                {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                }
                                                            )
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-else
                                                    class="flex mt-2 items-center"
                                                >
                                                    <img
                                                        :alt="$t('in_progress')"
                                                        svg-inline
                                                        :src="`/biiimIcons/real-time-clock.svg
                                                                   `"
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .pickup.status
                                                    }}
                                                </span>
                                            </span>
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (trackedPackages[0].steps
                                                        .pickup.duration &&
                                                        trackedPackages[0].steps
                                                            .pickup.distance) ||
                                                    trackedPackages[0].steps
                                                        .pickup.moment
                                                "
                                            />
                                        </span>
                                    </li>
                                    <li
                                        class="mb-1 ml-6 mt-8"
                                        style="
                                            background: rgba(
                                                236,
                                                240,
                                                241,
                                                0.85
                                            ) !important;
                                        "
                                    >
                                        <span
                                            style="color: black !important"
                                            class="flex absolute themed-bg -left-3 justify-center items-center w-6 h-6 rounded-full"
                                        >
                                            <fa-icon
                                                icon="flag-checkered"
                                            ></fa-icon>
                                        </span>
                                        <span
                                            style="color: black !important"
                                            class="block max-w-full themed-border mr-3 rounded border p-3"
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    {{
                                                        trackedPackages[0].steps
                                                            .delivery.name
                                                    }}
                                                </strong>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${trackedPackages[0].steps.delivery.address} - ${trackedPackages[0].steps.delivery.zipCity} 
                                                       `
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (trackedPackages[0].steps
                                                        .delivery.distance &&
                                                        trackedPackages[0].steps
                                                            .delivery
                                                            .duration) ||
                                                    trackedPackages[0].steps
                                                        .delivery.moment
                                                "
                                            />

                                            <div
                                                class="flex items-center justify-between mt-2"
                                            >
                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .delivery
                                                            .distance &&
                                                        trackedPackages[0].steps
                                                            .delivery.duration
                                                    "
                                                    class="whitespace-nowrap mr-2 flex items-center"
                                                    :title="
                                                        $t(
                                                            'dur_n_dist_last_step'
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'dur_n_dist_last_step'
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/kilometre.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .delivery.distance +
                                                        ' km - ' +
                                                        trackedPackages[0].steps
                                                            .delivery.duration
                                                    }}
                                                </span>

                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .delivery.moment
                                                    "
                                                    style="
                                                        color: black !important;
                                                    "
                                                    class="text-xs items-center flex whitespace-nowrap"
                                                    :title="$t('opening_hours')"
                                                >
                                                    <img
                                                        :alt="
                                                            $t('opening_hours')
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/clock.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .delivery.moment
                                                    }}
                                                </span>
                                            </div>
                                            <span class="mt-2">
                                                <span
                                                    v-if="
                                                        trackedPackages[0].steps
                                                            .delivery.executedOn
                                                    "
                                                    class="flex mt-2 items-center"
                                                    :title="
                                                        $t('delivery_finished')
                                                    "
                                                >
                                                    <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'delivery_finished'
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/check.svg"
                                                        class="mr-1"
                                                    />
                                                    <span
                                                        class="font-medium text-blue-500"
                                                    >
                                                        {{
                                                            new Date(
                                                                trackedPackages[0].steps.delivery.executedOn
                                                            ).toLocaleString(
                                                                $i18n.locale,
                                                                {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                }
                                                            )
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-else
                                                    class="flex items-center mt-2"
                                                >
                                                    <img
                                                        :alt="$t('in_progress')"
                                                        svg-inline
                                                        :src="`/biiimIcons/real-time-clock.svg
                                                                   `"
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        trackedPackages[0].steps
                                                            .delivery.status
                                                    }}
                                                </span>
                                            </span>
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (trackedPackages[0].steps
                                                        .delivery.duration &&
                                                        trackedPackages[0].steps
                                                            .delivery
                                                            .distance) ||
                                                    trackedPackages[0].steps
                                                        .delivery.moment
                                                "
                                            />
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import useVuelidate from '@vuelidate/core'
import googleScriptLoader from '@/mixins/googleScript'
import { useUserStore } from '@/stores/userStore'
import { useOrderStore } from '@/stores/orderStore'
import gmapStyles from '@/mixins/gmapStyles'
import converter from '@/mixins/converter'

const screenEvent =
    'ontouchstart' in document.documentElement ? 'mousedown' : 'click'
export default {
    name: 'LiveTracking',
    mixins: [googleScriptLoader, gmapStyles, converter],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            driverPosInt: null,
            showUnaffected: false,
            tourTime: '',
            calculationInProgress: false,
            mapLoaded: false,
            date: new Date(),
            processing: false,

            refresh: 0,
        }
    },

    created() {
        this.hidePOIS = true
        this.map = null
        this.loadGoogleScript()
    },
    mounted() {
        const googleInterval = setInterval(() => {
            if (google) {
                // && this.tourCards !== null
                // console.log(this.tourCards)
                // if (this.tourCards === null) {
                //     this.back()
                //     return
                // }
                this.mapLoaded = true
                clearInterval(googleInterval)

                this.map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 11,
                    center: {
                        lat: this.user.env.latitude,
                        lng: this.user.env.longitude,
                    },
                    //   gestureHandling: "cooperative",
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                        position: google.maps.ControlPosition.LEFT_CENTER,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    scaleControl: true,
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                })
                this.setGoogleMapStyle(false)
                // START ADD BACK BUTTON
                // if (this.isMobile === true) {
                const backButton = document.createElement('button')
                backButton.classList.add(
                    'font-semibold',
                    'flex',
                    'items-center',
                    // 'rounded',
                    'text-lg',
                    'py-2',
                    'theme-color',
                    'px-4',
                    'ml-3',
                    'mt-8'
                )
                const back = `${this.$t('back')}`
                const btnContent = document.createTextNode(back)
                backButton.appendChild(btnContent)
                this.map.controls[google.maps.ControlPosition.LEFT_CENTER].push(
                    backButton
                )
                backButton.addEventListener(screenEvent, (e) => {
                    this.back()
                })

                const childInterval = setInterval(() => {
                    if (document.getElementById('map')) {
                        if (
                            document
                                .getElementById('map')
                                .getElementsByTagName('div')[0]
                        ) {
                            const mapDiv = document
                                .getElementById('map')
                                .getElementsByTagName('div')[0]
                            if (
                                document.getElementById('custom-panel') &&
                                document.getElementById('custom-panel-2')
                            ) {
                                const panelTop =
                                    document.getElementById('custom-panel')
                                const panelBottom =
                                    document.getElementById('custom-panel-2')

                                clearInterval(childInterval)

                                mapDiv.appendChild(panelTop)
                                mapDiv.appendChild(panelBottom)

                                mapDiv.addEventListener(screenEvent, (e) => {
                                    if (document.getElementById('markStart')) {
                                        document
                                            .getElementById('markStart')
                                            .remove()
                                    }
                                    if (document.getElementById('markEnd')) {
                                        document
                                            .getElementById('markEnd')
                                            .remove()
                                    }
                                })
                            }
                        }
                    }
                }, 10)
                this.setTracking()

                // }
                // END BACK BUTTON
            }
        }, 1000)
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapState(useOrderStore, {
            trackedPackages: 'trackedPackages',
            currentOrder: 'current',
        }),

        isMobile() {
            return (
                (navigator.userAgentData &&
                    navigator.userAgentData.mobile &&
                    navigator.userAgentData.mobile === true) ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            )
        },
    },
    watch: {},
    methods: {
        ...mapActions(useOrderStore, ['trackOnePackage']),

        back() {
            this.$cookies.remove('currentTrackedPackageSteps')
            this.$router.go(-1)

            if (this.$router.options.history.state.back.includes('update')) {
                if (this.$cookies.get('currentDeliveryId')) {
                    this.$emit(
                        'goToUpdateOrder',
                        this.$cookies.get('currentDeliveryId')
                    )
                } else {
                    this.$emit('back', 'orders')
                }
            } else {
                this.$emit('back', 'orders')
            }
        },

        setTracking() {
            const themeColor = window
                .getComputedStyle(document.getElementById('themed'), null)
                .getPropertyValue('background-color')
            const symbol = {
                path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                scale: 1.3,
                strokeWeight: 0.5,
                fillOpacity: 1,

                fillColor: themeColor.toString(16),
                anchor: new google.maps.Point(10, 22),
                labelOrigin: new google.maps.Point(12, 8),
                labelInBackground: false,
            }
            const markStart = new google.maps.Marker({
                position: {
                    lat: parseFloat(
                        this.trackedPackages[0].steps.pickup.pos.lat
                    ),
                    lng: parseFloat(
                        this.trackedPackages[0].steps.pickup.pos.lng
                    ),
                },
                title: `${this.trackedPackages[0].steps.pickup.name} ${this.trackedPackages[0].steps.pickup.address} ${this.trackedPackages[0].steps.pickup.zipCity}`,
                icon: symbol,
                label: {
                    text: '\ue88a',
                    fontFamily: 'Material Icons',
                    color: '#000000',
                    fontSize: '18px',
                },
                optimized: false,
                animation: google.maps.Animation.DROP,
                clickable: true,
            })
            markStart.setMap(this.map)

            markStart.addListener(screenEvent, (evt) => {
                if (document.getElementById('markEnd')) {
                    document.getElementById('markEnd').remove()
                }
                if (document.getElementById('markStart')) {
                    document.getElementById('markStart').remove()
                } else {
                    evt.domEvent.cancelBubble = true // stop event propagation
                    const mapDiv = document
                        .getElementById('map')
                        .getElementsByTagName('div')[0]
                    const markerDiv = document.createElement('div')
                    markerDiv.style.position = 'absolute'
                    markerDiv.style.left = `${
                        evt.domEvent.pageX + markerDiv.offsetWidth / 3
                    }px`
                    markerDiv.style.top = `${
                        evt.domEvent.pageY - markerDiv.offsetHeight / 7
                    }px`

                    markerDiv.style.backgroundColor = themeColor.toString(16)
                    markerDiv.style.zIndex = 1000
                    markerDiv.setAttribute('id', 'markStart')

                    markerDiv.classList.add(
                        'text-zinc-900',
                        'border-2',
                        'rounded',
                        'text-xs',
                        'h-fit',
                        'min-w-[10%]',
                        'w-[10%]'
                    )

                    mapDiv.appendChild(markerDiv)

                    const innerMarkerDiv = document.createElement('div')

                    document
                        .getElementById('markStart')
                        .appendChild(innerMarkerDiv)
                    const newContent = document.createTextNode(
                        `${this.trackedPackages[0].steps.pickup.name} - ${this.trackedPackages[0].steps.pickup.address} ${this.trackedPackages[0].steps.pickup.zipCity} -
                        ${this.trackedPackages[0].steps.pickup.moment} `
                    )
                    innerMarkerDiv.classList.add('p-2', 'text-zinc-900')
                    innerMarkerDiv.appendChild(newContent)
                }
            })

            const markEnd = new google.maps.Marker({
                position: {
                    lat: parseFloat(
                        this.trackedPackages[0].steps.delivery.pos.lat
                    ),
                    lng: parseFloat(
                        this.trackedPackages[0].steps.delivery.pos.lng
                    ),
                },

                title: `${this.trackedPackages[0].steps.delivery.name} ${this.trackedPackages[0].steps.delivery.address}  ${this.trackedPackages[0].steps.delivery.zipCity}`,
                icon: symbol,
                label: {
                    text: '\uf06e',
                    fontFamily: 'Material Icons',
                    color: '#000000',
                    fontSize: '18px',
                },

                optimized: false,
                animation: google.maps.Animation.DROP,
                clickable: false,
            })
            markEnd.setMap(this.map)
            markEnd.addListener(screenEvent, (evt) => {
                if (document.getElementById('markStart')) {
                    document.getElementById('markStart').remove()
                }
                if (document.getElementById('markEnd')) {
                    document.getElementById('markEnd').remove()
                } else {
                    evt.domEvent.cancelBubble = true // stop event propagation
                    const mapDiv = document
                        .getElementById('map')
                        .getElementsByTagName('div')[0]
                    const markerDiv = document.createElement('div')
                    markerDiv.style.position = 'absolute'
                    markerDiv.style.left = `${
                        evt.domEvent.pageX + markerDiv.offsetWidth / 3
                    }px`
                    markerDiv.style.top = `${
                        evt.domEvent.pageY - markerDiv.offsetHeight / 7
                    }px`

                    markerDiv.style.backgroundColor = themeColor.toString(16)
                    markerDiv.style.zIndex = 1000
                    markerDiv.setAttribute('id', 'markEnd')

                    markerDiv.classList.add(
                        'text-zinc-900',
                        'border-2',
                        'rounded',
                        'text-xs',
                        'h-fit',
                        'min-w-[10%]',
                        'w-[10%]'
                    )

                    mapDiv.appendChild(markerDiv)

                    const innerMarkerDiv = document.createElement('div')

                    document
                        .getElementById('markEnd')
                        .appendChild(innerMarkerDiv)
                    const newContent = document.createTextNode(
                        `${this.trackedPackages[0].steps.delivery.name} - ${this.trackedPackages[0].steps.delivery.address} ${this.trackedPackages[0].steps.delivery.zipCity} -
                        ${this.trackedPackages[0].steps.delivery.moment} `
                    )
                    innerMarkerDiv.classList.add('p-2', 'text-zinc-900')
                    innerMarkerDiv.appendChild(newContent)
                }
            })
            let polyline = null

            const coordinatesForPolylines = []
            let tourPath = null

            this.trackedPackages[0].coordinates.forEach((coor, index) => {
                coordinatesForPolylines.push({
                    lat: +coor[1],
                    lng: +coor[0],
                })
            })
            tourPath = {
                path: coordinatesForPolylines,
                // geodesic: true, // hearth curves
                strokeColor: themeColor.toString(16),
                strokeOpacity: 1.0,
                fillOpacity: 0,
                strokeWeight: 3,
            }
            polyline = new google.maps.Polyline(tourPath)
            polyline.setMap(this.map)
        },
    },

    unmounted() {
        this.$cookies.remove('currentTrackedPackageSteps')
    },
}
</script>

<style lang="scss" scoped>
#wrapper {
    position: relative;
}
.tour-opti {
    position: relative;
    top: 10px;
    z-index: 24;
}
.over-map {
    position: relative;
    top: 10px;
    z-index: 24;
    background-color: rgba(7, 2, 2, 0.5);
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.light-over-map {
    position: relative;
    top: 10px;
    z-index: 24;
    height: 70%;
    background-color: unset !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}
.light-in-over-map {
    z-index: 25;
    // opacity: 0.2 !important;
    height: auto;

    background-color: rgba(236, 240, 241, 0.95) !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}
.in-over-map {
    z-index: 25;
    // opacity: 0.2 !important;
    background-color: rgba(7, 2, 2, 0.75) !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#map ::v-deep(.gm-style button) {
    border: 2 !important;
    opacity: 0.5 !important;
    cursor: pointer;
    background-color: black !important;
    color: rgba(161, 161, 170, 0.5) !important;
}
#map ::v-deep(ul) {
    opacity: 0.5 !important;
    background-color: black !important;
}
#map ::v-deep(li) {
    background-color: black !important;

    color: rgba(161, 161, 170, 1) !important;
}
#map ::v-deep(.gm-svpc) {
    opacity: 0.5 !important;
    background-color: black !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.over-map::-webkit-scrollbar {
    display: none;
}
.in-over-map::-webkit-scrollbar {
    display: none;
}
// .light-over-map::-webkit-scrollbar {
//     height: 24px;
//     width:20px;
// }
.ms-width ::v-deep(.multiselect) {
    // height: auto;
    // width: 18rem;
    border-radius: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.input-custom-round {
    border-radius: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
</style>
