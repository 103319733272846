import CreateOrUpdate from '../views/clients/CreateOrUpdate.vue'
import CreateOrUpdateAddress from '../views/clients/CreateOrUpdateAddress.vue'
import Wrapper from '../views/clients/Wrapper.vue'

import ContactClientHistoryList from '@/views/clients/ContactClientHistoryList.vue'
import ContractsList from '../views/clients/ContractsList.vue'
import AddressesList from '../views/clients/AddressesList.vue'
import ClientContactsList from '../views/clients/ClientContactsList.vue'
import CreateOrUpdateContact from '../views/clients/CreateOrUpdateContact.vue'

const clients = [
    {
        path: '/clients',
        name: 'clients',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'CLIENTS_FULL', 'CLIENTS_LIST'],
            showUniverseSearch: true,
        },
        children: [
            {
                path: 'new',
                name: 'new_client',
                component: CreateOrUpdate,
                meta: {
                    authorisations: ['FULL_ACCESS', 'CLIENTS_FULL'],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'update/:id',
                name: 'update_client',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',
                        'CLIENTS_UPDATE',
                    ],
                    showUniverseSearch: false,
                },
            },

            {
                path: 'delivery-address/update/:id',
                name: 'update_address',
                component: CreateOrUpdateAddress,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',

                        'DELIVERY-ADDRESSES_FULL',
                        'DELIVERY-ADDRESSES_UPDATE',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'delivery-address/new',
                name: 'new_address',
                component: CreateOrUpdateAddress,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',

                        'DELIVERY-ADDRESSES_FULL',
                        'DELIVERY-ADDRESSES_CREATE',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'delivery-address/new/:client',
                name: 'new_address_for',
                component: CreateOrUpdateAddress,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',
                        'DELIVERY-ADDRESSES_FULL',
                        'DELIVERY-ADDRESSES_UPDATE',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'contracts',
                name: 'contracts',
                component: ContractsList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',
                        'CONTRACT_FULL',
                        'CONTRACT_LIST',
                    ],
                    showUniverseSearch: true,
                },
            },
            {
                path: 'delivery-addresses',
                name: 'delivery_addresses',
                component: AddressesList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',

                        'DELIVERY-ADDRESSES_FULL',
                        'DELIVERY-ADDRESSES_LIST',
                    ],
                    showUniverseSearch: false,
                },
                children: [
                    {
                        path: ':id',
                        name: 'client_delivery_addresses',
                        component: AddressesList,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'CLIENTS_FULL',
                                'DELIVERY-ADDRESSES_FULL',
                                'DELIVERY-ADDRESSES_LIST',
                            ],
                            showUniverseSearch: false,
                        },
                    },
                ],
            },
            {
                path: 'contact-client-history',
                name: 'contact_client_history',
                component: ContactClientHistoryList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        // 'CLIENTS_FULL',

                        'CONTACT-CLIENT-HISTORY_FULL',
                        'CONTACT-CLIENT-HISTORY_LIST',
                    ],
                    showUniverseSearch: true,
                },
            },
            {
                path: 'contacts/:client_id',
                name: 'contacts',
                component: ClientContactsList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'CLIENTS_FULL',
                        'CONTACTS_FULL',
                        'CONTACTS_LIST',
                    ],
                    showUniverseSearch: false,
                },
                children: [
                    {
                        path: ':id',
                        name: 'update_contact',
                        component: CreateOrUpdateContact,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'CLIENTS_FULL',
                                'CONTACTS_FULL',
                                'CONTACTS_UPDATE',
                            ],
                            showUniverseSearch: false,
                        },
                    },
                    {
                        path: 'new',
                        name: 'new_contact',
                        component: CreateOrUpdateContact,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'CLIENTS_FULL',
                                'CONTACTS_FULL',
                                'CONTACTS_CREATE',
                            ],
                            showUniverseSearch: false,
                        },
                    },
                ],
                //     },
                // ],
            },
        ],
    },
]

export default clients
