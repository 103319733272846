<template>
    <svg
        @click.stop="$emit('finishedClicked')"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="10" :fill="bgColor" />
        <path
            d="M14.7908 6.23434C15.0697 6.54679 15.0697 7.05421 14.7908 7.36666L9.07711 13.7657C8.79813 14.0781 8.34505 14.0781 8.06606 13.7657L5.20924 10.5662C4.93025 10.2537 4.93025 9.74629 5.20924 9.43384C5.48823 9.12139 5.9413 9.12139 6.22029 9.43384L8.5727 12.0659L13.7819 6.23434C14.0609 5.92189 14.514 5.92189 14.793 6.23434H14.7908Z"
            fill="url(#paint0_linear_1501_27275)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1501_27275"
                x1="8"
                y1="14"
                x2="17.5"
                y2="2.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="icColor" />
                <stop offset="0.226003" :stop-color="icColor" />
                <stop
                    offset="0.678993"
                    :stop-color="icColor"
                    stop-opacity="0"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'TraceIcon',

    props: {
        bgColor: {
            type: String,
            default: '#68686A',
        },
        icColor: {
            type: String,
            default: '#FFFFFF',
        },
       
    },
}
</script>

