<template>
    <div class="mt-20 flex mb-3 items-center">
        <icon-button
            :title="$t('back')"
            :icon="'arrow-left'"
            @click="$emit('back')"
        />
        <div class="text-3xl ml-2">
            {{ $t(text) }}
        </div>
    </div>
</template>
<script>
import IconButton from '@/components/atoms/IconButton.vue'

export default {
    name: 'BackTo',
    components: { IconButton },
    props: {
        text: {
            type: String,
            default: 'back',
        },
    },
}
</script>
