<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />
        <div
            id="create-address"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <form
                    class="mb-2"
                    @keydown.enter.prevent
                    @submit.prevent="
                        $route.params.id ? updateVehicle() : createVehicle()
                    "
                >
                    <div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div class="rounded-md col-span-3 border-2 p-4">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('vehicle_information') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="grid grid-cols-6 gap-2 mt-2">
                                    <div
                                        class="mb-3 items-center"
                                        :class="
                                            formData.Type === ''
                                                ? 'col-span-3'
                                                : 'col-span-2'
                                        "
                                    >
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{
                                                $t('vehicle_type') + ' *'
                                            }}</label
                                        >
                                        <Multiselect
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="formData.Type"
                                            :options="typeOptions"
                                            label="name"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="$t('select_type')"
                                            :allow-empty="false"
                                            :can-clear="false"
                                        ></Multiselect>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.Type
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="formData.Type !== ''"
                                        class="mb-3 items-center col-span-2"
                                    >
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('name') + ' *' }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.Name"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.Name
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TODO mettre un ms de  vehicules une fois que le type est choisi -->
                                    <div
                                        class="mb-3 items-center"
                                        :class="
                                            formData.Type === ''
                                                ? 'col-span-3'
                                                : 'col-span-2'
                                        "
                                    >
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('powered') + ' *' }}</label
                                        >
                                        <Multiselect
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="formData.Powered"
                                            :options="powerOptions"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :can-deselect="false"
                                            :can-clear="false"
                                            label="name"
                                            track-by="name"
                                        ></Multiselect>

                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.Powered
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid grid-cols-6 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('brand') + ' *' }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.Brand"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.Brand
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('model') + ' *' }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.Model"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.Model
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{
                                                $t('registration') + ' *'
                                            }}</label
                                        >
                                        <input
                                            type="tel"
                                            v-model="formData.Registration"
                                            @blur="
                                                v$.formData.Registration.$touch
                                            "
                                            autocomplete="tel"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData
                                                .Registration.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('consumption') }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.Consumption"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>

                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('annual_cost') }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.AnnualCost"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('comments') }}</label
                                    >
                                    <textarea
                                        v-model="formData.Informations"
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 w-full"
                                    />
                                </div>
                                <div class="px-3">
                                    <div
                                        v-if="currentdriver"
                                        class="rounded-md border-1 p-er"
                                    >
                                        <span>
                                            {{ $t('associated_driver') }}
                                            <hr />
                                        </span>
                                        <ul>
                                            <li class="mt-2 mb-2">
                                                {{
                                                    `${$t('name')} : ${
                                                        currentdriver.firstname
                                                    } ${currentdriver.lastname}`
                                                }}
                                            </li>

                                            <li
                                                v-if="currentdriver.phone_pro"
                                                class="mb-2"
                                            >
                                                {{
                                                    `${$t('phone_pro')} : ${
                                                        currentdriver.phone_proro
                                                    }`
                                                }}
                                            </li>
                                            <li
                                                v-if="currentdriver.phone_perso"
                                                class="mb-2"
                                            >
                                                {{
                                                    `${$t('phone_perso')} : ${
                                                        currentdriver.phone_perso
                                                    }`
                                                }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md col-span-3 border-2 p-4">
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('vehicle_specifications') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="grid grid-cols-6 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('width') }}</label
                                            >
                                            <input
                                                type="number"
                                                v-model="formData.BoxWidth"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                min="0"
                                            />
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('length') }}</label
                                            >
                                            <input
                                                type="number"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                v-model="formData.BoxLength"
                                                min="0"
                                            />
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('height') }}</label
                                            >
                                            <input
                                                type="number"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                v-model="formData.BoxHeight"
                                                min="0"
                                            />
                                        </div>
                                    </div>

                                    <div class="grid grid-cols-6 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('volume') }}</label
                                            >
                                            <input
                                                type="number"
                                                v-model="formData.Capacity"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                min="0"
                                            />
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                            >
                                                {{ $t('volume_info') }}
                                            </div>
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    `${$t('max_volume')}`
                                                }}</label
                                            >
                                            <input
                                                type="number"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                v-model="formData.LimitCapacity"
                                                min="0"
                                            />
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('max_load') }}</label
                                            >
                                            <input
                                                v-model="formData.WeightMaxi"
                                                type="number"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                min="0"
                                            />
                                        </div>
                                    </div>
                                    <div class="mt-6 mb-6 flex">
                                        <span class="text-lg">
                                            {{ $t('package_specifications') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="my-2 items-center">
                                        <label
                                            class="font-semibold whitespace-nowrap text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{
                                                $t('package_max_length')
                                            }}</label
                                        >
                                        <input
                                            type="number"
                                            class="border rounded px-3 py-2 mt-1 w-1/4"
                                            v-model="formData.MaxiLength"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="creating"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="$route.params.id && canAccessMaintenancesList === true">
                <div class="rounded-md col-span-3 border-2 p-4">
                    <div class="mb-5 flex">
                        <span class="text-lg">
                            {{ $tc('maintenance', 2) }}
                            <hr />
                        </span>
                    </div>
                    <fetch-data-error
                        v-if="fetchError"
                        :dataType="'vehicle maintenance'"
                    />
                    <div
                        v-if="fetchError"
                        class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
                    >
                        <button
                            type="button"
                            @click="newMaintenance = true"
                            class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                        >
                            <fa-icon icon="plus" class="fa-sm mr-2"></fa-icon>

                            {{ $t('add_maintenance') }}
                        </button>
                    </div>

                    <filtrable-table
                        v-else
                        id="maintenanceList"
                        :items="maintenances"
                        :columns="columns"
                        :storeToSort="'vehicles'"
                        :page="page"
                        :count="maintenancesCount"
                        :loading="loading"
                        :multiActions="multiActions"
                        @perPageChanged="changePerPage($event)"
                        @updateSort="displaySearchResults($event)"
                        :perPage="perPage"
                        :sortDirection="sortDirection"
                        :sortableFields="sortableFields"
                        :totalPages="totalPages"
                        :searchQuery="searchQuery"
                        :refresh="refresh"
                        :canAddNew="
                            canAccessMaintenancesCreate &&
                            newMaintenance === false
                        "
                        :itemActions="itemActions"
                        @pageChanged="setPage($event)"
                        @searched="debounceSearch"
                        @sortDirectionChanged="updateSortDirection($event)"
                        @selected="updateFilter($event)"
                        @create="displayCreateMaintenance()"
                        @clear="clearFilter($event)"
                        @update="setMaintenanceToUpdate($event)"
                        @setAsDone="setAsDone($event)"
                        @delete="deleteOne($event)"
                        @deleteAll="deleteAll($event)"
                    >
                    </filtrable-table>
                    <modal
                        v-if="newMaintenance === true"
                        :headerText="
                            isUpdate
                                ? $t('update_maintenance')
                                : $t('add_maintenance')
                        "
                        :buttonText="isUpdate ? 'update' : 'save'"
                        :spin="creatingMaintenance"
                        :creating="creatingMaintenance"
                        id="addModal"
                        :mxWidth="'max-w-8xl'"
                        :mxHeight="'max-h-screen'"
                        :buttonAlign="'justify-center'"
                        @closeModal="resetMaintenanceForm()"
                        @sendFromModal="
                            isUpdate
                                ? updateCurrentMaintenance()
                                : saveMaintenance()
                        "
                    >
                        <template #body>
                            <div class="grid grid-cols-8 gap-2 mt-2 px-4">
                                <div class="col-span-2 mb-3 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('title') + ' *' }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="maintenanceFormData.Title"
                                        @blur="
                                            v$.maintenanceFormData.Title.$touch
                                        "
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.maintenanceFormData
                                            .Title.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-2 mb-3 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('start_date') + ' *' }}</label
                                    >
                                    <input
                                        type="datetime-local"
                                        :min="
                                            new Date(
                                                new Date().getTime() -
                                                    new Date().getTimezoneOffset() *
                                                        60000
                                            )
                                                .toISOString()
                                                .substr(0, 16)
                                        "
                                        v-model="maintenanceFormData.DateBegin"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-if="badTimeRange"
                                    >
                                        <div class="error-msg">
                                            {{ $t('bad_time_range') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-2 mb-3 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('end_date') + ' *' }}</label
                                    >
                                    <input
                                        type="datetime-local"
                                        :min="maintenanceFormData.DateBegin"
                                        v-model="maintenanceFormData.DateEnd"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="col-span-2 mb-3 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('done_date') }}</label
                                    >
                                    <input
                                        type="datetime-local"
                                        :min="
                                            new Date(
                                                new Date().getTime() -
                                                    new Date().getTimezoneOffset() *
                                                        60000
                                            )
                                                .toISOString()
                                                .substr(0, 16)
                                        "
                                        v-model="maintenanceFormData.DoneDate"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>

                            <div class="items-center flex justify-center py-4">
                                <input
                                    id="set-active-checkbox"
                                    type="checkbox"
                                    v-model="maintenanceFormData.Urgent"
                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                />
                                <label
                                    for="set-active-checkbox"
                                    class="ml-2 font-medium"
                                    >{{ $t('urgent') }}</label
                                >
                            </div>

                            <div class="mb-3 items-center px-4">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                    >{{ $t('informations') }}</label
                                >
                                <textarea
                                    v-model="maintenanceFormData.Informations"
                                    class="border rounded px-3 py-2 w-full"
                                />
                            </div>
                            <span
                                class="flex mt-1 italic text-xs items-center justify-center"
                            >
                                {{ $t('required_fields') }}
                            </span>
                        </template>
                    </modal>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import BackTo from '@/components/elements/BackTo.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useDriverStore } from '@/stores/driverStore'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    name: 'CreateOrUpdateVehicle',
    components: { Multiselect, BackTo, FiltrableTable, FetchDataError, Modal },
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [filterList],

    async mounted() {
        window.scrollTo(0, 0)

        this.currentVehicleInterval = setInterval(() => {
            if (this.currentVehicle) {
                clearInterval(this.currentVehicleInterval)
                this.formData = { ...this.currentVehicle }
                this.setRealSearch(this.search)
                this.filter.push({
                    dbField: 'IdVehicle',
                    value: this.$route.params.id,
                    name: this.currentVehicle.Name,
                })
                this.setRealSelected('IdVehicle', 0)
            }
        }, 1000)
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            statusOptions: [
                { name: this.$t('todo'), value: 'todo', dbField: 'Status' },
                { name: this.$tc('done', 1), value: 'done', dbField: 'Status' },
                { name: this.$t('late'), value: 'late', dbField: 'Status' },
            ],
            maintenanceFormData: {
                Title: '',
                DateBegin: new Date(
                    new Date().getTime() -
                        new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 16),
                DateEnd: new Date(
                    new Date().getTime() -
                        new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 16),
                CreationDate: '',
                ModificationDate: '',
                CreationUserId: '',
                ModificationUserId: '',
                Informations: '',
                IdVehicle: '',
                DoneDate: null,
                Urgent: false,
                Status: 'todo',
                Id: '',
            },
            newMaintenance: false,

            sortableFields: [
                { name: this.$t('title'), value: 'Title' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('start_date'), value: 'DateBegin' },
                { name: this.$t('end_date'), value: 'DateEnd' },
            ],
            currentVehicleInterval: null,
            contact: null,
            creating: false,
            isUpdate: false,
            badTimeRange: false,
            formData: {
                IdExternal: '',
                Brand: '',
                Model: '',
                Type: '',
                Powered: '',
                Capacity: null,
                WeightMaxi: null,
                BoxWidth: null,
                BoxLength: null,
                BoxHeight: null,
                MaxiLength: null,
                LimitCapacity: null,
                Informations: '',
                Consumption: null,
                AnnualCost: null,
                Registration: '',
                Active: '1',
                TimeBegin: null,
                TimeEnd: null,
                BreakBegin: null,
                BreakEnd: null,
                BreakDuration: null,
                CreationDate: '',
                CreationUserId: '',
                ModificationDate: '',
                ModificationUserId: '',
            },
            creatingMaintenance: false,
            typeOptions: [
                { name: this.$t('bike'), value: 'bike' },
                { name: this.$t('cargo_bike'), value: 'cargoBike' },
                {
                    name: this.$t('delivery_tricycle'),
                    value: 'deliveryTricycle',
                },
                { name: this.$t('car'), value: 'car' },
                { name: this.$t('van'), value: 'van' },
                { name: this.$t('truck'), value: 'truck' },
                { name: this.$t('boat'), value: 'boat' },
            ],
            powerOptions: [
                { name: this.$t('legs'), value: 'legs' },
                { name: this.$t('hydrogen'), value: 'hydrogen' },

                { name: this.$t('gasoline'), value: 'gasoline' },

                { name: this.$t('gas'), value: 'gas' },
                { name: this.$t('diesel'), value: 'diesel' },
                { name: this.$t('electric'), value: 'electric' },
                { name: this.$t('hybrid_gasoline'), value: 'hybrideGasoline' },
                { name: this.$t('hybrid_diesel'), value: 'hybridDiesel' },
                {
                    name: this.$t('plugin_hybrid_diesel'),
                    value: 'pluginHybridDiesel',
                },
                {
                    name: this.$t('plugin_hybrid_gasoline'),
                    value: 'pluginHybridGasoline',
                },
            ],
        }
    },
    validations() {
        return {
            formData: {
                Brand: { required },
                Name: { required },

                Model: { required },
                Type: { required },
                Powered: { required },
                Registration: { required },
            },
            maintenanceFormData: {
                Title: { required },
                DateBegin: { required },
                DateEnd: { required },
            },
        }
    },
    computed: {
        ...mapState(useVehicleStore, {
            maintenances: 'maintenances',
            maintenancesCount: 'maintenancesCount',
            count: 'count',
            totalPages: 'totalPages',
            loading: 'loading',
            fetchError: 'fetchError',
        }),
        canAccessMaintenancesUpdate() {
            return checkAccess(
                this.userStore,
                actionsAccess.vehicles.maintenances.update
            )
        },
        canAccessMaintenancesList() {
            return checkAccess(
                this.userStore,
                actionsAccess.vehicles.maintenances.list
            )
        },
        canAccessMaintenancesCreate() {
            return checkAccess(
                this.userStore,
                actionsAccess.vehicles.maintenances.create
            )
        },
        canAccessMaintenancesDelete() {
            return checkAccess(
                this.userStore,
                actionsAccess.vehicles.maintenances.delete
            )
        },
        ...mapWritableState(useVehicleStore, {
            currentVehicle: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useUserStore),
        ...mapWritableState(useDriverStore, {
            currentdriver: 'current',
            allDrivers: 'all',
        }),
        itemActions() {
            const actions = []
            if (this.canAccessMaintenancesUpdate) {
                actions.push(
                    {
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                    },
                    {
                        name: 'set_as_done',
                        action: 'setAsDone',
                        icon: 'check',
                    }
                )
            }
            if (this.canAccessMaintenancesDelete) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (this.canAccessMaintenancesDelete) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            return actions
        },
        columns() {
            return [
                {
                    name: 'title',
                    filtrable: true,
                    dbField: 'Title',
                    type: 'search',
                    sortable: true,
                    isLink: true,
                },
                {
                    name: 'status',
                    filtrable: true,
                    type: 'select',
                    dbField: 'Status',

                    sortable: false,
                    canSearch: true,
                    canUpdate: this.canAccessMaintenancesUpdate,

                    // selected: ,
                    options: this.statusOptions,
                },
                {
                    name: 'start_date',
                    dbField: 'DateBegin',
                    sortable: true,
                },
                {
                    name: 'end_date',
                    dbField: 'DateEnd',
                    sortable: true,
                },
                {
                    name: 'done_date',
                    dbField: 'DoneDate',
                    sortable: true,
                },

                {
                    name: 'urgent',
                    filtrable: true,
                    dbField: 'Urgent',
                    archive: true,
                    type: 'select',
                    sortable: false,
                    withIconNoText: true,

                    options: [
                        {
                            name: 'not_urgent',
                            value: 0,
                            dbField: 'Urgent',
                        },
                        {
                            name: 'urgent',
                            value: 1,
                            dbField: 'Urgent',
                        },
                    ],
                },
                {
                    name: 'informations',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
    },
    watch: {
        maintenances() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    methods: {
        ...mapActions(useVehicleStore, [
            'deleteMaintenance',
            'addMaintenance',
            'updateMaintenance',
            'updateMaintenanceStatus',
            'create',
            'update',
            'searchMaintenances',
        ]),
        setAsDone(id) {
            const payload = {
                Id: id,
                Status: 'done',
                ModificationUserId: this.user.id,
            }
            this.updateMaintenanceStatus(payload)
                .then(() => {
                    this.$toast.success(this.$t('maintenance_update_ok'))

                    this.setResults()
                })
                .catch(() => {
                    this.$toast.error(this.$t('maintenance_update_ko'))
                })
        },
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.currentVehicle = null
            this.$emit('back', 'vehicles')
        },
        checkTimes() {
            if (
                this.maintenanceFormData.DateEnd <
                    this.maintenanceFormData.DateBegin ||
                this.maintenanceFormData.DateEnd ===
                    this.maintenanceFormData.DateBegin
            ) {
                this.badTimeRange = true
                return true
            }
            return false
        },
        displayCreateMaintenance() {
            this.newMaintenance = true
            this.isUpdate = false
        },
        async deleteOne(id) {
            const response = await this.deleteMaintenance(id)
            if (response.status === 200) {
                this.setResults()
                this.$toast.success(this.$t('maintenance_delete_ok'))
            } else {
                this.$toast.error(this.$t('maintenance_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.deleteMaintenance(el)
            })
            this.setResults()
        },
        async saveMaintenance() {
            this.creatingMaintenance = true
            this.badTimeRange = false
            this.maintenanceFormData.CreationUserId = this.user.id
            this.maintenanceFormData.ModificationUserId = this.user.id
            this.maintenanceFormData.IdVehicle = this.$route.params.id

            const formIsValid = await this.v$.maintenanceFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                try {
                    const response = await this.addMaintenance(
                        this.maintenanceFormData
                    )
                    if (response) {
                        this.resetMaintenanceForm()
                        this.$toast.success(this.$t('maintenance_create_ok'))
                    }
                } catch (err) {
                    console.log(err)
                    this.creatingMaintenance = false
                    this.$toast.error(`${this.$t('maintenance_create_ko')}`)
                }
            } else {
                this.creatingMaintenance = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateCurrentMaintenance() {
            this.creatingMaintenance = true
            this.badTimeRange = false

            this.maintenanceFormData.ModificationUserId = this.user.id

            const formIsValid = await this.v$.maintenanceFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                try {
                    const response = await this.updateMaintenance({
                        id: this.maintenanceFormData.Id,
                        form: this.maintenanceFormData,
                    })
                    if (response) {
                        this.resetMaintenanceForm()

                        this.$toast.success(this.$t('maintenance_update_ok'))
                    }
                } catch (err) {
                    console.log(err)
                    this.creatingMaintenance = false
                    this.$toast.error(`${this.$t('maintenance_update_ko')}`)
                }
            } else {
                this.creatingMaintenance = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async setMaintenanceToUpdate(maintenanceId) {
            const maintenanceToUpdate = this.maintenances.find(
                (maintenance) => maintenance.id === maintenanceId
            )
            this.isUpdate = true
            this.newMaintenance = true

            const start = new Date(
                new Date(maintenanceToUpdate.start_date).getTime() -
                    new Date(
                        maintenanceToUpdate.start_date
                    ).getTimezoneOffset() *
                        60000
            ).toISOString()

            const end = new Date(
                new Date(maintenanceToUpdate.end_date).getTime() -
                    new Date(maintenanceToUpdate.end_date).getTimezoneOffset() *
                        60000
            ).toISOString()
            this.maintenanceFormData = {
                Title: maintenanceToUpdate.title,
                DateBegin: start.substr(0, 16),
                DateEnd: end.substr(0, 16),
                CreationDate: maintenanceToUpdate.creation_date,
                CreationUserId: maintenanceToUpdate.creation_user_id,
                Informations: maintenanceToUpdate.informations,
                Urgent: maintenanceToUpdate.urgent,
                DoneDate: maintenanceToUpdate.done_date,
                Status: maintenanceToUpdate.status,
                IdVehicle: this.$route.params.id,
                ModificationDate: '',
                ModificationUserId: this.user.id,
                Id: maintenanceToUpdate.id,
            }

            window.scrollTo(0, document.body.scrollHeight)
        },
        resetMaintenanceForm() {
            this.newMaintenance = false
            this.isUpdate = false
            this.creatingMaintenance = false
            this.badTimeRange = false

            const start = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 16)
            const end = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 16)
            this.maintenanceFormData = {
                Title: '',
                DateBegin: start,
                DateEnd: end,
                CreationDate: '',
                CreationUserId: '',
                Informations: '',
                IdVehicle: this.$route.params.id,
            }
            this.setResults()
        },
        async createVehicle() {
            this.creating = true
            this.formData.CreationUserId = this.user.id
            this.formData.ModificationUserId = this.user.id

            const isFormCorrect = await this.v$.formData.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                try {
                    const response = await this.create(this.formData)
                    if (response) {
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('vehicle_create_ok'))
                        this.$emit('created', 'vehicles')
                    }
                } catch (err) {
                    console.log(err)
                    this.creating = false
                    this.$toast.error(`${this.$t('vehicle_create_ko')}`)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateVehicle() {
            this.creating = true
            this.formData.ModificationUserId = +this.user.id

            const isFormCorrect = await this.v$.formData.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response = this.update(params)
                    if (response.status) {
                        this.$toast.error(this.$t('vehicle_update_ko'))
                        // this.$toast.error(response.data.message)
                        this.creating = false
                    } else {
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('vehicle_update_ok'))
                        this.$emit('updated', 'vehicles')
                    }
                } catch (err) {
                    console.log(err)
                    this.creating = false
                    this.$toast.error(`${this.$t('vehicle_update_ko')}`)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        setPage(isNext) {
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.filter.push({
                dbField: 'IdVehicle',
                value: this.$route.params.id,
            })
            this.sortField =
                search.sortField === 'client' ? '' : search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.searchMaintenances(search)
            this.refresh += 1
        },
        changePerPage(perPage) {
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        this.currentVehicle = null
        this.currentdriver = null
        this.allDrivers = null
    },
}
</script>
