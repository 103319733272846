<template>
    <div class="mode-toggle mt-2" @click="modeToggle" :class="darkDark">
        <div class="toggle">
            <div id="dark-mode" type="checkbox"></div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'NightModeSwitch',
    data() {
        return {
            darkMode: false,
        }
    },
    computed: {
        darkDark() {
            return this.darkMode && 'darkmode-toggled'
        },
        ...mapStores(useUserStore),
    },
    mounted() {
        if (this.$route.name !== 'login') {
            if (this.$cookies.get('nightMode') === 'true') {
                this.dark()
            } else {
                this.light()
            }
        }
    },
    methods: {
        dark() {
            document.querySelector('body').classList.add('dark-mode')
            document.querySelector('html').classList.add('dark-mode')

            this.darkMode = true
            this.$emit('dark')
            this.userStore.$patch({ nightMode: true })

            this.$cookies.set('nightMode', true)
        },

        light() {
            document.querySelector('body').classList.remove('dark-mode')
            document.querySelector('html').classList.remove('dark-mode')

            this.darkMode = false
            this.$emit('light')
            this.userStore.$patch({ nightMode: false })
            this.$cookies.set('nightMode', false)
        },
        modeToggle() {
            if (
                this.darkMode ||
                document.querySelector('body').classList.contains('dark-mode')
            ) {
                this.light()
            } else {
                this.dark()
            }
        },
    },
}
</script>
