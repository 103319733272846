import { defineStore } from 'pinia'

const useVehicleStore = defineStore('vehicle', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        totalPages: 1,
        current: null,
        maintenances: null,
        maintenancesCount: null,
        loading: false,
    }),
    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/vehicle/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/vehicle/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const vehicles = []
                            // TODO récupérer les contacts
                            res.data.Records.forEach((vehicle) => {
                                const formattedVehicle = {
                                    id: +vehicle.Id,
                                    brand: vehicle.Brand,
                                    model: vehicle.Model,
                                    type: vehicle.Type,
                                    powered: vehicle.Powered,
                                    active: +vehicle.Active,
                                    name: vehicle.Name,
                                    capacity: vehicle.Capacity,
                                    box_height: vehicle.BoxHeight,
                                    box_width: vehicle.BoxWidth,
                                    box_length: vehicle.BoxLength,
                                    max_weight: vehicle.WeightMaxi,
                                    max_length: vehicle.LengthMaxi,
                                    limit_capacity: vehicle.LimitCapacity,
                                    registration: vehicle.Registration,
                                    creation_date: vehicle.CreationDate,
                                    creation_user_id: +vehicle.CreationUserId,
                                }
                                vehicles.push(formattedVehicle)
                            })

                            this.all = vehicles
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        availableVehiclesForDate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/vehicle/available?date=${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/vehicle', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/vehicle/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        updateMaintenance(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/vehiclemaintenance/${payload.id}`,
                        payload.form,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateMaintenanceStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/vehiclemaintenance/updateStatus`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/vehicle/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const vehicle = res.data
                        this.current = vehicle

                        const maintenancesPayload = {
                            query: [],
                            filter: [{ dbField: 'IdVehicle', value: payload }],
                            perPage: 100,
                            page: 1,
                            sortField: 'CreationDate',
                            sortDirection: 'ASC',
                        }
                        this.searchMaintenances(maintenancesPayload).then(
                            () => {
                                resolve(res)
                            }
                        )
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        searchMaintenances(payload) {
            let query = []
            this.fetchError = false

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            const statuses = []
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                  
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'IdVehicle') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (
                        type.dbField === 'Status' &&
                        payload.onlyLatePlus30Todo === false
                    ) {
                        statuses.push(`"${type.value}"`)
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            if (statuses.length > 0) {
                query.push(
                    `{"Status":[{"operator":"IN","value":[${statuses}]}]}`
                )
            }
            if (payload.onlyLatePlus30Todo === true && statuses.length === 0) {
                let datePlus30 = new Date()
                datePlus30.setDate(datePlus30.getDate() + 30)
                datePlus30 = datePlus30.toISOString().slice(0, 10)
                query.push(
                    `{ "Status":[{"operator":"NOT LIKE","value":"done"}]}`
                )
                query.push(
                    `{ "Status":[{"operator":"LIKE","value":"late"}], "DateBegin":[{"operator":"<=","value":"${datePlus30}%"}]}`
                )
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/vehiclemaintenance/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/vehiclemaintenance/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const maintenances = []
                            // TODO récupérer les contacts
                            if (res.data.TotalResults > 0) {
                                res.data.Records.forEach((maintenance) => {
                                    const formattedMaintenance = {
                                        id: +maintenance.Id,
                                        title: maintenance.Title,
                                        start_date: maintenance.DateBegin,
                                        end_date: maintenance.DateEnd,
                                        informations: maintenance.Informations,
                                        status: maintenance.Status,
                                        urgent: +maintenance.Urgent,
                                        done_date: maintenance.DoneDate,
                                        creation_date: maintenance.CreationDate,
                                        vehicleId: +maintenance.IdVehicle,
                                        vehicle: maintenance.Vehicle.Name,
                                        creation_user_id:
                                            +maintenance.CreationUserId,
                                    }
                                    maintenances.push(formattedMaintenance)
                                })
                                this.maintenances = maintenances
                            }

                            this.maintenancesCount = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.maintenances = null

                            this.maintenancesCount = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        deleteMaintenance(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/vehiclemaintenance/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        addMaintenance(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/vehiclemaintenance', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deactivate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/vehicle/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})
export { useVehicleStore }
