const prefixes = {
    becycle: {
        order: 'BC',
        invoice: 'BF',
        package: 'BF',
        delivery: 'BD',
        defaultDeliveryMode: 'bike',
    },
    localhost: {
        order: 'DEVO',
        invoice: 'DEVI',
        package: 'DEVP',
        delivery: 'DEVD',

        defaultDeliveryMode: 'bike',
    },
    dev: {
        order: 'DEVO',
        invoice: 'DEVI',
        package: 'DEVP',
        delivery: 'DEVD',

        defaultDeliveryMode: 'bike',
    },
    preprod: {
        order: 'PREPRODO',
        invoice: 'PREPRODI',
        package: 'PREPRODP',
        delivery: 'PREPRODD',

        defaultDeliveryMode: 'bike',
    },
    demo01: {
        order: 'DEMO-01O',
        invoice: 'DEMO-01I',
        package: 'DEMO-01P',
        delivery: 'DEMO-01D',

        defaultDeliveryMode: 'bike',
    },
    client: {
        order: 'CLIENTSO',
        invoice: 'CLIENTSI',
        package: 'CLIENTSP',
        delivery: 'CLIENTSD',

        defaultDeliveryMode: 'bike',
    },
    solutrans: {
        order: 'SOLUTRANSO',
        invoice: 'SOLUTRANSI',
        package: 'SOLUTRANSP',
        delivery: 'SOLUTRANSD',

        defaultDeliveryMode: 'bike',
    },
    annecy: {
        order: 'BC',
        invoice: 'BF',
        package: 'RLP',
        delivery: 'DEVD',
        defaultDeliveryMode: 'van',
    },
    bft: {
        order: 'BC',
        invoice: 'BF',
        package: 'RLP',
        delivery: 'DEVD',
        defaultDeliveryMode: 'van',
    },
    lium: {
        order: 'LIUM',
        invoice: 'LIUMI',
        package: 'LIUMP',
        delivery: 'LIUMD',

        defaultDeliveryMode: 'bike',
    },
    beaujolyon: {
        order: 'BJL',
        invoice: 'BJLI',
        package: 'BJLP',
        delivery: 'BJLD',

        defaultDeliveryMode: 'bike',
    },
    rushlogistic: {
        order: 'RLP',
        invoice: 'RLI',
        package: 'RLP',
        delivery: 'RLD',

        defaultDeliveryMode: 'van',
    },
}

export default prefixes
