import { defineStore } from 'pinia'
import { useUserStore } from './userStore'

const useUniverseStore = defineStore('universe', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        universesOptions: [],
        selectedUniverses: [],
    }),

    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/universe/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/universe/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const universes = []
                            if (count > 0) {
                                res.data.Records.forEach((universe) => {
                                    universe.id = universe.Id
                                    universes.push(universe)
                                })
                            }
                            this.all = universes

                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/universe', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/universe/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/universe/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/universe/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})

export { useUniverseStore }
