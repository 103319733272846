import { defineStore } from 'pinia'

const useAreaStore = defineStore('area', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    actions: {
        searchAreas(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/sector/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/sector/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const areas = []
                            if (count > 0) {
                                res.data.Records.forEach((area) => {
                                    const parsedGeoJSON = JSON.parse(
                                        area.GeoJSON
                                    )
                                    const formattedArea = {
                                        id: +area.Id,
                                        type: area.Type,
                                        name: area.Name,
                                        geo_json: parsedGeoJSON,
                                        active: +area.Active,
                                        creation_date: area.CreationDate,
                                    }
                                    areas.push(formattedArea)
                                })
                            }

                            this.all = areas
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/sector', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/sector/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/sector/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const area = res.data
                        const current = {
                            id: +area.Id,
                            name: area.Name,
                            center: area.Centroid,
                            geo_json: JSON.parse(area.GeoJSON),
                            type: area.Type,
                            active: +area.Active === 1,
                            creation_date: area.CreationDate,
                            modfication_date: area.ModificationDate,
                            creation_user_id: +area.CreationUserId,
                            modification_user_id: +area.ModificationUserId,
                        }
                        this.current = current

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deactivate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/sector/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})

export { useAreaStore }
