<template>
    <main
        style="width: 96vw"
        class="px-4 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'logs'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="logsList"
                :items="logs"
                :columns="columns"
                :tableWidth="'table-fixed w-auto'"
                :storeToSort="'log'"
                :page="page"
                :count="logStore.count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="logStore.totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="false"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @dateFilterUpdate="setDateFilter($event)"
                @create="toCreateAuthorisation()"
                @clear="clearFilter($event)"
                @update="toUpdateAuthorisation($event)"
                @deactivate="deactivateOne($event)"
                @deactivateAll="deactivateAll($event)"
                @activate="activateOne($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapState, mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { useLogStore } from '@/stores/logStore'
import filterList from '@/mixins/filterList'
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LogsViewer',
    components: {
        FiltrableTable,
        FetchDataError,
    },
    mixins: [filterList],

    data() {
        return {
            refresh: 0,
            searchQuery: '',
            dateFilter: null,
            search: [],
            sortDirection: 'DESC',
            sortField: 'ModificationDate',
            perPage: 100,
            page: 1,
            show: false,
            logsInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('EntityName'), value: 'EntityName' },
                // { name: this.$t('CreationDate'), value: 'CreationDate' },
            ],
            itemActions: [
                // {
                //     name: 'edit',
                //     action: 'update',
                //     icon: 'pen',
                // },
                // {
                //     name: 'deactivate',
                //     action: 'deactivate',
                //     icon: 'times',
                // },
            ],
            multiActions: [
                // {
                //     name: 'deactivate_selection',
                //     action: 'deactivateAll',
                //     icon: 'times',
                // },
            ],
        }
    },

    watch: {
        logs() {
            this.refresh += 1
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            const dateFiltered = this.filter.find(
                (filt) => filt.dbField === 'ModificationDate'
            )
            if (dateFiltered !== undefined) {
                this.dateFilter = dateFiltered.value
            }
        }

        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.logStore.search(realSearch)
        // this.logsInterval = setInterval(() => {
        //     this.logStore.search(realSearch)
        // }, 5000)
    },

    unmounted() {
        clearInterval(this.logsInterval)
    },
    computed: {
        ...mapStores(useLogStore),

        ...mapState(useUserStore, {
            user: 'current',
        }),
        logs() {
            return this.logStore.all
        },
        columns() {
            return [
                {
                    name: 'ModificationDate',
                    sortable: false,
                    dbField: 'ModificationDate',
                    withButton: false,
                    datePicker: true,
                    pickedDate: this.dateFilter,
                },
                {
                    name: 'Id',
                    filtrable: false,
                    isLink: false,
                    dbField: 'Id',
                    sortable: false,
                },
                {
                    name: 'Description',
                    filtrable: false,
                    isLink: false,
                    dbField: 'Description',
                    sortable: false,
                },
                {
                    name: 'HttpCode',
                    filtrable: true,
                    type: 'search',
                    isLink: false,
                    dbField: 'HttpCode',
                    sortable: false,
                },
                {
                    name: 'EntityName',
                    type: 'search',
                    filtrable: true,
                    isLink: false,
                    dbField: 'EntityName',
                    sortable: false,
                },
                {
                    name: 'Function',
                    isLink: false,
                    type: 'search',

                    filtrable: true,
                    dbField: 'Function',
                    sortable: false,
                },
                {
                    name: 'Response',
                    type: 'search',

                    filtrable: true,
                    isLink: false,
                    dbField: 'Response',
                    sortable: false,
                },
                {
                    name: 'EntityObject',
                    dbField: 'EntityObject',

                    filtrable: true,
                    type: 'search',
                    // notCentered: true,
                    json: true,
                },
            ]
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.logsInterval)
            this.updateFilter(event)
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.logsInterval)
            this.$cookies.remove('filtered')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        setDateFilter(dFilter) {
            this.searchingCursor = true
            const filterExistsIndex = this.filter.findIndex(
                (df) => df.dbField === dFilter.dbField
            )
            if (filterExistsIndex !== -1) {
                this.filter[filterExistsIndex] = dFilter
            } else {
                this.filter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }
            this.dateFilter = dFilter.value
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.logsInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.logStore.search(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.logsInterval = setInterval(() => {
                this.logStore.search(search)
            }, 5000)
        },

        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.logsInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
