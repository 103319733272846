<template>
    <svg
        @click.stop="$emit('traceClicked')"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="10" :fill="bgColor" />
        <path
            d="M16.7623 10.5294C16.7623 11.4051 15.616 12.9643 15.1133 13.6076C14.9928 13.7609 14.7694 13.7609 14.6489 13.6076C14.1463 12.9643 13 11.4051 13 10.5294C13 9.46721 13.8426 8.60547 14.8811 8.60547C15.9197 8.60547 16.7623 9.46721 16.7623 10.5294Z"
            fill="white"
        />
        <path
            d="M7.32497 6.00049L6 6.00049L7.5 6.00049L8.90973 6.00049C10.0642 6.00049 11 6.93634 11 8.09076V8.09076C11 9.24519 10.0642 10.181 8.90973 10.181H7H4.78422C3.79882 10.181 3 10.9799 3 11.9653V11.9653C3 12.9506 3.79882 13.7495 4.78422 13.7495H13.5"
            stroke="url(#paint0_linear_1501_21983)"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-dasharray="1.3 1.5"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.00033 6.07345C4.00035 6.07345 4.00037 6.07346 4.00038 6.07346L7.98854 8.09563C8.36819 8.28813 8.78031 7.89956 8.61045 7.50925L8.06444 6.25458C8.01413 6.13898 8.01413 6.00767 8.06444 5.89207L8.61045 4.6374C8.78031 4.24709 8.36819 3.85852 7.98854 4.05102L4.00038 6.07318C4.00037 6.07319 4.00035 6.0732 4.00033 6.0732L4.00005 6.0732C4 6.0732 3.99998 6.07326 4.00003 6.07328C4.00006 6.0733 4.00006 6.07335 4.00003 6.07337C3.99998 6.07339 4 6.07345 4.00005 6.07345L4.00033 6.07345Z"
            :fill="icColor"
        />
        <circle cx="14.8807" cy="10.5633" r="0.959759" :fill="bgColor" />
        <defs>
            <linearGradient
                id="paint0_linear_1501_21983"
                x1="15.4765"
                y1="13.7495"
                x2="11.4839"
                y2="4.34382"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="icColor" />
                <stop offset="0.7779" :stop-color="icColor" />
                <stop offset="1" :stop-color="icColor" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'TraceIcon',

    props: {
        bgColor: {
            type: String,
            default: '#68686A',
        },
        icColor: {
            type: String,
            default: '#FFFFFF',
        },
       
    },
}
</script>
