<template>
    <svg

        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click.stop="$emit('progressClicked')"
    >
        <circle cx="10" cy="10" r="10" :fill="bgColor" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.79883 16.116C4.46211 15.0777 5.62482 14.3892 6.94833 14.3892C8.10517 14.3892 9.04298 13.4514 9.04298 12.2945L9.04296 1.59859C9.35445 1.56442 9.67094 1.54688 9.99153 1.54688C10.2242 1.54688 10.4548 1.55612 10.6828 1.57425L10.6828 12.2945C10.6828 14.357 9.01084 16.029 6.94833 16.029C6.1229 16.029 5.40899 16.5065 5.06768 17.2003C4.61016 16.8802 4.18522 16.5168 3.79883 16.116Z"
            fill="url(#paint0_linear_1488_76523)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.79028 5.84092C9.79028 5.84097 9.79027 5.84103 9.79024 5.84108L6.45689 12.4152C6.13953 13.0411 6.78013 13.7205 7.4236 13.4405L9.49188 12.5404C9.68246 12.4575 9.89893 12.4575 10.0895 12.5404L12.1578 13.4405C12.8013 13.7205 13.4419 13.0411 13.1245 12.4152L9.79115 5.84108C9.79112 5.84103 9.79111 5.84097 9.79111 5.84092V5.83999C9.79111 5.83984 9.7909 5.83979 9.79083 5.83993C9.79077 5.84003 9.79062 5.84003 9.79056 5.83993C9.79049 5.83979 9.79028 5.83984 9.79028 5.83999V5.84092Z"
            :fill="icColor"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1488_76523"
                x1="9.5"
                y1="1.5"
                x2="5.5"
                y2="16.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop
                    offset="0.0190366"
                    :stop-color="icColor"
                    stop-opacity="0"
                />
                <stop offset="0.378648" :stop-color="icColor" />
                <stop offset="0.950538" :stop-color="icColor" />
            </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'ProgressIcon',

    props: {
        bgColor: {
            type: String,
            default:  '#68686A'
        },
        icColor: {
            type: String,
            default:  '#FFFFFF'
        },
       

    }
}
</script>
