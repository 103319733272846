import { ref } from 'vue'

class UploadableFile {
    constructor(file) {
        this.file = file
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
        this.url = URL.createObjectURL(file)
        this.status = null
    }
}
export default function () {
    const files = ref([])

    function fileExists(otherId) {
        return files.value.some(({ id }) => id === otherId)
    }
    function addFiles(newFiles) {
        const newUploadableFiles = [...newFiles]
            .map((file) => new UploadableFile(file))
            .filter((file) => !fileExists(file.id))
        files.value = files.value.concat(newUploadableFiles)
    }

    function removeFile(file) {
        const index = files.value.indexOf(file)
        URL.revokeObjectURL(file.url)
        
        if (index > -1) files.value.splice(index, 1)
    }
    function removeAllFiles() {
        files.value = []
        // files.value.forEach((file) => {
        //     console.log(file)
        //     removeFile(file)
        // })
    }

    return { files, addFiles, removeFile, removeAllFiles }
}
