import { defineStore } from 'pinia'

const useContactClientHistoryStore = defineStore('contactClientHistory', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    // actions
    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        if (q.dbField === 'ContactUser') {
                            query.push(
                                `{"ContactUser.FirstName":[{"operator":"LIKE","value":"${q.term}"}],"ContactUser.LastName":[{"operator":"LIKE","value":"${q.term}"}]}`
                            )
                        } else if (q.dbField === 'SalesPersonUser') {
                            query.push(
                                `{"SalesPersonUser.FirstName":[{"operator":"LIKE","value":"${q.term}"}],"SalesPersonUser.LastName":[{"operator":"LIKE","value":"${q.term}"}]}`
                            )
                        } else {
                            query.push(
                                `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                            )
                        }
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'IdClient') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/ContactClientHistory/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/ContactClientHistory/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const communications = []
                            if (count > 0) {
                                res.data.Records.forEach((history) => {
                                    const formattedHistory = {
                                        id: +history.Id,
                                        name: history.Title,
                                        client: history.Client
                                            ? history.Client.Name
                                            : this.i18n.global.t(
                                                  'client_not_found'
                                              ),
                                        client_id: history.Client.Id,
                                        client_type: !history.Client
                                            ? this.i18n.global.t(
                                                  'client_not_found'
                                              )
                                            : history.Client.Type === 'customer'
                                            ? 'B2C'
                                            : history.Client.Type === 'business'
                                            ? 'B2B'
                                            : history.Client.Type,
                                        contact: `${history.UserContact.FirstName} ${history.UserContact.LastName}`,
                                        subInfoForListDisplay:
                                            history.UserContact.Email,
                                        sales_person: `${history.UserSalesPerson.FirstName} ${history.UserSalesPerson.LastName}`,
                                        meeting_date: history.MeetingDate,
                                        description: history.Description,
                                        pinned: history.Pinned,
                                        archived_status: +history.Active,
                                        active: +history.Active,

                                        type: history.Type,
                                        files: history.Files,
                                        // multiParamItemAction: true
                                    }
                                    communications.push(formattedHistory)
                                })
                            }

                            this.all = communications
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },

        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/ContactClientHistory`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        pinNote(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/ContactClientHistory/${payload.id}/pinNote`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        archiveNote(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/ContactClientHistory/${payload.id}/archiveNote`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/ContactClientHistory/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/ContactClientHistory/${payload.Id}`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },

        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/ContactClientHistory/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteMany(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/ContactClientHistory/deleteRecords`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/ContactClientHistoryFile/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },

        uploadFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/ContactClientHistoryFile`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchNoteFiles(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/ContactClientHistoryFile/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current.Files.push(res.data)
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})

export { useContactClientHistoryStore }
