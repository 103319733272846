<!-- NEXT TO DO  -->
<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" />
            <div v-else>
                <filtrable-table
                    id="clientsList"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="clients"
                    :columns="columns"
                    :storeToSort="'clients'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :canAddNew="canAddNew"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    :itemActions="itemActions"
                    :multiActions="[]"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @update="goToUpdateOne($event)"
                    @delete="deleteOne($event)"
                    @deleteAll="deleteAll($event)"
                    @viewContacts="$emit('contacts', $event)"
                    @viewDeliveryAddresses="
                        $emit('clientDeliveryAdresses', $event)
                    "
                    @addContact="$emit('addContact', $event)"
                    @addCredits="showAddCreditModal($event)"
                >
                </filtrable-table>
            </div>
            <modal
                v-if="showCreditModal"
                :headerText="'add_credits'"
                :buttonText="'save'"
                id="creditmodal"
                :mxWidth="'w-1/4'"
                :buttonAlign="'justify-center'"
                @closeModal="showAddCreditModal()"
                @sendFromModal="addCredits()"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center font-semibold mt-4">
                            {{ $t('amount') }}
                        </div>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            v-model="creditForm.Amount"
                            class="border rounded px-3 py-2 w-full"
                        />
                        <div class="flex justify-center font-semibold mt-4">
                            {{ $t('valid_until') }}
                        </div>
                        <input
                            :id="`creditDatePicker`"
                            type="date"
                            :min="new Date().toISOString().substr(0, 10)"
                            v-model="creditForm.EndDate"
                            class="border rounded px-3 py-2 w-full"
                        />

                        <!-- <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div> -->
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Clients',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
    },
    data() {
        return {
            showCreditModal: false,
            refresh: 0,
            creditForm: {},
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            clientsInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('company_name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        clients() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        clearInterval(this.clientsInterval)
        const filteredList = this.$cookies.get('filtered')

        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        window.scrollTo(0, 0)

        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })

            this.setRealSearch(this.search)
        }
        /// TODO check si le filtre univers fonctionne correctement (ex , ne se charge pas au premier call du mounted)
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                if (this.userStore.isB2C === false) {
                    if (!this.filter.find((f) => f.dbField === 'IdUniverse')) {
                        const universeFilter = {
                            dbField: 'IdUniverse',
                            value:
                                this.selectedUniverses.length > 0
                                    ? this.selectedUniverses
                                    : this.universesOptions,
                        }
                        this.filter.push(universeFilter)
                    }
                }
                const realSearch = {
                    query: this.search,
                    filter: this.filter,
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                }
                this.clientStore.search(realSearch)
                this.clientsInterval = setInterval(() => {
                    this.clientStore.search(realSearch)
                }, 20000)
            }
        }, 10)
    },

    unmounted() {
        this.creditForm = {}
        clearInterval(this.clientsInterval)
        this.eventBus.all.clear()
    },
    computed: {
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        clientsActionsAccess() {
            return actionsAccess.clients
        },
        canAddNew() {
            return checkAccess(this.userStore, this.clientsActionsAccess.create)
        },
        ...mapState(useClientStore, {
            clients: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useClientStore, useUserStore),
        columns() {
            return [
                {
                    name: 'company_name',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.clientsActionsAccess.update,
                    ),
                    routeName: 'update_client',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'registration_number',
                    filtrable: false,
                    sortable: false,
                },
                // {
                //     name: 'universe',
                //     dbField: 'IdUniverse',
                //     filtrable: true,
                //     sortable: false,
                //     canSearch: true,
                //     type: 'select',
                //     options: this.universeOptions,
                //     // canUpdate: checkAccess(
                //     //     this.userStore,
                //     //     actionsAccess.universes.update
                //     // ),
                // },
                {
                    name: 'client_type',
                    filtrable: true,
                    type: 'select',
                    dbField: 'ClientType',
                    sortable: false,
                    options: [
                        {
                            name: 'voucher_book',
                            value: 'voucher',
                            dbField: 'ClientType',
                        },
                        {
                            name: 'occasional',
                            value: 'occasional',
                            dbField: 'ClientType',
                        },
                        {
                            name: 'month_invoice',
                            value: 'month',
                            dbField: 'ClientType',
                        },
                    ],
                },
                {
                    name: 'payment_type',
                    filtrable: true,
                    type: 'select',
                    dbField: 'PaymentType',

                    sortable: false,
                    options: [
                        {
                            name: 'cash',
                            value: 'cash',
                            dbField: 'PaymentType',
                        },
                        {
                            name: 'check',
                            value: 'check',

                            dbField: 'PaymentType',
                        },
                        {
                            name: 'credit_card',
                            value: 'creditcard',
                            dbField: 'PaymentType',
                        },
                        {
                            name: 'notselected',
                            value: 'notselected',
                            dbField: 'PaymentType',
                        },
                        {
                            name: 'transfer',
                            value: 'transfer',

                            dbField: 'PaymentType',
                        },
                        {
                            name: 'web',
                            value: 'web',
                            dbField: 'PaymentType',
                        },
                    ],
                },
                {
                    name: 'credits',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []

            if (checkAccess(this.userStore, this.clientsActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.addresses.list,
                )
            ) {
                actions.push({
                    name: 'delivery_addresses',
                    action: 'viewDeliveryAddresses',
                    icon: 'address-book',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.contacts.list,
                )
            ) {
                actions.push({
                    name: 'contacts',
                    action: 'viewContacts',
                    icon: 'users',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.credits.create,
                )
            ) {
                actions.push({
                    name: 'add_credits',
                    action: 'addCredits',
                    icon: 'piggy-bank',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.contacts.create,
                )
            ) {
                actions.push({
                    name: 'add_contact',
                    action: 'addContact',
                    icon: 'user-plus',
                })
            }
            // if (
            //     checkAccess(
            //         this.userStore,
            //         this.clientsActionsAccess.delete
            //     )
            // ) {
            //     actions.push( {
            //     name: 'delete',
            //     action: 'delete',
            //     icon: 'trash-can',
            // },)
            // })}

            return actions
        },
        // multiActions() {[
        // if (
        //     checkAccess(
        //         this.userStore,
        //         this.clientsActionsAccess.delete
        //     )
        // ) {
        //         name: 'delete_selection',
        //         action: 'deleteAll',
        //         icon: 'trash-can',
        //     },
        // },
    },
    methods: {
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        searchCustomUniverses(clear = false) {
            clearInterval(this.clientsInterval)
            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        showAddCreditModal(id = null) {
            clearInterval(this.clientsInterval)
            this.showCreditModal = !this.showCreditModal
            if (id === null) {
                this.creditForm = null
                this.setResults()
            } else {
                this.creditForm = {
                    IdClient: id,
                    Amount: 0,
                    EndDate: new Date().toISOString().substr(0, 10),
                }
            }
        },
        addCredits() {
            this.clientStore.addCredits(this.creditForm).then((response) => {
                if (response.status === 201) {
                    this.showAddCreditModal()
                    this.$toast.success(this.$t('credits_ok'))
                } else {
                    this.$toast.error(this.$t('credits_ko'))
                }
            })
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.clientsInterval)
            this.searchCustomUniverses()
        },
        upFilter(event) {
            clearInterval(this.clientsInterval)
            this.updateFilter(event)
        },
        goToUpdateOne(id) {
            this.$emit('goToUpdateClient', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.clientStore.deleteOne(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('client_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('client_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.clientStore.deleteOne(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            this.setRealSearch(this.search)

            this.clientStore.search(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.clientsInterval = setInterval(() => {
                this.clientStore.search(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
