export default function checkAccess(userStore, authorisations) {
    let canAccess = false
    // remove fiirst if when auth on all envs
    if (authorisations.length === 0) {
        canAccess = true
    }
    authorisations.forEach((auth) => {
        if (userStore.userAuthorisations.includes(auth)) {
            canAccess = true
        }
    })
    // if (authorisations.length === 0) {
    //     canAccess = true
    // }
    return canAccess
}
