<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />
        <div
            id="create-area"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div>
                <div class="p-5">
                    <form
                        @keydown.enter.prevent
                        @submit.prevent="
                            $route.params.id ? updateArea() : createArea()
                        "
                    >
                        <div>
                            <div class="rounded-md pt-4 px-4">
                                <div class="mb-2">
                                    <span
                                        v-if="$route.params.id"
                                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                                    >
                                        <span
                                            class="items-center custom-link ml-2"
                                        >
                                            {{ formData.Name }}
                                        </span>
                                    </span>
                                </div>
                                <div class="grid grid-cols-6 gap-4 border">
                                    <div class="col-span-3 p-4">
                                        <div class="px-3">
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('name') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="formData.Name"
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                    @blur="
                                                        v$.formData.Name.$touch
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData.Name
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="flex items-center mt-5 justify-center"
                                            >
                                                <input
                                                    id="set-active-checkbox"
                                                    type="checkbox"
                                                    v-model="formData.Active"
                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                />
                                                <label
                                                    for="set-active-checkbox"
                                                    class="ml-2 font-medium"
                                                    >{{
                                                        $t('activate_area')
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-3 p-4 mt-1">
                                        <div class="px-3">
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('area_type') }}
                                                </label>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="formData.Type"
                                                    :options="typeOptions"
                                                    :searchable="false"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                class="flex items-center mt-5 justify-center"
                                            >
                                                <input
                                                    id="ref-add-update-checkbox"
                                                    type="checkbox"
                                                    v-model="isBike"
                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                />
                                                <label
                                                    for="ref-add-update-checkbox"
                                                    class="ml-2 font-medium"
                                                    >{{
                                                        $t('area_by_bike')
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span
                                class="flex mt-1 italic text-xs items-center justify-center"
                            >
                                {{ $t('required_fields') }}
                            </span>

                            <div class="p-2 mt-4">
                                <div class="flex justify-center">
                                    <button
                                        type="button"
                                        @click="back()"
                                        class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                    >
                                        {{ $t('cancel') }}
                                    </button>
                                    <button
                                        type="submit"
                                        :disabled="creating"
                                        class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span v-if="creating">
                                            <fa-icon
                                                icon="spinner"
                                                spin-pulse
                                            ></fa-icon
                                        ></span>
                                        <span v-else>
                                            {{ $t('save') }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="polygoneError" class="p-2 mt-4">
                                <div class="flex justify-center">
                                    <div class="error-msg">
                                        {{ $t('area_error') }}
                                    </div>
                                </div>
                            </div>
                            <div
                                id="map"
                                style="width: 100%; height: 500px"
                            ></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import Multiselect from '@vueform/multiselect'
import { required } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'
import BackTo from '@/components/elements/BackTo.vue'
import keys from '@/resources/keys'
import { useUserStore } from '@/stores/userStore'
import { useAreaStore } from '@/stores/areaStore'
import googleScriptLoader from '@/mixins/googleScript'
import gmapStyles from '@/mixins/gmapStyles'

export default {
    name: 'CreateOrUpdate',
    components: { Multiselect, BackTo },
    mixins: [googleScriptLoader, gmapStyles],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]
        return { key, v$: useVuelidate() }
    },
    mounted() {
        const payload = {
            query: [],
            filter: [],
            perPage: 100000000000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.areaStore.searchAreas(payload)
        window.scrollTo(0, 0)

        this.currentAreaInterval = setInterval(() => {
            if (this.currentArea) {
                clearInterval(this.currentAreaInterval)
                const centroidArray = this.currentArea.center.split(',')
                const centroid = centroidArray.reverse().toString()

                this.currentArea.geo_json.features[0].geometry.coordinates[0].forEach(
                    (path) => {
                        path.reverse()
                    }
                )
                this.formData = {
                    Name: this.currentArea.name,
                    Id: this.currentArea.id,
                    GeoJSON: this.currentArea.geo_json,
                    Type: this.currentArea.type,
                    CreationUserId: this.currentArea.creation_user_id,
                    CreationDate: this.currentArea.creation_date,
                    VehicleType: this.currentArea.vehicle_type,
                    Centroid: centroid,
                    Active: this.currentArea.active,
                    lat: +centroid.slice(0, centroid.indexOf(',')),
                    lng: +centroid.slice(centroid.indexOf(',') + 1),
                }
                if (this.currentArea.vehicle_type) this.isBike = true
            }
        }, 1000)
        this.loadGoogleScript()
        const googleInterval = setInterval(() => {
            this.map = null
            let polygon = null
            if (google) {
                if (!this.$route.params.id) {
                    clearInterval(googleInterval)
                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: {
                                lat: this.user.env.latitude,
                                lng: this.user.env.longitude,
                            },
                            zoom: 10,
                        }
                    )
                } else if (this.currentArea) {
                    clearInterval(googleInterval)

                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: {
                                lat: this.formData.lat,
                                lng: this.formData.lng,
                            },
                            zoom: 10,
                        }
                    )
                }
                this.setGoogleMapStyle(false)

                // set other areas polygons
                if (this.areas) {
                    this.areas.forEach((area) => {
                        if (
                            this.currentArea === null ||
                            this.currentArea.id !== area.id
                        ) {
                            const polygonPaths = []
                            let polygonArea = null

                            area.geo_json.features[0].geometry.coordinates[0].forEach(
                                (path) => {
                                    path.reverse()

                                    const coordinates = {
                                        lat: path[0],
                                        lng: path[1],
                                    }
                                    polygonPaths.push(coordinates)
                                }
                            )
                            polygonArea = new google.maps.Polygon({
                                strokeColor:
                                    area.active === 1 ? '#1AC0EC' : '#A9AEA6',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor:
                                    area.active === 1 ? '#1AC0EC' : '#A9AEA6',
                                fillOpacity: 0.35,
                                paths: polygonPaths,
                                clickable: true,
                            })
                            polygonArea.setMap(this.map)
                            this.infoWindow = new google.maps.InfoWindow()
                            polygonArea.addListener('click', (event) => {
                                let content = `<b>${this.$t(
                                    'activate_area'
                                )} - ${area.name}</b><br>`
                                if (+area.active === 0) {
                                    content = `<b>${this.$t(
                                        'deactivated_area'
                                    )} - ${area.name}</b><br>`
                                }
                                this.infoWindow.setContent(content)
                                this.infoWindow.setPosition(event.latLng)
                                this.infoWindow.open(this.map)
                            })
                        }
                    })
                }

                if (!this.$route.params.id) {
                    polygon = new google.maps.Polygon({
                        strokeColor: '#FE6100',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FE6100',
                        fillOpacity: 0.35,
                        paths: [
                            {
                                lat: 45.71098477961418,
                                lng: 4.731940199397795,
                            },
                            {
                                lat: 45.701913239454306,
                                lng: 4.921341322444692,
                            },
                            {
                                lat: 45.796376175960596,
                                lng: 4.942009291194656,
                            },
                            {
                                lat: 45.83263735236813,
                                lng: 4.764899183772795,
                            },
                        ],

                        draggable: true,
                        editable: true,
                        clickable: true,
                    })
                    polygon.setMap(this.map)
                    polygon.addListener('mouseup', (event) => {
                        const vertices = polygon.getPath()

                        const paths = []
                        for (let i = 0; i < vertices.getLength(); i++) {
                            const xy = vertices.getAt(i)
                            paths.push([xy.lat(), xy.lng()])
                        }
                        this.formData.GeoJSON.features[0].geometry = {
                            coordinates: [paths],
                            type: 'Polygon',
                        }
                        this.formData.Centroid = `${event.latLng.lng()}, ${event.latLng.lat()}`
                        polygon.setPaths(paths)
                    })
                } else if (this.currentArea) {
                    clearInterval(googleInterval)
                    const polygonPaths = []
                    this.formData.GeoJSON.features[0].geometry.coordinates[0].forEach(
                        (path) => {
                            const coordinates = { lat: path[0], lng: path[1] }
                            polygonPaths.push(coordinates)
                        }
                    )
                    polygon = new google.maps.Polygon({
                        strokeColor: '#FE6100',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FE6100',
                        fillOpacity: 0.35,
                        paths: polygonPaths,
                        draggable: true,
                        editable: true,
                        clickable: true,
                    })
                    polygon.setMap(this.map)
                    polygon.addListener('mouseup', (event) => {
                        const vertices = polygon.getPath()

                        const paths = []
                        for (let i = 0; i < vertices.getLength(); i++) {
                            const xy = vertices.getAt(i)
                            paths.push([xy.lat(), xy.lng()])
                        }
                        this.formData.GeoJSON.features[0].geometry = {
                            coordinates: [paths],
                            type: 'Polygon',
                        }
                        this.formData.Centroid = `${event.latLng.lng()}, ${event.latLng.lat()}`
                        polygon.setPaths(paths)
                    })
                }
            }
        }, 1000)
    },
    data() {
        return {
            center: { lat: 24.886, lng: -70.268 },
            creating: false,
            polygoneError: false,
            currentAreaInterval: null,
            isBike: false,
            infoWindow: null,
            formData: {
                Name: '',
                GeoJSON: {
                    type: 'FeatureCollection',
                    features: [
                        { type: 'Feature', properties: {}, geometry: null },
                    ],
                },
                Type: 'grouping',
                CreationUserId: '',
                CreationDate: '',
                ModificationUserId: '',
                ModificationDate: '',
                VehicleType: '',
                Centroid: '',
                Active: true,
                lat: 45.5127,
                lng: 4.4905,
            },
            typeOptions: [
                { name: this.$t('grouping'), value: 'grouping' },
                { name: this.$t('vehicle_type'), value: 'vehicleType' },
            ],
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
            },
        }
    },
    computed: {
        ...mapState(useAreaStore, {
            currentArea: 'current',
            areas: 'all',
        }),
        ...mapStores(useAreaStore),
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },

    methods: {
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.areaStore.$patch({ current: null })
            this.$emit('back', 'areas')
        },
        addInfoWindow(event, area, map) {
            const content = `${area.name} <b>Bermuda Triangle polygon</b><br>`
            // Replace the info window's content and position.
            this.infoWindow.setContent(content)
            this.infoWindow.setPosition(event.latLng)
            this.infoWindow.open(map)
        },
        async createArea() {
            this.creating = true

            this.formData.CreationUserId = this.user.id
            this.formData.ModificationUserId = this.user.id
            this.formData.VehicleType = this.isBike ? 'bike' : ''
            this.formData.Active = this.formData.Active === true ? 1 : 0

            let polygonIsOk = true
            if (this.formData.GeoJSON.features[0].geometry === null) {
                polygonIsOk = false
            }

            this.formData.GeoJSON.features[0].geometry.coordinates[0].forEach(
                (coordinates) => {
                    coordinates.reverse()
                }
            )
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true && polygonIsOk === true) {
                this.formData.GeoJSON = JSON.stringify(this.formData.GeoJSON)
                try {
                    const response = await this.areaStore.create(this.formData)
                    if (response) {
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)

                        this.$emit('created', 'areas')
                        this.$toast.success(this.$t('area_create_ok'))
                    }
                } catch (err) {
                    this.creating = false

                    this.$toast.error(`${this.$t('area_create_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false
                this.polygoneError = true
                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateArea() {
            this.creating = true

            const isFormCorrect = await this.v$.$validate()
            this.formData.ModificationUserId = this.user.id
            this.formData.VehicleType = this.isBike
                ? 'bike'
                : this.formData.VehicleType

            this.formData.GeoJSON.features[0].geometry.coordinates[0].forEach(
                (coordinates) => {
                    coordinates.reverse()
                }
            )
            this.formData.GeoJSON = JSON.stringify(this.formData.GeoJSON)
            this.formData.Active = this.formData.Active === true ? 1 : 0

            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response = this.areaStore.update(params)
                    if (response) {
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('area_update_ok'))

                        this.$emit('updated', 'areas')
                    }
                } catch (err) {
                    this.creating = false

                    this.$toast.error(`${this.$t('area_update_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.areaStore.$patch({ current: null })
    },
}
</script>
