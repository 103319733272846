<template>
    <main>
        <div
            style="width: 96vw"
            class="px-4 pt-2 rounded-md h-full flex items-center justify-center"
        >
            <div v-if="loading === true">
                <div
                    class="flex mt-16 justify-center bg-white darky mb-6 text-xl"
                >
                    <fa-icon icon="spinner" spin-pulse class="fa-2xl"></fa-icon>
                </div>
            </div>
            <div v-else>
                <div v-if="success === true">
                    <div class="mt-16 bg-white darky mb-6 text-lg">
                        <div>
                            {{ $t('payment_success') }}
                        </div>
                        <br />
                        <div>{{ $t('payment_success_2') }}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-14 bg-white darky mb-6 text-lg">
                        <div>
                            {{ $t('payment_failed') }}
                        </div>
                        <br />
                        <div>{{ $t('payment_failed_2') }}</div>
                    </div>
                </div>
                <button
                    type="button"
                    class="themed-button mt-2 text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                    @click="$router.push({ name: 'orders' })"
                >
                    <span>
                        <fa-icon icon="arrow-left" class="mr-2"></fa-icon>
                        {{ $t('back_to_deliveries') }}
                    </span>
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { usePaymentStore } from '@/stores/paymentStore'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'PaymentResult',
    mounted() {
        this.loading = true
        this.checkoutSessionResult({
            session: this.$route.query.session,
            ModificationUserId: this.user.id,
        })
            .then((res) => {
                this.success = true
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
                this.success = false
            })
        // recupérer les infos de la session via stripe
    },
    data() {
        return {
            success: null,
            loading: false,
        }
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
    },
    methods: {
        ...mapActions(usePaymentStore, ['checkoutSessionResult']),
    },
}
</script>
