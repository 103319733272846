<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-cLient"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div>
                <div class="p-5">
                    <form
                        @keydown.enter.prevent
                        @submit.prevent="
                            $route.params.id ? updateContact() : createContact()
                        "
                    >
                        <div>
                            <!-- <div class="col-span-2 rounded-md border-2 p-4">
                                <div class="px-3"> -->
                            <div class="mb-2">
                                <span
                                    v-if="currentClient"
                                    class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                                >
                                    <span class="ml-2">
                                        {{ currentClient.company_name }}
                                    </span>
                                </span>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                    >{{ $t('firstname') + ' *' }}</label
                                >
                                <input
                                    type="text"
                                    v-model="formData.FirstName"
                                    autocomplete="name"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.FirstName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                    >{{ $t('lastname') + ' *' }}</label
                                >
                                <input
                                    type="text"
                                    v-model="formData.LastName"
                                    autocomplete="name"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.LastName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                    >{{ $t('email_address') + ' * '
                                    }}<span class="italic text-xs">{{
                                        '(' + $t('for_login') + ')'
                                    }}</span></label
                                >
                                <input
                                    type="email"
                                    autocomplete="email"
                                    v-model="formData.Email"
                                    @blur="v$.formData.Email.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Email.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                    >{{ $t('phone') }}</label
                                >
                                <input
                                    type="tel"
                                    v-model="formData.Phone"
                                    @blur="v$.formData.Phone.$touch"
                                    autocomplete="tel"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Phone.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                    >{{ $t('mobile') }}</label
                                >
                                <input
                                    type="tel"
                                    v-model="formData.MobilePhone"
                                    @blur="v$.formData.MobilePhone.$touch"
                                    autocomplete="tel"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.MobilePhone.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <!-- <Multiselect
                                        v-if="$route.params.id"
                                        class="multiselect-no-pr darky h10 sort-select"
                                        v-model="contact"
                                        :options="contacts"
                                        label="name"
                                        :placeholder="$t('select_one')"
                                        track-by="name"
                                        :searchable="true"
                                        :object="true"
                                        @clear="$emit('clear', contact)"
                                        @select="$emit('selected', contact)"
                                    ></Multiselect> -->
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <!-- </div>
                        </div> -->
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="creating"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import Multiselect from '@vueform/multiselect'
import { required, email, helpers } from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'
import BackTo from '@/components/elements/BackTo.vue'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'

const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
)
export default {
    name: 'CreateOrUpdateContact',
    components: { Multiselect, BackTo },
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        window.scrollTo(0, 0)
        if (this.$route.name === 'update_contact') {
            this.currentContactInterval = setInterval(() => {
                if (this.currentContact) {
                    clearInterval(this.currentContactInterval)
                    this.formData = {
                        Id: this.currentContact.id,
                        FirstName: this.currentContact.firstname,
                        LastName: this.currentContact.lastname,
                        // Password: this.currentContact.password,
                        Email: this.currentContact.email_address,
                        Phone: this.currentContact.phone,
                        MobilePhone: this.currentContact.mobile,
                        CreationUserId: this.currentContact.creation_user_id,
                        CreationDate: this.currentContact.creation_date,
                        IdClient: this.$route.params.client_id,
                        Type: this.currentContact.type,
                    }
                }
            }, 1000)
        }
    },
    data() {
        return {
            currentContactInterval: null,
            contact: null,
            creating: false,

            formData: {
                FirstName: '',
                LastName: '',
                Email: '',
                Phone: '',
                MobilePhone: '',
                CreationDate: '',
                CreationUserId: '',
                ModificationUserId: '',
                Password: '',
                ModificationDate: '',
                Login: '',
                Type: 'client',
                IdClient: this.$route.params.client_id,
            },
        }
    },
    validations() {
        return {
            formData: {
                FirstName: { required },
                LastName: { required },
                Email: { email, required },
                Phone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
                MobilePhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
            },
        }
    },
    computed: {
        ...mapState(useClientStore, {
            currentContact: 'currentContact',
            currentClient: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useClientStore),
    },

    methods: {
        ...mapActions(useUserStore, ['update', 'create']),
        back() {
            this.clientStore.fetchOne(this.$route.params.client_id)

            this.$router.push({
                name: 'contacts',
                client_id: this.$route.params.client_id,
            })
            this.$emit('back', 'contacts')
        },

        async createContact() {
            this.creating = true

            const isFormCorrect = await this.v$.$validate()
            this.formData.CreationUserId = this.user.id
            this.formData.Login = this.formData.Email
            this.formData.IdClient = this.$route.params.client_id

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                try {
                    const response = await this.create(this.formData)
                    if (response) {
                        // document.head.removeChild(this.googleScript)
                        this.$router.push({
                            name: 'contacts',
                            client_id: this.$route.params.client_id,
                        })
                        this.$toast.success(this.$t('contact_create_ok'))

                        // this.$emit('created', 'contacts')
                        this.$emit('contacts', this.$route.params.client_id)
                    }
                } catch (err) {
                    console.log(err)
                    this.creating = false

                    this.$toast.error(`${this.$t('contact_create_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateContact() {
            this.creating = true

            const isFormCorrect = await this.v$.$validate()
            this.formData.ModificationUserId = this.user.id
            this.formData.Login = this.formData.Email
            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response = this.update(params)
                    if (response) {
                        this.clientStore.$patch({ currentContact: null })
                        this.$router.push({
                            name: 'contacts',
                            client_id: this.$route.params.client_id,
                        })
                        this.$toast.success(this.$t('contact_update_ok'))

                        // this.$emit('updated', 'contacts')
                        this.$emit('contacts', this.$route.params.client_id)
                    }
                } catch (err) {
                    console.log(err.data.message)
                    this.creating = false

                    this.$toast.error(`${this.$t('contact_update_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.clientStore.$patch({ currentContact: null })
    },
}
</script>
