<template>
    <modal
        :headerText="'new_reminder'"
        :buttonText="'save'"
        :creating="creating"
        id="addModal"
        :mxWidth="'w-1/2'"
        :mxHeight="'h-screen mt-32'"
        :buttonAlign="'justify-center'"
        :secondSubmit="true"
        :secondSubmitText="'save_and_send'"
        @closeModal="unsetReminderCreation()"
        @sendFromModal="createNewReminder($event)"
    >
        <template #body>
            <div class="p-8">
                <div class="mb-3 items-center flex">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('meeting_date') }}</label
                    >
                    {{ eventDate.slice(0, 16).replace('T', ' ') }}
                </div>
                <div class="mb-3 items-center flex">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('reminder_date') + ' *' }}</label
                    >
                    <input
                        type="date"
                        :min="now"
                        :max="maxDate"
                        v-model="reminderForm.Date"
                    />
                    <div
                        class="text-xs italic mt-1 ml-2 mb-2"
                        v-for="error of v$.reminderForm.Date.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="mb-3 items-center flex">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('users_to_notify') + ' *' }}</label
                    >
                    <input
                        type="email"
                        multiple
                        v-model="reminderForm.EmailUsers"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 ml-2 mb-2"
                        v-for="error of v$.reminderForm.EmailUsers.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from '@/components/atoms/Modal.vue'
import { useReminderStore } from '@/stores/reminderStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'
import { mapActions, mapState } from 'pinia'

// TODO les min et max date ne fonctionnent pas
// on peut valider le form saans date de reminder bad time format dans les autres form enlever l'ehure
// PB de l'id qui n'est pas retourné quand on créé une nouvelle note et du coup on ne peut pas créer le reminder qui va avec l'idEvent

const emailPattern = helpers.regex(
    /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$/
)
const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
)
export default {
    name: 'CreateReminderModal',
    components: {
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    props: {
        eventId: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        eventEntityType: {
            type: String,
            required: true,
            default: '',
        },
        eventDate: {
            type: String,
            required: true,
            default: '',
        },
        eventDefautlEmail: {
            type: String,
            required: true,
            default: '',
        },
    },
    data() {
        return {
            now: new Date().toISOString().substr(0, 10),
            maxDate: new Date().toISOString().substr(0, 10),
            creating: false,
            reminderForm: {
                Id: '',
                EmailUsers: '',
                SendNow: false,
                Date: new Date().toISOString().substr(0, 10),
                IdEvent: '',
                EventEntityType: '',
                EventDate: '',
                Sent: false,
                CreationUserId: '',
                ModificationUserId: '',
                IdTemplate: null,
            },
        }
    },
    mounted() {
        this.v$.$reset()

        this.reminderForm.CreationUserId = this.user.id
        this.reminderForm.ModificationUserId = this.user.id
        this.reminderForm.IdEvent = this.eventId
        this.reminderForm.EventEntityType = this.eventEntityType
        this.reminderForm.EventDate = this.eventDate
        this.reminderForm.EmailUsers = this.eventDefautlEmail

        this.maxDate = new Date(this.eventDate).toISOString().substr(0, 10)
    },
    validations() {
        return {
            reminderForm: {
                Date: {
                    required: requiredIf(function (abc) {
                        return this.reminderCreation === true
                    }),
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid
                    ),
                },
                EmailUsers: {
                    required: requiredIf(function (abc) {
                        return this.reminderCreation === true
                    }),
                    emailPattern: helpers.withMessage(
                        this.$t('bad_emails_format'),
                        emailPattern
                    ),
                },
            },
        }
    },
    methods: {
        ...mapActions(useReminderStore, ['create', 'update']),
        async createNewReminder(sendReminderNow = false) {
            this.creating = true
            this.reminderForm.SendNow = sendReminderNow
            this.v$.reminderForm.$reset()
            const isValid = await this.v$.reminderForm.$validate()
            if (isValid === false) {
                this.$toast.error(this.$t('form_errors'))
                this.creating = false

                return false
            }
            if (this.eventId === '') {
                this.$emit('reminderToCreate', this.reminderForm)
            } else {
                this.reminderForm.CreationUserId = this.user.id
                this.reminderForm.ModificationUserId = this.user.id
                this.create(this.reminderForm)
                    .then((res) => {
                        this.$emit('reminderCreated', res.data)
                        this.creating = false
                        this.$toast.success(this.$t('create_ok'))
                        return true
                    })
                    .catch((err) => {
                        this.creating = false

                        this.$toast.error(this.$t('create_ko'))
                        return false
                    })
            }

            return true
        },
        // async updateReminder() {
        //     this.creating = true

        //     this.v$.reminderForm.$reset()
        //     const isValid = await this.v$.reminderForm.$validate()
        //     if (isValid === false) {
        //         this.$toast.error(this.$t('form_errors'))
        //         this.creating = false

        //         return false
        //     }
        //     this.reminderForm.CreationUserId = this.user.id
        //     this.reminderForm.ModificationUserId = this.user.id
        //     this.update(this.reminderForm)
        //         .then((res) => {
        //             this.$emit('reminderCreated', res.data)
        //             this.creating = false
        //             this.$toast.success(this.$t('create_ok'))
        //             return true
        //         })
        //         .catch((err) => {
        //             this.creating = false

        //             this.$toast.error(this.$t('create_ko'))
        //             return false
        //         })
        //     return true
        // },
        unsetReminderCreation() {
            this.v$.reminderForm.$reset()

            this.reminderCreation = false
            this.reminderForm = {
                EmailUsers: '',
                Id: '',
                CreationUserId: '',
                Date: new Date(),
                IdEntity: '',
                EntityType: '',
                EventDate: '',
                Sent: false,
            }
            this.creating = false
            this.$emit('closeReminderModal')
        },
    },
}
</script>
