<template>
    <div class="mr-20 bg-white darky">
        <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        />
        <component
            :is="componentToDisplay"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
        <!-- @create="toCreate()"
            @goToUpdateArea="toUpdate($event)" -->
    </div>
</template>
<script>
// import Settings from '@/views/settings/List.vue'
import { mapStores } from 'pinia'
import OrdersSettings from '@/views/settings/OrdersSettings.vue'
import NavTabs from '@/components/elements/NavTabs.vue'
import { useUserStore } from '@/stores/userStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

export default {
    name: 'SettingsWrapper',
    components: {
        // Settings,
        OrdersSettings,
        NavTabs,
    },
    data() {
        return {
            componentToDisplay: this.humanize(this.$route.name),
        }
    },
    computed: {
        ...mapStores(useUserStore),
        settingsActionsAccess() {
            return actionsAccess.settings
        },
        tabs() {
            const realTabs = []
            if (
                checkAccess(this.userStore, this.settingsActionsAccess.orders)
            ) {
                realTabs.push({
                    icon: 'cart-shopping',
                    name: 'orders_settings',
                    disabled: false,
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.settingsActionsAccess.notifications
                )
            ) {
                realTabs.push({
                    icon: 'bell',
                    name: 'notifications_settings',
                    disabled: true,
                })
            }
            if (
                checkAccess(this.userStore, this.settingsActionsAccess.billings)
            ) {
                realTabs.push({
                    icon: 'file-invoice-dollar',
                    name: 'billings_settings',
                    disabled: true,
                })
            }
            return realTabs
        },
    },
    created() {
        this.$cookies.remove('filtered')
    },
    mounted() {
        this.changeContent(this.$route.name)
    },
    updated() {
        if (this.sideBarNav) {
            // TODO voir quand autres pages accessibles depuis sidebar ne pas remove si on va sur des updates
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            this.changeContent(this.$route.name)
            this.$emit('removeSidebarNav')
        }
    },

    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
