<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @update="toUpdate($event)"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import { mapActions } from 'pinia'
import NavTabs from '@/components/elements/NavTabs.vue'
import Suppliers from '@/views/suppliers/List.vue'
import NewSupplier from '@/views/suppliers/CreateOrUpdate.vue'
import { useSupplierStore } from '@/stores/supplierStore'

export default {
    name: 'SuppliersWrapper',
    components: {
        NavTabs,
        Suppliers,
        NewSupplier,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'suppliers'],
                // ['users', 'contacts'],
                // ['address-book', 'delivery_addresses'],
                // ['pen', 'order_step_correction'],
            ],
        }
    },
    created() {
        this.$cookies.remove('filtered')
    },
    mounted() {
        if (this.$route.name === 'update_supplier') {
            this.toUpdate(this.$route.params.id)
        } else if (this.$route.name === 'new_supplier') {
            this.toCreate()
        } else {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            if (this.sideBarNav === 'update_supplier') {
                this.toUpdate(this.$route.params.id)
            } else if (this.sideBarNav === 'new_supplier') {
                this.toCreate()
            } else {
                this.changeContent(this.sideBarNav)
            }
            this.$emit('removeSidebarNav')
        }
    },

    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        ...mapActions(useSupplierStore, ['fetchOne']),
        toCreate() {
            this.componentToDisplay = this.humanize('new_supplier')
            this.$router.push({ name: 'new_supplier' })
        },
        toUpdate(id) {
            this.fetchOne(id)
            this.componentToDisplay = this.humanize('new_supplier')
            this.$router.push({ name: 'update_supplier', params: { id } })
        },
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
