<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'vehicles'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="vehiclesList"
                :items="vehicles"
                :columns="columns"
                :storeToSort="'vehicles'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="$emit('update', $event)"
                @deactivate="deactivateOne($event)"
                @deactivateAll="deactivateAll($event)"
                @activate="activateOne($event)"
            >
                <!-- @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)" -->
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'

import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Vehicles',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            vehiclesInterval: null,

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        vehicles() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    created() {
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
            this.setRealSelected('Active', 0)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.searchVehicles(realSearch)
        this.vehiclesInterval = setInterval(() => {
            this.searchVehicles(realSearch)
        }, 20000)
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    unmounted() {
        clearInterval(this.vehiclesInterval)
    },

    computed: {
        ...mapState(useVehicleStore, {
            vehicles: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapWritableState(useVehicleStore, {
            currentVehicle: 'current',
        }),
        ...mapStores(useUserStore),
        vehiclesActionsAccess() {
            return actionsAccess.vehicles
        },
        columns() {
            return [
                {
                    name: 'active',
                    filtrable: true,
                    dbField: 'Active',
                    type: 'select',

                    sortable: false,
                    withIconNoText: true,
                    // selected: {
                    //     name: this.$t('active'),
                    //     value: 1,
                    //     dbField: 'Active',
                    // },
                    options: [
                        {
                            name: 'active',
                            value: 1,
                            dbField: 'Active',
                        },
                        {
                            name: 'deactivated',
                            value: 0,
                            dbField: 'Active',
                        },
                    ],
                },
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.vehiclesActionsAccess.update
                    ),
                    routeName: 'update_vehicle',
                },
                {
                    name: 'registration',
                    filtrable: true,
                    dbField: 'Registration',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'brand',
                    filtrable: true,
                    dbField: 'Brand',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'model',
                    filtrable: true,
                    dbField: 'Model',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'powered',
                    translate: true,
                    dbField: 'Powered',
                    filtrable: true,
                    type: 'select',
                    sortable: false,
                    options: [
                        {
                            name: 'legs',
                            value: 'legs',
                            dbField: 'Powered',
                        },
                        {
                            name: 'hydrogen',
                            value: 'hydrogen',
                            dbField: 'Powered',
                        },
                        {
                            name: 'gasoline',
                            value: 'gasoline',
                            dbField: 'Powered',
                        },
                        {
                            name: 'gas',
                            value: 'gas',
                            dbField: 'Powered',
                        },
                        {
                            name: 'diesel',
                            value: 'diesel',
                            dbField: 'Powered',
                        },
                        {
                            name: 'electric',
                            value: 'electric',
                            dbField: 'Powered',
                        },
                        {
                            name: 'hybrid_gasoline',
                            value: 'hybrideGasoline',
                            dbField: 'Powered',
                        },
                        {
                            name: 'hybrid_diesel',
                            value: 'hybridDiesel',
                            dbField: 'Powered',
                        },
                        {
                            name: 'plugin_hybrid_diesel',
                            value: 'pluginHybridDiesel',
                            dbField: 'Powered',
                        },
                        {
                            name: 'plugin_hybrid_gasoline',
                            value: 'pluginHybridGasoline',
                            dbField: 'Powered',
                        },
                    ],
                },
                {
                    name: 'type',
                    dbField: 'Type',
                    filtrable: true,
                    type: 'select',
                    sortable: false,
                    options: [
                        {
                            name: 'bike',
                            value: 'bike',
                            dbField: 'Type',
                        },
                        {
                            name: 'cargo_bike',
                            value: 'cargoBike',
                            dbField: 'Type',
                        },
                        {
                            name: 'delivery_tricycle',
                            value: 'deliveryTricycle',
                            dbField: 'Type',
                        },
                        {
                            name: 'car',
                            value: 'car',
                            dbField: 'Type',
                        },
                        {
                            name: 'van',
                            value: 'van',
                            dbField: 'Type',
                        },
                        {
                            name: 'truck',
                            value: 'truck',
                            dbField: 'Type',
                        },
                        {
                            name: 'boat',
                            value: 'boat',
                            dbField: 'Type',
                        },
                    ],
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.vehiclesActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.vehiclesActionsAccess.activate)
            ) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                })
            }
            return actions
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.vehiclesActionsAccess.activate)
            ) {
                return [
                    {
                        name: 'deactivate_selection',
                        action: 'deactivateAll',
                        icon: 'times',
                    },
                ]
            }
            return []
        },
    },

    methods: {
        upFilter(event) {
            clearInterval(this.vehiclesInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.vehiclesActionsAccess.create
            )
        },
        ...mapActions(useVehicleStore, {
            searchVehicles: 'search',
            fetchOne: 'fetchOne',
            deactivate: 'deactivate',
            update: 'update',
        }),

        async deactivateOne(id) {
            this.searchingCursor = true

            const response = await this.deactivate(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('vehicle_deactivate_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('vehicle_deactivate_ko'))
            }
        },
        deactivateAll(elementsToDeactivate) {
            this.searchingCursor = true

            elementsToDeactivate.forEach((el, index) => {
                this.deactivate(el)
                if (index === elementsToDeactivate.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        activateOne(id) {
            this.searchingCursor = true

            this.currentVehicle = null
            this.fetchOne(id).then(() => {
                const vehicleInterval = setInterval(() => {
                    if (this.currentVehicle !== null) {
                        clearInterval(vehicleInterval)

                        const form = {
                            id,
                            form: {
                                Id: this.currentVehicle.Id,
                                IdExternal: this.currentVehicle.IdExternal,
                                Brand: this.currentVehicle.Brand,
                                Model: this.currentVehicle.Model,
                                Type: this.currentVehicle.Type,
                                Powered: this.currentVehicle.Powered,
                                Capacity: this.currentVehicle.Capacity,
                                WeightMaxi: this.currentVehicle.WeightMaxi,
                                BoxWidth: this.currentVehicle.BoxWidth,
                                BoxLength: this.currentVehicle.BoxLength,
                                BoxHeight: this.currentVehicle.BoxHeight,
                                BreakBegin: this.currentVehicle.BreakBegin,
                                BreakDuration:
                                    this.currentVehicle.BreakDuration,
                                BreakEnd: this.currentVehicle.BreakEnd,
                                TimeBegin: this.currentVehicle.TimeBegin,
                                TimeEnd: this.currentVehicle.TimeEnd,
                                MaxiLength: this.currentVehicle.MaxiLength,
                                LimitCapacity:
                                    this.currentVehicle.LimitCapacity,
                                Informations: this.currentVehicle.Informations,
                                Consumption: this.currentVehicle.Consumption,
                                AnnualCost: this.currentVehicle.AnnualCost,
                                Registration: this.currentVehicle.Registration,
                                Name: this.currentVehicle.Name,
                                CreationDate: this.currentVehicle.CreationDate,
                                CreationUserId:
                                    this.currentVehicle.CreationUserId,
                                ModificationUserId: this.userStore.current.id,
                                Active: 1,
                            },
                        }
                        this.update(form)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.searchingCursor = false

                                    this.$toast.success(
                                        this.$t('vehicle_activate_ok')
                                    )
                                } else {
                                    this.searchingCursor = false

                                    this.$toast.error(
                                        this.$t('vehicle_activate_ko')
                                    )
                                }
                            })
                            .catch((err) => {
                                this.searchingCursor = false

                                this.$toast.error(
                                    this.$t('vehicle_activate_ko')
                                )
                            })
                    }
                }, 20)
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.vehiclesInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.vehiclesInterval)
            this.$cookies.remove('filtered')

            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.searchVehicles(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.vehiclesInterval = setInterval(() => {
                this.searchVehicles(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.vehiclesInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
