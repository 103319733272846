import { useClientStore } from '@/stores/clientStore'
import { mapActions } from 'pinia'

export default {
    data() {
        return {
            orderGroups: [],
            groupForm: {},
            orderGroupOptions: [],
        }
    },

    mounted() {},
    methods: {
        ...mapActions(useClientStore, ['fetchClientPricing']),
        openOrderGroupModal(payload = null) {
            if (payload !== null) {
                this.setPricings(payload.client)

                this.groupForm = {
                    Name: payload.group.Name,
                    IdPricing: payload.group.IdPricing,
                    Id: payload.group.Id,
                    Price: payload.group.Price,
                    ForcedPrice: payload.group.ForcedPrice ?? 0,
                    ModificationUserId: this.userStore.current.id,
                }
                if (payload.group.IdPricing) {
                    this.selectedTourPricing = {
                        name: payload.group.Pricing.Name,
                        value: +payload.group.IdPricing,
                    }
                }  else {
                    this.selectedTourPricing = null
                }
                this.showOrderGroupModal = true
                this.refreshMS += 1
            } else {
                this.showOrderGroupModal = false
                this.groupForm = null
                this.selectedTourPricing = null
                this.tourPricingOptions = []
            }
        },
        setPricings(clientId) {
            const tourPricingPayload = {
                clientId,
                type: 'tour',
            }
            this.fetchClientPricing(tourPricingPayload).then((res) => {
                this.tourPricingOptions = []
                if (res && res.status === 200) {
                    res.data.forEach((pricing) => {
                        if (
                            !this.tourPricingOptions.find(
                                (option) => +option.value === +pricing.Id
                            )
                        ) {
                            this.tourPricingOptions.push({
                                name: pricing.Name,
                                value: +pricing.Id,
                            })
                        }
                    })
                }
                this.refreshMS += 1
            })
        },
    },
}
