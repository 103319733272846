import { indexOf } from 'lodash'

export default {
    data() {
        return {
            userTypes: [
                {
                    name: 'all',
                    value: false,
                    type: 'all',
                },
                {
                    name: 'operators',
                    value: false,
                    type: 'operator',
                },
                {
                    name: 'drivers',
                    value: false,
                    type: 'driver',
                },
                {
                    name: 'clients',
                    value: false,
                    type: 'client',
                },
                {
                    name: 'admins',
                    value: false,
                    type: 'admin',
                },
                {
                    name: 'superadmin',
                    value: false,
                    type: 'superadmin',
                },
                {
                    name: 'visitors',
                    value: false,
                    type: 'visitor',
                },
            ],
        }
    },
    methods: {
        isCheckedAuth(authId) {
            if (this.roleAuthIds.find((el) => el === authId)) {
                return { value: true }
            }
            return { value: false }
        },
        checkAuth(auth, checkValue) {
            // TODO G2RER SI JE UNCHECK LE FULL par entite
            // TODO UNCHECK Full_access
            // TODO un check une case doit supprimer le full_access sans tout décocher pour autant

            if (auth.Code === 'FULL_ACCESS') {
                const authToRM = this.roleAuthIds.find((el) => el === auth.Id)
                if (authToRM === undefined && checkValue === true) {
                    this.roleAuthIds.push(auth.Id)
                } else {
                    this.roleAuthIds.splice(
                        this.roleAuthIds.indexOf(authToRM),
                        1
                    )
                }
            } else if (auth.Code.includes('_FULL')) {
                this.authorisations.forEach((authorisation) => {
                    if (
                        authorisation.Code.substring(
                            0,
                            authorisation.Code.indexOf('_')
                        ) === auth.Code.substring(0, auth.Code.indexOf('_'))
                    ) {
                        const authToRM = this.roleAuthIds.find(
                            (el) => el === authorisation.Id
                        )
                        if (authToRM === undefined && checkValue === true) {
                            this.roleAuthIds.push(authorisation.Id)
                        } else if (
                            authToRM !== undefined &&
                            checkValue === false
                        ) {
                            this.roleAuthIds.splice(
                                this.roleAuthIds.indexOf(authToRM),
                                1
                            )
                        }
                    }
                })
            } else {
                const entityFullAccess = this.authorisations.find(
                    (el) =>
                        el.Code ===
                        `${auth.Code.substring(0, auth.Code.indexOf('_'))}_FULL`
                )

                if (
                    entityFullAccess &&
                    checkValue === false &&
                    this.isCheckedAuth(entityFullAccess.Id).value === true
                ) {
                    this.roleAuthIds.splice(
                        this.roleAuthIds.indexOf(entityFullAccess.Id),
                        1
                    )
                }
                const authToRM = this.roleAuthIds.find((el) => el === auth.Id)
                if (authToRM === undefined && checkValue === true) {
                    this.roleAuthIds.push(auth.Id)
                } else {
                    this.roleAuthIds.splice(
                        this.roleAuthIds.indexOf(authToRM),
                        1
                    )
                }
            }
        },
        isCheckedRole(roleId) {
            if (this.authRoleIds.find((el) => el === roleId)) {
                return { value: true }
            }
            return { value: false }
        },
        checkRole(roleId) {
            const roleIndex = this.authRoleIds.findIndex((el) => +el === roleId)
            if (roleIndex === -1) {
                this.authRoleIds.push(roleId)
            } else {
                this.authRoleIds.splice(roleIndex, 1)
            }
        },
        isCheckedUser(userId) {
            if (this.userRoleIds.find((el) => +el === userId)) {
                return { value: true }
            }
            return { value: false }
        },
        checkUser(userId) {
            const userIndex = this.userRoleIds.findIndex((el) => +el === userId)
            if (userIndex === -1) {
                this.userRoleIds.push(userId)
            } else {
                this.userRoleIds.splice(userIndex, 1)
            }
        },

        checkByType(type) {
            if (type.type === 'all') {
                this.userTypes.forEach((t) => {
                    if (t.type !== 'all') {
                        t.value = type.value
                    }
                })
                this.userRoleIds = []
                if (type.value === true) {
                    this.users.forEach((user) => {
                        this.checkUser(user.id)
                    })
                }
            } else {
                if (type.value === false) {
                    this.userTypes[0].value = false
                }
                const usersToCheck = this.users.filter(
                    (el) => el.type === type.type
                )
                if (usersToCheck) {
                    usersToCheck.forEach((user) => {
                        this.checkUser(user.id)
                    })
                }
            }
        },
        notPickableCodes(count, store, currentCodeId = null) {
            const result = []
            if (count > 0) {
                store.forEach((st) => {
                    if (currentCodeId === null || currentCodeId !== st.Id) {
                        result.push(st.Code)
                    }
                })
            }
            return result
        },
    },
}
