export default {
    methods: {
        setSlider() {
            const sliderInterval = setInterval(() => {
                if (document.querySelector('.slider')) {
                    clearInterval(sliderInterval)
                    const slider = document.querySelector('.slider')
                    let isDown = false
                    let startX
                    let scrollLeft

                    slider.addEventListener('mousedown', (e) => {
                        isDown = true
                        slider.classList.add('active')
                        startX = e.pageX - slider.offsetLeft
                        scrollLeft = slider.scrollLeft
                    })
                    slider.addEventListener('mouseleave', (e) => {
                        e.preventDefault()

                        isDown = false
                        slider.classList.remove('active')
                    })
                    slider.addEventListener('mouseup', (e) => {
                        e.preventDefault()

                        isDown = false
                        slider.classList.remove('active')
                    })
                    slider.addEventListener('mousemove', (e) => {
                        if (!isDown) return
                        e.preventDefault()
                        const x = e.pageX - slider.offsetLeft
                        const walk = (x - startX) * 3 // scroll-fast
                        slider.scrollLeft = scrollLeft - walk
                    })
                }
            }, 10)
        },
    },
}
