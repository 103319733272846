import areas from './areas'
import biiimBo from './backoffice'
import billings from './billings'
import clients from './clients'
import dashboard from './dashboard'
import drivers from './drivers'
import orders from './orders'
import settings from './settings'
import suppliers from './suppliers'
import tours from './tours'
import users from './users'
import vehicles from './vehicles'

import CreateAccount from '../views/CreateAccount.vue'
import Login from '../views/Login.vue'
import MyAccount from '../views/MyAccount.vue'
import ResetPassword from '../views/ResetPassword.vue'

const baseRoute = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            layout: 'default-layout',
            authorisations: [],
            showUniverseSearch: false,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            authorisations: [],
            layout: 'default-layout',
            showUniverseSearch: false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authorisations: [],
            showUniverseSearch: false,
        },
    },

    {
        path: '/my-account/:id',
        name: 'my_account',
        component: MyAccount,
        meta: {
            authorisations: [],
            showUniverseSearch: false,
        },
    },
    {
        path: '/sign-up',
        name: 'sign_up',
        component: CreateAccount,
        meta: {
            authorisations: [],

            layout: 'default-layout',
            showUniverseSearch: false,
        },
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // },
]
const routes = baseRoute.concat(
    ...areas,
    ...billings,
    ...clients,
    ...dashboard,
    ...drivers,
    ...orders,
    ...tours,
    ...vehicles,
    ...suppliers,
    ...users,
    ...settings,
    ...biiimBo
)
export default routes
