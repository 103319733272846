export default {
    methods: {
        toCorrection(date) {
            // ? pour comportement cohé"rent avec celui mis dans la map view on ne limite plus
            // l'affichage des stetps à corriger  à ceux correspondantt à la date de la simulation
            this.$router.replace({
                path: '/orders/order-step-correction',
                // query: { date },
            })
        },
        toUpdateOrder(id) {
            this.fetchOne(id)

            this.$router.push({ name: 'update_order', params: { id } })
        },
        toUpdateStep(step) {
            if (step.incomplete) {
                step.id = step.Id
                step.order_ref = step.Reference
            }
            this.fetchStep({
                id: step.id,
                order_ref: step.order_ref,
            })

            this.$router.push({
                name: 'update_step',
                params: { id: step.id, order_ref: step.order_ref },
            })
        },
    },
}
