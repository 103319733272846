<template>
    <modal
        :headerText="'action_required'"
        :buttonText="'confirm'"
        id="confirmTouroptimodal"
        :mxWidth="'max-w-2xl'"
        :buttonAlign="'justify-center'"
        @closeModal="actionConfirmation(false)"
        @sendFromModal="actionConfirmation(true)"
    >
        <template #body>
            <div class="mx-8">
                <div class="flex justify-center font-semibold mt-4">
                    {{ $t(confirmModalText) }}
                </div>
                <span v-if="elementsToConfirm.length > 1">
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.Client" class="mr-1">
                                    {{ toConfirm.Client }}
                                </span>
                                <span v-if="toConfirm.Ref">
                                    {{
                                        ' - ' +
                                        $t('order_ref') +
                                        ': ' +
                                        toConfirm.Ref
                                    }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </span>

                <div
                    v-if="confirmModalTextOptiErrorInfo !== ''"
                    class="flex items-center justify-center mt-4"
                >
                    {{ $t(confirmModalTextOptiErrorInfo) }}
                </div>
                <div
                    v-if="confirmModalTextOptiError !== ''"
                    class="flex items-center justify-center mt-4"
                >
                    {{ $t(confirmModalTextOptiError) }}
                </div>
            </div></template
        ></modal
    >
</template>
<script>
import Modal from '@/components/atoms/Modal.vue'

export default {
    components: {
        Modal,
    },
    props: {
        confirmModalText: {
            type: String,
            default: '',
        },

        elementsToConfirm: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            confirmModalTextOptiErrorInfo: 'opti_error_info',
            confirmModalTextOptiError: 'opti_error_choice',
        }
    },
    methods: {
        actionConfirmation(action) {
            this.$emit('tourOptiConfirmation', action)
        },
    },
}
</script>
