<template>
    <div class="darky">
        <main-header v-show="isMobile === false" />
        <div class="flex bg-white darky">
            <div
                v-show="isMobile === false"
                class="flex-none"
                :class="
                    $route.meta.map || $route.meta.admin ? 'pl-10' : 'pl-16'
                "
            >
                <side-bar
                    @navigate="navigate = $event"
                    @sidebarNavigateFromRoute="sidebarNavFromRoute = true"
                />
            </div>
            <div class="flex-1 bg-white darky">
                <router-view
                    :sideBarNav="navigate"
                    :sidebarNavigateFromRoute="sidebarNavFromRoute"
                    @removeSidebarNav="removeSN()"
                ></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '@/components/Sidebar.vue'
import MainHeader from '@/components/Header.vue'

export default {
    name: 'FullLayout',
    components: {
        SideBar,
        MainHeader,
    },
    mounted() {
        this.navigate = this.$route.name
    },
    data() {
        return {
            navigate: '',
            sidebarNavFromRoute: false,
        }
    },

    computed: {
        isMobile() {
            if (this.$route.meta.map === true) {
                return (
                    (navigator.userAgentData &&
                        navigator.userAgentData.mobile &&
                        navigator.userAgentData.mobile === true) ||
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                )
            }
            return false
        },
    },
    methods: {
        removeSN() {
            this.navigate = ''
            this.sidebarNavFromRoute = false
        },
    },
}
</script>
<style scoped>
.content-margin {
    margin-left: 4.4em;
}
</style>
