import ApiDoc from '../views/superAdmin/ApiDoc.vue'
import Authorisation from '../views/superAdmin/AuthorisationManagement.vue'
import Backoffice from '../views/superAdmin/BackofficeWrapper.vue'
import LogsViewer from '../views/superAdmin/LogsViewer.vue'

const tours = [
    {
        path: '/biiim-admin',
        name: 'backoffice',
        component: Backoffice,

        redirect: { name: 'authorisations' },
        meta: {
            layout: 'full-layout',
            admin: true,
            authorisations: ['FULL_ACCESS'],
        },
        children: [
            {
                path: 'roles-and-authorisations',
                name: 'authorisations',
                component: Authorisation,

                meta: {
                    authorisations: ['FULL_ACCESS'],
                    showUniverseSearch: false,
                },
            },

            {
                path: 'logs',
                name: 'logs',
                component: LogsViewer,
                meta: {
                    authorisations: ['FULL_ACCESS'],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'api-doc',
                name: 'api_doc',
                component: ApiDoc,
                meta: {
                    authorisations: ['FULL_ACCESS'],
                    showUniverseSearch: false,
                },
            },
        ],
    },
]

export default tours
