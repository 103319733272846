<template>
    <div>
        <div
            tabindex="-1"
            aria-hidden="true"
            class="overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center bg-gray-400/75"
        >
            <div class="relative p-4" :class="widthAndHeight">
                <!-- ???  w-full au dessus -->
                <div class="relative darky bg-white rounded shadow">
                    <div
                        v-if="headerText !== ''"
                        class="flex justify-between items-start p-4 rounded-t border-b"
                    >
                        <h3 class="text-xl mt-2 font-semibold text-zinc-400">
                            {{
                                tcChoice
                                    ? $tc(headerText, tcChoice)
                                    : $t(headerText)
                            }}
                        </h3>
                        <button
                            type="button"
                            :disabled="creating && spin"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center"
                            @click="$emit('closeModal')"
                        >
                            <svg
                                class="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <slot name="body">
                        <div class="p-6 space-y-6">
                            <p class="text-base leading-relaxed text-gray-500">
                                With less than a month to go before the European
                                Union enacts new consumer privacy laws for its
                                citizens, companies around the world are
                                updating their terms of service agreements to
                                comply.
                            </p>
                            <p class="text-base leading-relaxed text-gray-500">
                                The European Union’s General Data Protection
                                Regulation (G.D.P.R.) goes into effect on May 25
                                and is meant to ensure a common set of data
                                rights in the European Union. It requires
                                organizations to notify users as soon as
                                possible of high-risk data breaches that could
                                personally affect them.
                            </p>
                        </div>
                    </slot>

                    <div
                        class="flex items-end p-6 mt-2 mr-2 ml-2 space-x-2"
                        :class="buttonAlign"
                    >
                        <!-- to separate buttons with border add rounded-b border-t border-gray-200 to the above div-->
                        <button
                            v-if="canCancel === true"
                            id="cancel-button"
                            @click="$emit('closeModal')"
                            type="button"
                            :disabled="creating && spin"
                            class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-500 font-bold rounded text-sm px-5 py-2.5 text-center"
                        >
                            {{ $t('cancel') }}
                        </button>
                        <button
                            type="button"
                            id="submit-button"
                            :disabled="creating"
                            @click="$emit('sendFromModal')"
                            :style="buttonStyle"
                            class="text-white themed-button focus:outline-none rounded text-sm font-bold px-5 py-2.5 focus:z-10"
                        >
                            <span v-if="creating && spin">
                                <fa-icon icon="spinner" spin></fa-icon
                            ></span>
                            <span v-else>
                                {{ $t(buttonText) }}
                            </span>
                        </button>
                        <button
                            v-if="secondSubmit === true"
                            id="second-submit-button"
                            type="button"
                            :disabled="creating"
                            @click="$emit('sendFromModal', true)"
                            :style="secondButtonStyle"
                            class="text-white themed-button focus:outline-none rounded text-sm font-bold px-5 py-2.5 focus:z-10"
                        >
                            <span v-if="creating && spin">
                                <fa-icon icon="spinner" spin></fa-icon
                            ></span>
                            <span v-else>
                                {{ $t(secondSubmitText) }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Modal',
    props: {
        headerText: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: 'send',
        },
        buttonStyle: {
            type: String,
            default: '',
        },
        secondButtonStyle: {
            type: String,
            default: '',
        },
        mxWidth: {
            type: String,
            default: 'max-w-2xl',
        },
        mxHeight: {
            type: String,
            default: 'h-screen',
        },
        buttonAlign: {
            type: String,
            default: 'justify-end ',
        },
        creating: {
            type: Boolean,
            default: false,
        },
        canCancel: {
            type: Boolean,
            default: true,
        },
        secondSubmit: {
            type: Boolean,
            default: false,
        },
        secondSubmitText: {
            type: String,
            default: 'send',
        },
        spin: {
            type: Boolean,
            default: true,
        },
        tcChoice: {
            default: null,
        },
    },
    computed: {
        widthAndHeight() {
            return `${this.mxHeight} ${this.mxWidth}`
        },
    },
}
</script>
