<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'orders'" />
            <filtrable-table
                v-else
                id="orderStepsList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="orderSteps"
                :columns="columns"
                :storeToSort="'orders'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="false"
                :itemActions="itemActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateStep($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useOrderStore } from '@/stores/orderStore'
import filterList from '@/mixins/filterList'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useUserStore } from '@/stores/userStore'
import { useUniverseStore } from '@/stores/universeStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Orders',
    components: {
        FiltrableTable,
        FetchDataError,
    },
    mixins: [filterList],
    inject: ['eventBus'],
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            ordersInterval: null,

            sortableFields: [
                { name: this.$t('name'), value: 'AddressName' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        orderSteps() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')

        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        if (filteredList) {
            // this.search.push(this.$cookies.get('query'))
            this.search = filteredList.query
            // this.searchQuery = filteredList.query[0].term
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
        }
        // TODO ceci limiterai l'affichage aux steps à corriger pour une date utilisé précédement si accès via simulations
        // if (this.$route.query.date) {
        //     this.filter.push({
        //         dbField: 'FromDateTime',
        //         value: this.$route.query.date,
        //     })
        //     // this.$cookies.remove('filtered')
        //     this.$cookies.set('filtered', {
        //         query: this.search,
        //         sortDirection: this.sortDirection,
        //         filter: this.filter,
        //         sortField: this.sortField,
        //         perPage: this.perPage,
        //         toCorrect: true,
        //         page: this.page,
        //     })
        // }
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                const universeFilter = {
                    dbField: 'IdUniverse',
                    value:
                        this.selectedUniverses.length > 0
                            ? this.selectedUniverses
                            : this.universesOptions,
                }
                if (!this.filter.find((f) => f.dbField === 'IdUniverse')) {
                    this.filter.push(universeFilter)
                }
                const realSearch = {
                    query: this.search,
                    sortDirection: this.sortDirection,
                    filter: this.filter,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    toCorrect: true,
                    page: this.page,
                }
                // this.$cookies.set('filtered', realSearch)
                this.searchSteps(realSearch)
                this.ordersInterval = setInterval(() => {
                    this.searchSteps(realSearch)
                }, 20000)
            }
        }, 10)
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    unmounted() {
        clearInterval(this.ordersInterval)
    },

    computed: {
        orderStepCorrectionActionsAccess() {
            return actionsAccess.orders
        },
        ...mapStores(useUserStore),
        ...mapState(useOrderStore, {
            orderSteps: 'steps',
            count: 'countSteps',
            fetchError: 'fetchError',
            totalPages: 'stepsTotalPages',
            loading: 'loading',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        columns() {
            return [
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'AddressName',
                    type: 'search',
                    isLink: checkAccess(
                        this.userStore,
                        this.orderStepCorrectionActionsAccess.update
                    ),
                    sortable: true,
                    routeName: 'update_step',
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.orderStepCorrectionActionsAccess.update
                )
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.orderStepCorrectionActionsAccess.delete
                )
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.orderStepCorrectionActionsAccess.delete
                )
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },
    methods: {
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },
        ...mapActions(useOrderStore, ['deleteStep', 'searchSteps']),
        goToUpdateStep(id) {
            clearInterval(this.ordersInterval)
            this.$emit('goToUpdateStep', { id })
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.deleteStep(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('address_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('address_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.deleteStep(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            search.toCorrect = true
            this.setRealSearch(this.search)

            this.searchSteps(search).then(() => {
                this.searchingCursor = false
            })
            this.refresh += 1
            this.ordersInterval = setInterval(() => {
                this.searchSteps(search)
            }, 20000)
            this.$cookies.set('filtered', search)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)

            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1

            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                toCorrect: true,

                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
