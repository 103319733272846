/* eslint-disable no-console */
<template>
    <div class="grid grid-cols-12" id="login">
        <div  class="h-screen themed-bg md:block col-span-6 hidden">
            <img
                alt="Biiim Solutions"
                src="/img/roadLogin.png"
                class="mx-auto mt-12"
            />
            <div class="text-xl text-white">
                {{ $t('login_biiim_info') }}
            </div>
        </div>
        <div
            class="min-h-full flex flex-col justify-center md:col-span-6 col-span-12 h-screen"
        >
            <div class="rounded-md mx-auto mb-5">
                <alert-info
                    v-if="resetPwInfo"
                    class="bg-green-100 text-green-700"
                    :color="'green'"
                    :text="'reset_pw_ok'"
                />
            </div>

            <div id="login" class="m-6 p-8">
                <div class="flex justify-end mb-4">
                    <night-mode-switch />
                    <language-switcher />
                </div>
                <div class="flex justify-center">
                    <img
                    :class="userStore.logo === 'bft' ? 'w-[50%]' : ''"
                        alt="Company logo"
                        :src="`/logo/${userStore.logo}-nobg.png`"
                    />
                </div>
                <alert-info
                    v-if="userStore.loginError"
                    :color="'zinc'"
                    :text="userStore.loginError"
                />
                <div class="px-5 py-7">
                    <form @submit.prevent="connect()">
                        <div class="mb-5">
                            <label
                                class="font-semibold text-sm text-gray-600 pb-1 block"
                                >{{ $t('login') }}</label
                            >
                            <input
                                v-model="login"
                                autocomplete="username"
                                tabindex="1"
                                class="border rounded px-3 py-2 mt-1 text-sm w-full"
                            />
                            <div
                                class="text-xs italic mt-2 mb-2"
                                v-for="error of v$.login.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-5">
                            <label
                                class="font-semibold text-sm text-gray-600 pb-1 block"
                                >{{ $t('password') }}</label
                            >
                            <div class="relative w-full">
                                <div
                                    class="absolute inset-y-0 right-0 flex items-center px-2"
                                >
                                    <icon-button
                                        class="ml-2 cursor-pointer"
                                        @click="togglePasswordVisibility()"
                                        :icon="passwordVisibility"
                                        :noStyle="true"
                                        :iconStyle="'text-zinc-500 pt-2'"
                                    ></icon-button>
                                </div>

                                <input
                                    tabindex="2"
                                    id="passwordInput"
                                    :type="passwordType"
                                    v-model="password"
                                    autocomplete="current-password"
                                    class="border rounded px-3 py-2 mt-1 text-sm w-full leading-tight"
                                />
                                <div
                                    class="text-xs italic mt-2 mb-2"
                                    v-for="error of v$.password.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            tabindex="3"
                            type="submit"
                            class="themed-button transition duration-200 themed-button focus:shadow-sm focus:ring-opacity-50 text-white w-full py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                        >
                            <span class="inline-block mr-2">{{
                                $t('connect')
                            }}</span>
                        </button>
                    </form>

                    <button
                        @click="showModal()"
                        class="transition duration-200 mx-5 mb-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500 focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-inset"
                    >
                        <span class="inline-block ml-1 font-bold">{{
                            $t('forgot_password')
                        }}</span>
                    </button>
                    <hr />
                    <button
                        @click="$router.push({ name: 'sign_up' })"
                        class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500 focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-inset"
                    >
                        <span class="inline-block ml-1 font-bold">{{
                            $t('sign_up')
                        }}</span>
                    </button>
                </div>
            </div>
        </div>
        <modal
            v-if="show"
            :headerText="'reset_pw_modal_title'"
            id="resetPwModal"
            :buttonAlign="'justify-center'"
            @closeModal="show = false"
            @sendFromModal="forgotPasswordRequest()"
        >
            <template #body>
                <label
                    class="font-semibold text-sm mt-4 text-zinc-400 pb-1 block"
                    >{{ $t('email_address') + ' *' }}</label
                >
                <input
                    v-model="loginForResetPw"
                    class="border rounded my-4 px-3 py-2 mt-1 text-sm w-2/3"
                />
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.loginForResetPw.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="mb-4 text-xs text-zinc-400 whitespace-nowrap mx-4">
                    {{ $t('reset_pw_instructions') }}
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
import { mapStores } from 'pinia'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useUserStore } from '@/stores/userStore'
import NightModeSwitch from '@/components/elements/NightModeSwitch.vue'
import LanguageSwitcher from '@/components/elements/LanguageSwitcher.vue'
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import Modal from '@/components/atoms/Modal.vue'

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    name: 'LoginForm',
    components: {
        NightModeSwitch,
        LanguageSwitcher,
        AlertInfo,
        IconButton,
        Modal,
    },
    created() {
        this.userStore.$patch({
            current: null,
            isLoggedIn: false,
            isB2C: null,
        })

        this.$cookies.keys().forEach((cookie) => {
            if (
                cookie !== 'logo' &&
                cookie !== 'nightMode' &&
                cookie !== 'locale'
            ) {
                this.$cookies.remove(cookie)
            }
        })
    },
    data() {
        return {
            login: null,
            loginForResetPw: null,
            password: null,
            error: null,
            passwordType: 'password',
            passwordVisibility: 'eye',
            show: false,
            resetPwInfo: false,
            resetPwEmptyField: null,
        }
    },
    validations() {
        return {
            login: { required },
            password: { required },
            loginForResetPw: { required, email },
        }
    },
    computed: {
        ...mapStores(useUserStore),
    },
    methods: {
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        async connect() {
            this.userStore.$patch({ loginError: null })
            const connect =
                (await this.v$.login.$validate()) &&
                (await this.v$.password.$validate())
            if (connect) {
                this.userStore.login({
                    Login: this.login,
                    Password: this.password,
                })
            }
        },
        showModal() {
            this.resetPwEmptyField = null
            this.show = true
        },
        async forgotPasswordRequest() {
            const sendRequest = await this.v$.loginForResetPw.$validate()

            this.resetPwEmptyField = null
            if (sendRequest) {
                this.show = false
                this.userStore.forgotPassword({
                    Email: this.loginForResetPw,
                })
                this.resetPwInfo = true
            }
            return this.resetPwInfo
        },
    },
}
</script>
