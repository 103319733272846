import { defineStore } from 'pinia'

const useBillingStore = defineStore('billing', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    // actions
    actions: {
        searchBillings(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else if (type.dbField === 'PaymentStatus') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"NOT LIKE","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/invoice/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/invoice/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const billings = []
                            if (count > 0) {
                                res.data.Records.forEach((billing) => {
                                    const formattedBilling = {
                                        id: +billing.Id,
                                        client_name: billing.ClientName,
                                        bill_number: billing.Number,
                                        id_order: billing.IdOrderFActuration,
                                        id_client: billing.IdClient,
                                        invoice_number: billing.Number,
                                        date_invoice: billing.DateInvoice,
                                        due_date: billing.DueDateInvoice,
                                        type: billing.Type,
                                        ht: billing.TotalHT,
                                        ttc: billing.TotalTTC,
                                        file: billing.FileName,
                                        creation_date: billing.CreationDate,
                                        payment_date: billing.PaymentDate
                                            ? billing.PaymentDate
                                            : '',
                                        payment_status: billing.PaymentStatus,

                                        payment_type: billing.PaymentType
                                            ? billing.PaymentType
                                            : '',
                                    }
                                    billings.push(formattedBilling)
                                })
                            }

                            this.all = billings
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        download(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/invoice/${payload}/pdf`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        sendInvoice(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/invoice/email`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        sendReminderInvoice(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/invoice/remindByEmail`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        setInvoiceAsPaid(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/invoice/setAsPaid`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/invoice/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})

export { useBillingStore }
