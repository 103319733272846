<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div class="relative z-0">
            <div v-if="clientId && currentClient && userStore.isB2C === false">
                <back-to
                    @back="back()"
                    :text="
                        $t('delivery_addresses') +
                        ' ' +
                        $t('for') +
                        ' ' +
                        currentClient.company_name
                    "
                />
            </div>
            <!-- <div v-if="clientId && currentClient && isB2C === false">
             
                <div class="mt-20 flex mb-3 items-center">
                    <div class="text-3xl ml-2">
                        {{
                            $t('delivery_addresses') +
                            ' ' +
                            $t('for') +
                            ' ' +
                            currentClient.company_name
                        }}
                    </div>
                </div>
            </div> -->
            <fetch-data-error
                v-if="fetchError"
                :dataType="'contact_client_history'"
            />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="contactHistoryList"
                :items="all"
                :columns="columns"
                :storeToSort="'contactClientHistory'"
                :page="page"
                :count="count"
                :loading="loading"
                :resetCheckedItems="resetCheckedItems"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                @dateFilterUpdate="setDateFilter($event)"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearchCustom"
                @deleteAll="deleteAll($event)"
                @sortDirectionChanged="updateSortDirectionCustom($event)"
                @selected="upFilter($event)"
                @create="openNoteForm()"
                @clear="clearFilter($event)"
                @update="openNoteForm($event)"
                @delete="deleteOne($event)"
                @unarchive="updateArchived($event)"
                @unarchiveAll="updateArchivedMany($event)"
                @archive="updateArchived($event)"
                @archiveAll="updateArchivedMany($event)"
            >
            </filtrable-table>
            <div
                v-if="showNoteForm"
                class="absolute bg-slate-700 bg-opacity-60 inset-0 flex justify-end h-screen z-10"
            >
                <new-Note
                    :key="refresh"
                    @closeNoteForm="openNoteForm()"
                    :disableForm="disableForm"
                    :isNoteUpdate="isNoteUpdate"
                    @newFileUploaded="refreshNoteFiles($event)"
                ></new-Note>
            </div>
            <modal
                v-if="showConfirmModal"
                :headerText="confirmModalHeader"
                :buttonText="'confirm'"
                id="deletemodal"
                :buttonAlign="'justify-center'"
                @closeModal="actionConfirmation(false)"
                @sendFromModal="actionConfirmation(true)"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center font-semibold mt-4">
                            {{ $t(confirmModalText) }}
                        </div>

                        <div
                            v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                            :key="iToConfirm"
                            class="flex justify-center mt-2"
                        >
                            <ul>
                                <li
                                    class="flex text-sm items-center whitespace-nowrap"
                                >
                                    <fa-icon
                                        icon="circle"
                                        class="fa-2xs mr-2"
                                    />
                                    <span v-if="toConfirm.Title" class="mr-1">
                                        {{ toConfirm.Title }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div>
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import filterList from '@/mixins/filterList'
import { mapState, mapStores, mapWritableState } from 'pinia'

import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import BackTo from '@/components/elements/BackTo.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import NewNote from './generated/CreateOrUpdateNote.vue'

import { useClientStore } from '@/stores/clientStore'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'ContactClientHistoryList',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        BackTo,
        NewNote,
        Modal,
    },
    data() {
        return {
            isNoteUpdate: false,
            resetCheckedItems: false,
            disableForm: false,
            showConfirmModal: false,
            confirmAction: null,
            elementsToConfirm: [],
            confirmModalHeader: '',
            confirmModaltext: '',
            confirmModaltext2: '',
            showNoteForm: false,
            refresh: 0,
            clientUniverseFilter: [],
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            datePicked: null,
            contactClientHistoryInterval: null,
            filter: [],

            sortableFields: [
                // { name: this.$t('client'), value: 'Client' },
                { name: this.$t('address_name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    // watch: {
    //     addresses() {
    //         if (this.page === 1) {
    //             this.refresh += 1
    //         }
    //     },
    //     $route(to, from) {
    //         if (
    //             !this.$route.params.id &&
    //             this.userStore.isB2C === false &&
    //             from.params.id &&
    //             from.name === 'client_delivery_addresses'
    //         ) {
    //             clearInterval(this.contactClientHistoryInterval)
    //             this.$cookies.remove('filtered_contactClientHistory')

    //             this.currentClient = null
    //             this.searchQuery = ''
    //             this.search = []
    //             this.sortDirection = 'DESC'
    //             this.sortField = 'CreationDate'
    //             this.perPage = 10
    //             this.page = 1
    //             this.filter = []

    //             this.clientUniverseFilter = []
    //             const universesInterval = setInterval(() => {
    //                 if (this.universesOptions.length > 0) {
    //                     clearInterval(universesInterval)
    //                     if (this.userStore.isB2C === false) {
    //                         if (
    //                             !this.filter.find(
    //                                 (f) => f.dbField === 'IdUniverse'
    //                             )
    //                         ) {
    //                             const universeFilter = {
    //                                 dbField: 'IdUniverse',
    //                                 value:
    //                                     this.selectedUniverses.length > 0
    //                                         ? this.selectedUniverses
    //                                         : this.universesOptions,
    //                             }
    //                             this.clientUniverseFilter.push(universeFilter)
    //                         }
    //                     }

    //                     const clientsPayload = {
    //                         query: [],
    //                         filter: this.clientUniverseFilter,
    //                         perPage: 100000000000,
    //                         page: 1,
    //                         sortField: 'Name',
    //                         sortDirection: this.sortDirection,
    //                     }

    //                     this.contactClientHistoryStore
    //                         .search(clientsPayload)
    //                         .then(() => {
    //                             this.setResults()
    //                         })
    //                 }
    //             }, 10)
    //         }
    //     },
    // },
    created() {
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get(
                    'filtered_contactClientHistory',
                )
        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)

                const clientSearchQuery = []
               
                if (filteredList) {
                    this.search = filteredList.query
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    this.columns.forEach((col, index) => {
                        if (
                            col.type === 'select' ||
                            col.type === 'icon-select'
                        ) {
                            this.setRealSelected(col.dbField, index)
                        }
                    })
                    this.setRealSearch(this.search)
                    const clQuery = this.search.find(
                        (s) => s.dbField === 'client',
                    )
                    if (clQuery) {
                        clientSearchQuery.push({
                            term: clQuery.term,
                            dbField: 'Name',
                        })
                    }
                } else {
                    this.filter.push({
                        dbField: 'Active',
                        value: 1,
                        name: 'ongoing',
                    })
                    this.setRealSelected('Active', 0)
                }

                // let clientsPayload = {
                //     query: clientSearchQuery,
                //     filter: [],
                //     perPage: 10000,
                //     page: 1,
                //     sortField: 'Name',
                //     sortDirection: this.sortDirection,
                // }
                const realSearch = {
                    clientId: this.clientId,
                    query: this.search,
                    filter: this.filter,
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                }
                if (this.userStore.isB2C === false) {
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            if (this.userStore.isB2C === false) {
                                if (
                                    !this.filter.find(
                                        (f) => f.dbField === 'IdUniverse',
                                    )
                                ) {
                                    const universeFilter = {
                                        dbField: 'IdUniverse',
                                        value:
                                            this.selectedUniverses.length > 0
                                                ? this.selectedUniverses
                                                : this.universesOptions,
                                    }
                                    this.filter.push(universeFilter)
                                }
                            }

                            // clientsPayload = {
                            //     query: clientSearchQuery,
                            //     filter: this.clientUniverseFilter,
                            //     perPage: 10000,
                            //     page: 1,
                            //     sortField: 'Name',
                            //     sortDirection: this.sortDirection,
                            // }

                            if (!this.$route.params.id) {
                                this.contactClientHistoryStore.search(
                                    realSearch,
                                )
                                this.contactClientHistoryInterval = setInterval(
                                    () => {
                                        this.contactClientHistoryStore.search(
                                            realSearch,
                                        )
                                    },
                                    20000,
                                )
                            } else {
                                this.contactClientHistoryStore.search(
                                    realSearch,
                                )
                                this.contactClientHistoryInterval = setInterval(
                                    () => {
                                        this.contactClientHistoryStore.search(
                                            realSearch,
                                        )
                                    },
                                    20000,
                                )
                            }
                        }
                    }, 10)
                } else {
                    this.contactClientHistoryStore.search(realSearch)
                    this.contactClientHistoryInterval = setInterval(() => {
                        this.contactClientHistoryStore.search(realSearch)
                    }, 20000)
                }
            }
        }, 200)
    },

    unmounted() {
        clearInterval(this.contactClientHistoryInterval)
        this.currentClient = null
        this.eventBus.all.clear()

        // if (this.$route.params.id) {

        //     this.$cookies.remove('filtered_contactClientHistory')
        // }
    },
    computed: {
        contactClientHistory() {
            return actionsAccess.clients.contactClientHistory
        },
        ...mapWritableState(useContactClientHistoryStore, {
            all: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            current: 'current',
            loading: 'loading',
        }),
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),

        ...mapStores(
            useClientStore,
            useUserStore,
            useContactClientHistoryStore,
        ),
        clientId() {
            return this.userStore.isB2C === true &&
                this.userStore.current.idClient
                ? this.userStore.current.idClient
                : this.$route.params.id
                  ? this.$route.params.id
                  : this.currentClient && this.currentClient.id
                    ? this.currentClient.id
                    : null
        },
        columns() {
            return [
                {
                    name: 'archived_status',
                    filtrable: true,
                    dbField: 'Active',
                    archive: true,
                    type: 'select',
                    sortable: false,
                    withIconNoText: true,

                    options: [
                        {
                            name: 'archived',
                            value: 0,
                            dbField: 'Active',
                        },
                        {
                            name: 'ongoing',
                            value: 1,
                            dbField: 'Active',
                        },
                    ],
                },
                {
                    name: 'meeting_date',
                    sortable: true,
                    datePicker: true,
                    dbField: 'MeetingDate',
                    pickedDate: this.datePicked,
                    hide: false,
                },
                {
                    name: 'name',
                    isLink: checkAccess(
                        this.userStore,
                        this.contactClientHistory.update,
                    ),

                    filtrable: true,
                    dbField: 'Title',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'client_type',
                    filtrable: true,
                    dbField: 'Client.Type',
                    translate: true,
                    type: 'select',
                    options: [
                        {
                            name: 'B2C',
                            value: 'customer',
                            dbField: 'Client.Type',
                        },
                        {
                            name: 'B2B',
                            value: 'business',
                            dbField: 'Client.Type',
                        },
                        {
                            name: 'prospect',
                            value: 'prospect',
                            dbField: 'Client.Type',
                        },
                    ],
                    sortable: false,
                    hide: false,
                },
                {
                    name: 'client',
                    filtrable: !this.$route.params.id,
                    dbField: 'Client.Name',
                    type: !this.$route.params.id ? 'search' : '',
                    sortable: !this.$route.params.id,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'contact',
                    filtrable: true,
                    subInfo: true,
                    dbField: 'ContactUser',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'sales_person',
                    filtrable: true,
                    dbField: 'SalesPersonUser',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                // {
                //     name: 'description',
                //     filtrable: true,
                //     dbField: 'Description',
                //     type: 'search',
                //     sortable: true,
                //     hide: false,
                // },
            ]
        },
        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.contactClientHistory.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                    editNote: true,
                })
            }

            if (
                checkAccess(this.userStore, this.contactClientHistory.archive)
            ) {
                actions.push({
                    name: 'archive',
                    action: 'archive',
                    icon: 'box-archive',
                    archive: true,
                })
            }
            if (checkAccess(this.userStore, this.contactClientHistory.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.contactClientHistory.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            if (
                checkAccess(this.userStore, this.contactClientHistory.archive)
            ) {
                if (
                    this.filter.find(
                        (f) => f.dbField === 'Active' && f.value === 1,
                    )
                ) {
                    actions.push({
                        name: 'archive_selection',
                        action: 'archiveAll',
                        icon: 'box-archive',
                    })
                } else if (
                    this.filter.find(
                        (f) => f.dbField === 'Active' && f.value === 0,
                    )
                ) {
                    actions.push({
                        name: 'unarchive_selection',
                        action: 'unarchiveAll',
                        icon: 'box-open',
                    })
                }
            }
            return actions
        },
    },

    methods: {
        updateArchived(id) {
            clearInterval(this.contactClientHistoryInterval)
            const payload = {
                id,
                ModificationUserId: this.userStore.current.id,
            }
            this.contactClientHistoryStore
                .archiveNote(payload)
                .then(() => {
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('error_occured'))
                })
        },
        updateArchivedMany(ids) {
            clearInterval(this.contactClientHistoryInterval)
            ids.forEach((id) => {
                this.updateArchived(id)
            })
        },
        searchCustomUniverses(clear = false) {
            clearInterval(this.contactClientHistoryInterval)

            this.filter = []
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                this.filter.push({
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                })
            } else {
                this.filter.push({
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                })
            }
            this.setResults()
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.contactClientHistoryInterval)
            this.searchCustomUniverses()
        },
        upFilter(event) {
            clearInterval(this.contactClientHistoryInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.contactClientHistory.create)
        },
        openNoteForm(note = null) {
            clearInterval(this.contactClientHistoryInterval)
            this.contactClientHistoryStore.$patch({ current: null })
            const tempShow = this.showNoteForm === false
            if (note && tempShow === true) {
                this.contactClientHistoryStore.fetchOne(note).then((res) => {
                    this.clientStore.fetchOne(res.data.Client.Id).then(() => {
                        this.disableForm = +res.data.Active === 0
                        this.isNoteUpdate = true
                        this.refresh += 1
                        this.showNoteForm = !this.showNoteForm
                    })
                    // this.disableForm = +res.data.Active === 0
                    // this.isNoteUpdate = true
                    // this.refresh += 1
                    // this.showNoteForm = !this.showNoteForm
                })
            } else {
                this.showNoteForm = !this.showNoteForm
                if (this.showNoteForm === false) {
                    this.isNoteUpdate = false

                    clearInterval(this.contactClientHistoryInterval)
                    this.clientStore.$patch({ current: null })
                    this.disableForm = false

                    this.setResults()
                }
            }
        },
        refreshNoteFiles(id) {
            this.contactClientHistoryStore.fetchNoteFiles(id).then(() => {
                this.refresh += 1
            })
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        deleteOne(note) {
            this.confirmAction = null
            this.elementsToConfirm = []
            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            const noteInfo = {
                Title: note.name,
            }
            this.elementsToConfirm.push(noteInfo)

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.contactClientHistoryStore
                            .delete(note.id)
                            .then(() => {
                                this.$emit('refetchNotes')
                            })
                            .catch((err) => {
                                this.$toast.error(this.$t('error_occured'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)

            // todo ajouter la modale de confirmation
        },

        async deleteAll(elToDelete) {
            this.searchingCursor = true

            const elements = []
            let response = null

            this.confirmAction = null
            this.elementsToConfirm = []

            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            elToDelete.forEach((id) => {
                const note = this.contactClientHistoryStore.all.find(
                    (note) => note.id === id,
                )
                if (this.userStore.isB2C === true) {
                    const noteInfo = {
                        Title: note.name,
                    }
                    this.elementsToConfirm.push(noteInfo)
                    elements.push({ Id: id })
                } else {
                    const noteInfo = {
                        Title: note.name,
                    }
                    this.elementsToConfirm.push(noteInfo)
                    elements.push({ Id: note.id })
                }
            })
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []

                    if (this.confirmAction === true) {
                        this.confirmAction = null

                        this.searchingCursor = false

                        response =
                            await this.contactClientHistoryStore.deleteMany(
                                elements,
                            )

                        if (response.status === 200) {
                            this.setResults()
                            this.resetCheckedItems = true
                            this.$toast.success(this.$t('delete_many_ok'))
                            this.searchingCursor = false
                        } else {
                            this.$toast.error(this.$t('delete_many_ko'))
                            this.searchingCursor = false
                        }
                        this.resetCheckedItems = false
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        back() {
            // const backPath = this.$router.options.history.state.back
            this.$router.push({ name: 'clients' })
            this.$emit('back', 'clients')
        },
        goToUpdateOne(id) {
            this.$emit('goToUpdateAddress', id)
        },

        setPage(isNext) {
            this.searchingCursor = true

            // clearInterval(this.contactClientHistoryInterval)
            // this.$cookies.remove('filtered_contactClientHistory')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        setDateFilter(dFilter) {
            this.searchingCursor = true
            const filterExistsIndex = this.filter.findIndex(
                (df) => df.dbField === dFilter.dbField,
            )
            if (filterExistsIndex !== -1) {
                this.filter[filterExistsIndex] = dFilter
            } else {
                this.filter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })

                this.dateFilter = dFilter.value
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.contactClientHistoryInterval)
            this.$cookies.remove('filtered_contactClientHistory')
            this.search = search.query
            search.clientId = this.clientId
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField =
                search.sortField === 'client' ? '' : search.sortField
            this.perPage = search.perPage
            this.page = search.page

            // let clientsPayload = {
            //     query: [],
            //     filter: this.clientUniverseFilter,
            //     perPage: 100000000000,
            //     page: 1,
            //     sortField: 'Name',
            //     sortDirection: this.sortDirection,
            // }
            // this.search.forEach((s) => {
            //     if (s.dbField === 'client') {
            //         clientsPayload = {
            //             query: [{ term: s.term, dbField: 'Name' }],
            //             filter: [],
            //             perPage: 100000000000,
            //             page: 1,
            //             sortField: 'Name',
            //             sortDirection: this.sortDirection,
            //         }
            //     }
            // })
            this.setRealSearch(this.search)

            // if (!this.clientId) {
            //     this.contactClientHistoryStore.search(search).then(() => {
            //         this.searchingCursor = false

            //         this.refresh += 1
            //         this.contactClientHistoryInterval = setInterval(() => {
            //             this.contactClientHistoryStore.search(search)
            //         }, 20000)
            //     })
            // } else {
            this.contactClientHistoryStore.search(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
                this.contactClientHistoryInterval = setInterval(() => {
                    this.contactClientHistoryStore.search(search)
                }, 20000)
            })
            // }

            this.$cookies.set('filtered_contactClientHistory', search)
        },
        changePerPage(perPage) {
            // this.searchingCursor = true

            // clearInterval(this.contactClientHistoryInterval)
            this.$cookies.remove('filtered_contactClientHistory')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        updateSortDirectionCustom(sort) {
            this.searchingCursor = true

            this.page = 1

            this.sortDirection = sort.direction
            this.sortField = sort.field === 'client' ? '' : sort.field
            this.setResults()
        },

        // updateSortField(sortField) {
        //     this.sortField = sortField === 'client' ? '' : sortField
        //     this.setResults()
        // },
        debounceSearchCustom(query) {
            this.searchingCursor = true

            this.page = 1

            this.search.forEach((s, index) => {
                if (s.dbField !== 'client' && s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
        setResults() {
            const search = {
                clientId: this.clientId,
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField === 'client' ? '' : this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
