import { defineStore } from 'pinia'

const useReminderStore = defineStore('reminder', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    actions: {
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/reminder`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        // this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})

export { useReminderStore }
