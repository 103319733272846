<template>
    <dropdown-menu
        id="langSwitcher"
        :title="$t('lang_switch')"
        :head="locale"
        :items="languages"
        @picked="changeLocale($event)"
    />
</template>
<script>
import { mapStores } from 'pinia'
import DropdownMenu from '../atoms/DropdownMenu.vue'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'LanguageSwitcher',
    components: {
        DropdownMenu,
    },
    data() {
        return {
            languages: [{ name: 'En' }, { name: 'Fr' }],
            locale: this.$cookies.get('locale') || this.$i18n.locale,
        }
    },
    mounted() {
        this.changeLocale({
            name: this.$cookies.get('locale') || this.$i18n.locale,
        })
    },
    computed: {
        ...mapStores(useUserStore),
    },
    methods: {
        changeLocale(newLang) {
            this.locale = newLang.name
            this.$cookies.set(
                'locale',
                newLang.name.charAt(0).toUpperCase() + newLang.name.slice(1)
            )
            this.$i18n.locale = newLang.name
            this.userStore.$patch({
                locale:
                    newLang.name.charAt(0).toUpperCase() +
                    newLang.name.slice(1),
            })
        },
    },
}
</script>
