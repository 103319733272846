import { defineStore } from 'pinia'

const useContractStore = defineStore('contract', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        totalPages: 1,
        current: null,
        loading: false,
    }),
    actions: {
        search(payload) {
            let query = []
            this.fetchError = false
            this.loading = true

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'IdClient') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            // showRec = false
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            // if (payload.onlyLatePlus30Todo === true && statuses.length === 0) {
            //     let datePlus30 = new Date()
            //     datePlus30.setDate(datePlus30.getDate() + 30)
            //     datePlus30 = datePlus30.toISOString().slice(0, 10)
            //     query.push(
            //         `{ "Status":[{"operator":"NOT LIKE","value":"done"}]}`
            //     )
            //     query.push(
            //         `{ "Status":[{"operator":"LIKE","value":"late"}], "DateBegin":[{"operator":"<=","value":"${datePlus30}%"}]}`
            //     )
            // }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/contract/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/contract/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }

            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const contracts = []
                            if (res.data.TotalResults > 0) {
                                res.data.Records.forEach((contract) => {
                                    const formattedContract = {
                                        id: +contract.Id,
                                        name: contract.Name,
                                        id_client: +contract.IdClient,
                                        client_name:
                                            contract.Client !== null
                                                ? contract.Client.Name
                                                : this.i18n.global.$t(
                                                      'no_client'
                                                  ),
                                        end_date: contract.EndDate,
                                        contract_type: contract.Type,
                                        signature_date: contract.SignatureDate,
                                        notice: contract.NoticePeriod, // in weeks
                                        contract_duration: contract.Duration, // in months
                                        idUniverse: +contract.IdUniverse,
                                        creation_date: contract.CreationDate,
                                        alert_before_end:
                                            contract.AlertBeforeEnd, // in weeks
                                        auto_renew: contract.AutomaticRenewal,
                                        modification_date:
                                            contract.ModificationDate,
                                    }
                                    contracts.push(formattedContract)
                                })
                                this.all = contracts
                            }

                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null

                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/contract', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/contract/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/contract/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const contract = res.data
                        this.current = contract
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/contract/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})
export { useContractStore }
