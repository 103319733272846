<template>
    <img
        :alt="$t(title)"
        svg-inline
        :src="`${iconSrc}`"
        class="mx-auto darky"
        :class="iconClass"
    />
</template>
<script>
export default {
    name: 'BiiimIcon',
    props: {
        iconSrc: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        iconClass: {
            type: String,
            default: 'w-7 icon-img',
        },
    },
}
</script>
