<template>
    <div
        :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
        class="w-[350px] card-note self-stretch rounded-lg bg-white z-10 flex flex-col items-start justify-start pt-[15px] px-3.5 pb-[15.100000000000364px] relative shrink-0 border-[1px] border-solid border-gainsboro-100 mt-1"
    >
        <div
            class="self-stretch flex flex-row flex-wrap items-center justify-start py-0 pr-3.5 pl-0 gap-[0px_20px]"
        >
            <div
                class="flex-1 relative leading-[16px] inline-block overflow-hidden text-ellipsis whitespace-nowrap min-w-[77px]"
                @click.prevent="noteAccess.update ? updateNote(note) : ''"
                :class="noteAccess.update ? 'cursor-pointer' : 'cursor-default'"
            >
                {{ `${note.contact}` }}
            </div>
            <div class="flex flex-row items-start z-50 justify-start gap-[3px]">
                <!-- <div
                    class="h-[23.3px] rounded-10xs bg-lightsteelblue flex flex-row items-center justify-center"
                >
                    <div
                        v-if="noteAccess.update"
                        class="h-[15px] w-[23px] cursor-pointer flex flex-row items-center justify-center py-0 px-1 box-border opacity-[0.3]"
                        @click.prevent="updateNote(note)"
                    >
                        <img
                            class="h-[10.6px] w-[10.6px] relative"
                            loading="lazy"
                            alt=""
                            src="/img/generated/vector-1.svg"
                        />
                    </div>
                </div> -->

                <div
                    v-if="noteAccess.delete"
                    @click.prevent="deleteNote(note)"
                    :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
                    class="h-[23.3px] w-[23.3px] z-50 rounded-10xs bg-lightsteelblue flex flex-row items-center justify-center"
                >
                    <img
                        class="h-[15px] w-[15px] relative overflow-hidden shrink-0"
                        loading="lazy"
                        alt=""
                        src="/img/generated/icon.svg"
                    />
                </div>
                <div
                    v-if="noteAccess.update && unarchiveOnly === false"
                    :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
                    class="h-[23.3px] z-50 w-[23.3px] rounded-10xs bg-lightsteelblue flex flex-row items-center justify-center"
                    @click.prevent="pinNote(note.id)"
                >
                    <img
                        class="h-[21.2px] w-[21.2px] object-contain"
                        loading="lazy"
                        :alt="$t('pinned')"
                        :src="pinIcon"
                    />
                </div>
                <div
                    v-if="noteAccess.archive && unarchiveOnly === false"
                    :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
                    class="z-50 h-[23.3px] w-[23.3px] text-[#e7e7e7] flex flex-row items-center justify-center"
                    @click.prevent="archiveNote(note.id)"
                >
                    <fa-icon
                        icon="box-archive"
                        class="fa-sm text-[#e7e7e7]"
                        :title="$t('archive')"
                    />
                </div>

                <div
                    v-if="noteAccess.archive && unarchiveOnly === true"
                    :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
                    class="z-50 h-[23.3px] w-[23.3px] text-[#e7e7e7] flex flex-row items-center justify-center"
                    @click.prevent="archiveNote(note.id)"
                >
                    <fa-icon
                        icon="box-open"
                        class="fa-sm text-[#e7e7e7]"
                        :title="$t('unarchive')"
                    />
                </div>
            </div>
        </div>
        <div
            class="self-stretch flex flex-col items-start justify-start gap-[5px] z-[1] text-darkslategray-100"
            @click.prevent="noteAccess.update ? updateNote(note) : ''"
            :class="noteAccess.update ? 'cursor-pointer' : 'cursor-default'"
        >
            <div
                class="self-stretch relative leading-[16px] font-medium [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]"
            >
                {{ note.name }}
            </div>
            <div
                class="self-stretch h-5 flex flex-row items-center justify-between gap-[20px] text-xs text-darkslategray-400"
            >
                <div
                    class="flex flex-row items-center justify-start gap-[10px]"
                >
                    <div class="relative">{{ note.meeting_date }}</div>
                    <div
                        class="flex flex-row items-center justify-start py-0 pr-[19px] pl-0 text-3xs"
                    >
                        <div
                            :class="noteTypeClass.bg"
                            class="h-[17px] rounded-xl flex flex-row items-center justify-center py-0 px-[7px] box-border"
                        >
                            <div class="relative" :class="noteTypeClass.text">
                                {{
                                    $t(
                                        note.type === 'email'
                                            ? 'email_note'
                                            : note.type
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-start">
                    <div class="relative">{{ note.files.length }}</div>
                    <div
                        class="h-[15px] w-[15px] flex flex-row items-center justify-center"
                    >
                        <img
                            class="h-[13.5px] w-[6.8px] relative"
                            loading="lazy"
                            alt=""
                            src="/img/generated/frame-34241.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>

                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.Title" class="mr-1">
                                    {{ toConfirm.Title }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </div>
</template>
<script>
import Modal from '@/components/atoms/Modal.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useUserStore } from '@/stores/userStore'
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CardNote',
    components: {
        Modal,
    },
    props: {
        note: { type: Object, required: true },
        pinIcon: {
            type: String,
            default: '/img/generated/component-19-2@2x.png',
        },
        unarchiveOnly: { type: Boolean, default: false },
        // component19: { type: String },
        // dotMenuDetailLivraison: { type: String },
        // propMinWidth: { type: [Object, Array, String, Number, Boolean] },
        // propHeight: { type: [Object, Array, String, Number, Boolean] },
    },
    data() {
        return {
            updatingNote: false,
            showConfirmModal: false,
            confirmAction: null,
            elementsToConfirm: [],
            confirmModalHeader: '',
            confirmModaltext: '',
            confirmModaltext2: '',
        }
    },
    computed: {
        noteAccess() {
            const accesses = {
                delete: checkAccess(
                    this.userStore,
                    actionsAccess.clients.contactClientHistory.delete
                ),
                update: checkAccess(
                    this.userStore,
                    actionsAccess.clients.contactClientHistory.update
                ),
                archive: checkAccess(
                    this.userStore,
                    actionsAccess.clients.contactClientHistory.archive
                ),
            }
            return accesses
        },
        noteTypeClass() {
            const result = {
                text: 'text-mediumaquamarine-100',
                bg: 'bg-mediumaquamarine-200',
            }
            if (this.note.type === 'other') {
                result.text = 'text-chocolate-100'
                result.bg = 'bg-chocolate-200'
            }
            if (this.note.type === 'email') {
                result.text = 'text-mediumslateblue-100'
                result.bg = 'bg-mediumslateblue-300'
            }
            return result
        },
        ...mapStores(useUserStore, useContactClientHistoryStore),
    },
    methods: {
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        deleteNote(note) {
            this.confirmAction = null
            this.elementsToConfirm = []
            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            const noteInfo = {
                Title: note.name,
            }
            this.elementsToConfirm.push(noteInfo)

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    this.updatingNote = true
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.contactClientHistoryStore
                            .delete(note.id)
                            .then(() => {
                                this.$emit('refetchNotes')
                                this.updatingNote = false
                            })
                            .catch((err) => {
                                this.updatingNote = false

                                this.$toast.error(this.$t('error_occured'))
                            })
                    } else {
                        this.confirmAction = null
                        this.updatingNote = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)

            // todo ajouter la modale de confirmation
        },
        updateNote(note) {
            this.$emit('openNoteForm', note.id)
        },
        pinNote(id) {
            this.updatingNote = true

            const payload = {
                id,
                ModificationUserId: this.userStore.current.id,
            }
            this.contactClientHistoryStore.pinNote(payload).then(() => {
                this.$emit('refetchNotes')
                this.updatingNote = false
            })
        },
        archiveNote(id) {
            this.updatingNote = true

            const payload = {
                id,
                ModificationUserId: this.userStore.current.id,
            }
            this.contactClientHistoryStore.archiveNote(payload).then(() => {
                this.$emit('refetchNotes')
                this.updatingNote = false
            })
        },
    },
})
</script>
<style lang="scss" scoped>
.card-note {
    &:hover {
        animation: 0, 15s ease-in-out 0s 1 normal none running;
        -webkit-box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    }
}
img {
    opacity: 1;
}
</style>
