<template>
    <main style="width: 96vw" class="px-4 pt-20 h-full">
        <div class="rounded-md border-2 mt-16">
            <div class="my-2 flex justify-start text-2xl ml-2">
                {{ `${$tc('packages_status', 2)}` }}
            </div>
            <div class="text-sm italic ml-2 flex justify-start">
                {{ $t('status_subtext_pt_1') }}
            </div>
            <div class="text-sm italic ml-2 flex justify-start">
                {{ $t('status_subtext_pt_2') }}
            </div>

            <div class="text-sm italic ml-2 mb-4 flex justify-start">
                {{ $t('status_subtext_pt_3') }}
            </div>
            <div class="grid-cols-10 grid gap-2 px-2">
                <div class="col-span-1 px-2 pb-2">
                    <div
                        class="h-16 w-full items-center success-msg justify-center flex border-2 rounded-md"
                    >
                        <fa-icon
                            icon="plus"
                            class="fa-xl cursor-pointer"
                            :title="$t('create')"
                            @click="toCreatePackageStatus()"
                        ></fa-icon>
                    </div>
                </div>

                <div
                    class="px-2 pb-2 border-2 rounded-md col-span-1 mb-2"
                    v-for="(packageStatus, index) in packagesStatuses"
                    :key="index"
                >
                    <div class="flex justify-center my-2">
                        <!-- <div class="col-span-1"> -->
                        <fa-icon
                            icon="trash-can"
                            :title="$t('delete')"
                            class="fa-sm cursor-pointer mr-2"
                            @click="deleteStatus(packageStatus.Id)"
                        ></fa-icon>
                        <fa-icon
                            icon="pen"
                            class="fa-sm cursor-pointer"
                            :title="$t('edit')"
                            @click="toUpdatePackageStatus(packageStatus.Id)"
                        ></fa-icon>
                    </div>
                    <span
                        class="items-center text-sm justify-center mt-2 font-semibold flex custom-link mb-2"
                    >
                        {{ `${$t('code')} ${packageStatus.Code}` }}
                    </span>
                    <span
                        class="items-center text-sm justify-center font-semibold flex custom-link mb-2"
                    >
                        {{ packageStatus.Name }}
                    </span>
                    <div class="items-center text-xs justify-center flex">
                        {{
                            `${$t('modification_date')} ${new Date(
                                packageStatus.ModificationDate
                            ).toLocaleString($i18n.locale)}`
                        }}
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <modal
            v-if="show"
            :headerText="modalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-3">
                    <label class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('status_name') + ' *' }} <br />
                        <span class="text-sm italic">
                            {{ $t('status_name_info') }}</span
                        ></label
                    >
                    <input
                        v-model="formData.Name"
                        autocomplete="name"
                        maxlength="100"
                        @blur="v$.formData.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.formData.Name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <label class="basis-1/4 text-gray-600 pb-1 pt-2 mr-2 block"
                        >{{ $t('status_code') + ' *' }} <br />
                    </label>
                    <input
                        v-model="formData.Code"
                        :title="
                            editPackageStatus === true
                                ? $t('status_code_not_editable')
                                : ''
                        "
                        :disabled="editPackageStatus"
                        maxLength="10"
                        :placeholder="'ex.: FED3425'"
                        @blur="v$.formData.Code.$touch"
                        class="border rounded px-3 py-2 mt-1 w-1/4"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.formData.Code.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <span
                    class="flex mt-1 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import Modal from '@/components/atoms/Modal.vue'
import { useUserStore } from '@/stores/userStore'
import { useSettingStore } from '@/stores/settingStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'OrdersSettings',
    components: {
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            editPackageStatus: false,
            modalHeaderText: 'new_package_status',
            creating: false,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            show: false,
            packagesStatusesInterval: null,
            filter: [],

            formData: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Code: null,
            },
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
                Code: { required },
            },
        }
    },
    watch: {
        packagesStatuses() {
            this.refresh += 1
        },
    },

    mounted() {
        window.scrollTo(0, 0)
        this.searchPackagesStatuses({
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        })
        this.packagesStatusesInterval = setInterval(() => {
            this.searchPackagesStatuses({
                query: this.search,
                filter: this.filter,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
            })
        }, 20000)
    },

    unmounted() {
        clearInterval(this.packagesStatusesInterval)
    },
    computed: {
        ...mapState(useSettingStore, {
            packagesStatuses: 'packagesStatuses',
            packagesStatusesCount: 'countPackagesStatuses',
            fetchError: 'fetchError',
            totalPages: 'containersTotalPages',
            loading: 'loading',
        }),
        ...mapWritableState(useSettingStore, {
            currentPackageStatus: 'currentPackageStatus',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    methods: {
        ...mapActions(useSettingStore, [
            'fetchOnePackageStatus',
            'searchPackagesStatuses',
            'updatePackageStatus',
            'createPackageStatus',
            'deletePackageStatus',
        ]),
        notPickableCodes(currentCodeId = null) {
            const result = []
            if (this.packagesStatusesCount > 0) {
                this.packagesStatuses.forEach((status) => {
                    if (currentCodeId === null || currentCodeId !== status.Id) {
                        result.push(status.Code)
                    }
                })
            }
            return result
        },
        async deleteStatus(id) {
            const response = await this.deletePackageStatus(id)
            if (response.status === 200) {
                this.$toast.success(this.$t('deleted_ok'))
            } else {
                this.$toast.error(this.$t('deleted_ko'))
            }
        },

        toCreatePackageStatus() {
            this.currentPackageStatus = null
            this.modalHeaderText = 'new_package_status'
            this.show = true
        },
        toUpdatePackageStatus(id) {
            this.fetchOnePackageStatus(id).then((res) => {
                this.formData = {
                    Id: res.Id,
                    Name: res.Name,
                    CreationUserId: res.CreationUserId,
                    CreationDate: res.CreationDate,
                    Code: res.Code,
                }
                this.modalHeaderText = 'update_package_status'
                this.show = true
                this.editPackageStatus = true
            })
        },

        closeModal() {
            this.creating = false
            this.v$.$reset()
            this.show = false
            this.editPackageStatus = false
            this.formData = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Code: null,
            }
        },
        async submitModal() {
            this.creating = true
            const isValid = await this.v$.$validate()
            const codeIsAvailable = !this.notPickableCodes(
                this.formData.Id
            ).includes(this.formData.Code)
            this.formData.CreationUserId = this.user.id

            if (codeIsAvailable === false) {
                this.creating = false
                this.$toast.error(this.$t('code_already_exists'))
                return
            }
            if (isValid === true) {
                if (this.editPackageStatus === true) {
                    this.formData.ModificationUserId = this.user.id

                    const response = await this.updatePackageStatus({
                        id: this.formData.Id,
                        form: this.formData,
                    })
                    if (response.status === 200) {
                        this.$toast.success(this.$t('update_ok'))
                        this.closeModal()
                    } else {
                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.formData.CreationUserId = this.user.id

                    const response = await this.createPackageStatus(
                        this.formData
                    )
                    if (response.status === 201) {
                        this.$toast.success(this.$t('create_ok'))

                        this.closeModal()
                    } else {
                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
            }
        },

        displaySearchResults(search) {
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = 1

            this.searchPackagesStatuses(search)
        },

        debounceSearch(query) {
            this.search.forEach((s, index) => {
                if (s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
