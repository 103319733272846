<template>
    <div class="min-h-full flex flex-col justify-center sm:py-12">
        <div
            id="resetpassword"
            class="shadow w-full darky rounded-md p-8 xs:p-0 mx-auto md:w-full md:max-w-md"
        >
            <div class="flex justify-end mb-4">
                <night-mode-switch />
                <language-switcher />
            </div>
            <div class="flex justify-center">
                <img alt="Company logo" :src="`/logo/${logo}-nobg.png`" />
            </div>
            <!-- <alert-info
                class="bg-green-100 text-green-700"
                :color="'green'"
                :text="'pw_notice'"
            /> -->
            <div class="px-5 py-7">
                <form @submit.prevent="resetPassword()">
                    <div class="mb-10">
                        <label
                            class="font-semibold text-sm text-gray-600 pb-1 block"
                            >{{ $t('password') }}</label
                        >
                        <div class="relative w-full">
                            <div
                                class="absolute inset-y-0 right-0 flex items-center px-2"
                            >
                                <icon-button
                                    class="ml-2 cursor-pointer"
                                    @click="togglePasswordVisibility()"
                                    :icon="passwordVisibility"
                                    :noStyle="true"
                                    :iconStyle="'text-zinc-500 pt-2'"
                                ></icon-button>
                            </div>
                            <input
                                id="passwordInput"
                                v-model="password"
                                tabindex="1"
                                :type="passwordType"
                                class="border rounded px-3 py-2 mt-1 text-sm w-full leading-tight"
                            />
                        </div>
                        <password-score
                            :value="password"
                            class="mt-2 mb-6"
                            @passed="isPasswordStrong = true"
                            @failed="isPasswordStrong = false"
                        />
                    </div>
                    <div class="mb-5 mt-2">
                        <label
                            class="font-semibold text-sm text-gray-600 pb-1 block"
                            >{{ $t('confirm_password') }}</label
                        >
                        <div class="relative w-full">
                            <div
                                class="absolute inset-y-0 right-0 flex items-center px-2"
                            >
                                <icon-button
                                    @click="togglePasswordVisibility()"
                                    :icon="passwordVisibility"
                                    class="ml-2 cursor-pointer"
                                    :noStyle="true"
                                    :iconStyle="'text-zinc-500 pt-2'"
                                ></icon-button>
                            </div>
                            <input
                                id="confirmPasswordInput"
                                tabindex="2"
                                v-model="confirmPassword"
                                :type="passwordType"
                                class="border rounded px-3 py-2 mt-1 text-sm w-full leading-tight"
                            />

                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.confirmPassword.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ $t('passwords_dont_match') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        :disabled="!isPasswordStrong"
                        tabindex="3"
                        type="submit"
                        class="themed-button transition duration-200 focus:shadow-sm text-white w-full py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                    >
                        <span class="inline-block mr-2">{{ $t('send') }}</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'pinia'
import useVuelidate from '@vuelidate/core'
import { required, helpers, sameAs, minLength } from '@vuelidate/validators'
import IconButton from '@/components/atoms/IconButton.vue'
import NightModeSwitch from '@/components/elements/NightModeSwitch.vue'
import LanguageSwitcher from '@/components/elements/LanguageSwitcher.vue'
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import PasswordScore from '@/components/atoms/PasswordScore.vue'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'ResetPassword',
    // setup() {
    setup() {
        const isPasswordStrong = ref(false)

        return { isPasswordStrong, v$: useVuelidate() }
    },
    //     return { v$: useVuelidate() }
    // },
    components: {
        IconButton,
        NightModeSwitch,
        LanguageSwitcher,
        AlertInfo,
        PasswordScore,
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            passwordType: 'password',
            passwordVisibility: 'eye',
        }
    },
    validations() {
        return {
            password: {
                required,
            },
            confirmPassword: { sameAs: sameAs(this.password) },
        }
    },
    computed: {
        ...mapState(useUserStore, {
            logo: 'logo',
        }),
    },
    methods: {
        ...mapActions(useUserStore, ['doResetPassword']),
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        async resetPassword() {
            const result = await this.v$.$validate()
            if (result) {
                this.doResetPassword({
                    Token: this.$route.params.token,
                    Password: this.password,
                })
            }
        },
    },
}
</script>
