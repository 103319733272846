<template>
    <modal
        :headerText="'new_container'"
        :buttonText="'save'"
        :creating="creating"
        id="addModal"
        :mxWidth="'w-1/2'"
        :buttonAlign="'justify-center'"
        @closeModal="unsetContainerCreation()"
        @sendFromModal="createNewContainer()"
    >
        <template #body>
            <div class="p-2">
                <div class="mb-3 items-center flex">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('package_name') + ' *' }}</label
                    >
                    <input
                        v-model="containerForm.Name"
                        @blur="v$.containerForm.Name.$touch"
                        autocomplete="name"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 ml-2 mb-2"
                    v-for="error of v$.containerForm.Name.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="mb-3 items-center flex">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('description') }}</label
                    >
                    <textarea
                        v-model="containerForm.Description"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>

                <div class="grid grid-cols-4 gap-1 mt-4">
                    <div class="col-span-1">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('width') + ' *' }}</label
                        >
                        <input
                            type="number"
                            step="0.01"
                            min="0.01"
                            @blur="v$.containerForm.Width.$touch"
                            v-model="containerForm.Width"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.containerForm.Width.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('length') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="0.01"
                            step="0.01"
                            @blur="v$.containerForm.Length.$touch"
                            v-model="containerForm.Length"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.containerForm.Length.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('height') }}</label
                        >

                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            v-model="containerForm.Height"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                    </div>
                    <div class="col-span-1 items-center">
                        <label
                            class="font-semibold whitespace-nowrap basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('unit_weight') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="0.01"
                            step="0.01"
                            @blur="v$.containerForm.Weight.$touch"
                            v-model="containerForm.Weight"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.containerForm.Weight.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-center my-4">
                    <input
                        id="container-for-all-checkbox"
                        type="checkbox"
                        v-model="containerForm.containerForAll"
                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                    />
                    <label
                        for="container-for-all-checkbox"
                        class="ml-2 text-sm font-medium theme-color"
                        >{{ $t('create_container_for_all') }}</label
                    >
                </div>
                <div class="flex items-center justify-center my-4">
                    <input
                        id="set-as-default-checkbox"
                        type="checkbox"
                        v-model="containerForm.isDefault"
                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        :class="'cursor-pointer'"
                    />
                    <label
                        for="set-as-default-checkbox"
                        class="ml-2 text-sm font-medium theme-color"
                        >{{ $t('set_as_default_for_client') }}</label
                    >
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import { useClientStore } from '@/stores/clientStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { minValue, requiredIf } from '@vuelidate/validators'
import { mapActions, mapState } from 'pinia'
import Modal from '@/components/atoms/Modal.vue'

export default {
    name: 'CreateContaianerModal',
    components: {
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    computed: {
        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
    },
    props: {
        idClient: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            creating: false,
            containerForm: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: 0,
                Width: 0,
                Length: 0,
                Weight: 0,
                Description: '',
                Active: 1,
                IdClient: 0,
                containerForAll: false,
                isDefault: false,
            },
        }
    },
    validations() {
        return {
            containerForm: {
                Name: {
                    required: requiredIf(function (abc) {
                        return this.containerCreation === true
                    }),
                },
                Width: {
                    required: requiredIf(function (abc) {
                        return this.containerCreation === true
                    }),
                    minValueValue: minValue(0.01),
                },
                Length: {
                    required: requiredIf(function (abc) {
                        return this.containerCreation === true
                    }),
                    minValueValue: minValue(0.01),
                },
                Weight: {
                    required: requiredIf(function (abc) {
                        return this.containerCreation === true
                    }),
                    minValueValue: minValue(0.01),
                },
            },
        }
    },
    methods: {
        ...mapActions(useClientStore, ['createContainer']),
        async createNewContainer() {
            this.creating = true

            this.v$.containerForm.$reset()
            const isValid = await this.v$.containerForm.$validate()
            if (isValid === false) {
                this.creating = false
                this.$toast.error(this.$t('bad_packages'))
                return false
            }
            // ||
            //     this.containerForm.Width === 0 ||
            //     this.containerForm.Length === 0 ||
            //     this.containerForm.Weight === 0
            const container = {
                CreationUserId: this.user.id,
                IdClient: this.containerForm.containerForAll
                    ? 0
                    : this.idClient,
                Description: this.containerForm.Description,
                Name: this.containerForm.Name,
                Width: this.containerForm.Width,
                Height: this.containerForm.Height,
                Length: this.containerForm.Length,
                Weight: this.containerForm.Weight,
                Value: this.containerForm.Value,
                IsClientDefault: this.containerForm.isDefault,
                SetAsDefaultTo: this.containerForm.isDefault
                    ? this.idClient
                    : null,
                Active: 1,
            }
            this.createContainer(container)
                .then((res) => {
                    if (res) {
                        this.$emit('containerCreated', res.data)
                        this.creating = false
                        this.$toast.success(this.$t('container_create_ok'))
                        return true
                    }
                })
                .catch((err) => {
                    this.creating = false

                    this.$toast.error(this.$t('container_create_ko'))
                    return false
                })
        },
        unsetContainerCreation() {
            this.v$.containerForm.$reset()

            this.containerCreation = false
            this.containerForm = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: 0,
                Width: 0,
                Length: 0,
                Weight: 0,
                Description: '',
                Active: 1,
                IdClient: 0,
                containerForAll: false,
                isDefault: false,
            }
            this.creating = false
            this.$emit('closeContainerModal')
        },
    },
}
</script>
