<template>
    <div class="mt-1 items-center">
        <label
            v-if="withLabel"
            class="font-semibold basis-1/4 col-span-1 text-gray-600 pb-1 mr-2 block"
            >{{ $t('sort_by') }}</label
        >
        <Multiselect
            class="multiselect-no-pr darky"
            :class="small ? 'h-2 pd-1' : 'h10'"
            v-model="field"
            :options="options"
            label="name"
            track-by="name"
            :can-deselect="canDeselect"
            :can-clear="true"
            :hide-selected="false"
            :preserve-search="true"
            :clear-on-select="false"
            :placeholder="$t(fieldType)"
            :object="true"
            mode="multiple"
            :close-on-select="false"
            @clear="$emit('clear', { dbField: dbFieldToClear, multi: true })"
            @close="$emit('selected', field)"
        >
            <template #multiplelabel="{ values, isOpen }"
                ><span
                    class="multiple-label"
                    v-if="values.length"
                    v-show="!isOpen"
                    >{{
                        `${values.length} ${$tc(elementType, values.length)} `
                    }}</span
                ></template
            >
            <template #option="{ option }">
                <div class="select-option-icon-wrap" :style="option.style">
                    <fa-icon
                        :title="option.name"
                        :icon="option.icon"
                        class="fa-xl"
                    ></fa-icon>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: 'IconSelectMultiple',
    components: { Multiselect },
    data() {
        return {
            field: this.selected,
            elementType: `ms_${this.elemType}`,
            dbFieldToClear: '',
        }
    },
    computed: {
        options() {
            const formattedOptions = []
            this.sortableFields.forEach((option) => {
                formattedOptions.push({
                    name: this.$t(option.name),
                    value: option.value,
                    dbField: option.dbField,
                    icon: option.icon,
                    style: option.style,
                })
            })
            this.dbFieldToClear =
                formattedOptions.length > 0 ? formattedOptions[0].dbField : ''
            return formattedOptions
        },
    },
    methods: {},
    props: {
        withLabel: {
            type: Boolean,
            default: true,
        },

        fieldData: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Array,
            default: () => [],
        },
        sortableFields: {
            type: Object,
            default: () => {},
        },
        searchedItems: {
            type: Array,
            default: () => [],
        },
        storeToSort: {
            type: String,
            default: '',
        },
        elemType: {
            type: String,
            default: 'option',
        },
        canDeselect: {
            type: Boolean,
            default: true,
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        preselectFirst: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        fieldType: {
            type: String,
            default: 'no_filter',
        },
    },
}
</script>
<style lang="scss" scoped>
#sort-select-multi-icon ::v-deep(.multiselect) {
    font-size: x-small !important;
    height: auto;

    width: 6rem;
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }
    .multiple-label {
        font-size: x-small !important;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }
    .multiselect-clear {
        padding-right: 0.2rem;
    }
    .multiple-label {
        @apply mr-1 #{!important};
    }
}
</style>
