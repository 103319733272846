<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <filtrable-table
                    id="recurrentsList"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="recurrents"
                    :columns="columns"
                    :storeToSort="'recurrent'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="multiActions"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    :hoverableItems="false"
                    :itemActions="itemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="canAddNew()"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @updateStepDriver="updateStepsDriver($event)"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @update="goToUpdateOne($event)"
                    @deactivate="deactivateOne($event)"
                    @deactivateAll="deactivateAll($event)"
                    @duplicate="duplicateOne($event)"
                    @activate="activateOne($event)"
                    @activateAll="activateAll($event)"
                    @updateOrderGroup="openOrderGroupModal($event)"
                >
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
                <order-group-modal
                    v-if="showOrderGroupModal"
                    :form="groupForm"
                    :selectedPricing="selectedTourPricing"
                    :tourPricingOptions="orderGroupOptions"
                    @closeModal="openOrderGroupModal()"
                    @updateFromModal="updateGroup($event)"
                >
                </order-group-modal>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import OrderGroupModal from '@/components/elements/OrderGroupModal.vue'
import filterList from '@/mixins/filterList'
import orderGroupsMixin from '@/mixins/orderGroupsMixin'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useRecurrentOrderStore } from '@/stores/recurrentOrderStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RecurrentOrders',
    mixins: [filterList, orderGroupsMixin],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        OrderGroupModal,
    },
    data() {
        return {
            showOrderGroupModal: false,
            refreshMS: 0,
            driverOptions: [],
            dateFilter: [],
            resetCheckedItems: false,
            pickupTodayOnly: true,
            deliveryTodayOnly: true,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            recurrentsInterval: null,
            sortableFields: [
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('name'), value: 'Name' },
            ],
        }
    },
    watch: {
        // drivers() {
        //     if (this.drivers !== null) {
        //         // clearInterval(driversInterval)
        //         this.drivers.forEach((driver) => {
        //             const name = `${driver.firstname} ${driver.lastname}`
        //             const option = {
        //                 name,
        //                 value: +driver.id,
        //                 dbField: 'Recurrent_OrderStep.IdDriver',
        //             }
        //             this.driverOptions.push(option)
        //         })
        //     }
        // },
    },

    created() {
        clearInterval(this.recurrentsInterval)
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        const filteredList = this.$cookies.get('recurrentFiltered')

        this.orderGroupOptions = []
        if (this.userStore.isB2C === false) {
            this.fetchRecurrentGroups().then((orderGroupOptions) => {
                if (orderGroupOptions.status === 200) {
                    orderGroupOptions.data.Records.forEach((group) => {
                        const option = {
                            value: +group.Id,
                            name: group.Name,
                            dbField: 'RecurrentTour_Recurrent.IdRecurrentTour',
                        }
                        this.orderGroupOptions.push(option)
                    })
                }
            })
        }
        window.scrollTo(0, 0)
        this.filter = []

        if (filteredList) {
            // this.search.push(this.$cookies.get('query'))
            this.search = filteredList.query
            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page

            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.setRealSearch(this.search)
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
            this.setRealSelected('Active', 0)
        }

        const payload = {
            query: [],
            filter: [],
            perPage: 10000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.searchContainers(payload)

        // this.selectedUniverses = structuredClone(this.userStore.userUniverses)
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                if (!this.filter.find((f) => f.dbField === 'IdUniverse')) {
                    const universeFilter = {
                        dbField: 'IdUniverse',
                        value:
                            this.selectedUniverses.length > 0
                                ? this.selectedUniverses
                                : this.universesOptions,
                    }
                    this.filter.push(universeFilter)
                }

                const uniFilter = this.filter.find(
                    (filt) => filt.dbField === 'IdUniverse'
                )
                const universes = []
                uniFilter.value.forEach((uni) => {
                    universes.push(uni.value)
                })
                const drPayload = {
                    query: [],
                    filter: [
                        {
                            dbField: 'Active',
                            value: 1,
                        },
                        {
                            dbField: 'UserUniverse.IdUniverse',
                            value: universes,
                        },
                    ],
                    perPage: 10000,
                    page: 1,
                    sortField: 'LastName',
                    sortDirection: 'ASC',
                }
                this.driverOptions = []

                this.searchDrivers(drPayload).then((resDrivers) => {
                    resDrivers.data.Records.forEach((driver) => {
                        const name = `${driver.FirstName} ${driver.LastName}`
                        const option = {
                            name,
                            value: +driver.Id,
                            dbField: 'Recurrent_OrderStep.IdDriver',
                        }
                        this.driverOptions.push(option)
                    })
                })
                this.searchRecurrentOrders({
                    query: this.search,
                    filter: this.filter,
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                }).then(() => {
                    clearInterval(this.recurrentsInterval)

                    this.recurrentsInterval = setInterval(() => {
                        this.searchRecurrentOrders({
                            query: this.search,
                            filter: this.filter,
                            sortDirection: this.sortDirection,
                            sortField: this.sortField,
                            perPage: this.perPage,
                            page: this.page,
                        })
                    }, 20000)
                })
            }
        }, 10)
        // if (this.$cookies.get('currentDeliveryId'))
        //     this.$cookies.remove('currentDeliveryId')
    },

    unmounted() {
        clearInterval(this.recurrentsInterval)
        this.eventBus.all.clear()
    },

    computed: {
        recurrentActionAccess() {
            return actionsAccess.orders.recurrents
        },
        columns() {
            return [
                {
                    name: 'active',
                    filtrable: true,
                    dbField: 'Active',
                    type: 'select',
                    sortable: false,
                    withIconNoText: true,

                    options: [
                        {
                            name: 'active',
                            value: 1,
                            dbField: 'Active',
                        },
                        {
                            name: 'deactivated',
                            value: 0,
                            dbField: 'Active',
                        },
                    ],
                },
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'Name',
                    isLink: checkAccess(
                        this.userStore,
                        this.recurrentActionAccess.update
                    ),
                    routeName: 'update_order',
                    type: 'search',
                    sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'client',
                    filtrable: true,
                    dbField: 'Client.Name',

                    type: 'search',
                    sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: true,
                },
                {
                    name: 'orderGroup',
                    filtrable: true,
                    type: 'select',
                    dbField: 'RecurrentTour_Recurrent.IdRecurrentTour',
                    width: 'w-20',
                    sortable: false,
                    hasAccess: actionsAccess.orders.groups.update,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    // selected: ,
                    options: this.orderGroupOptions,
                },
                {
                    name: 'drivers',
                    filtrable: true,
                    sortable: false,
                    drivers: true,
                    recurrent: true,

                    canSearch: true,
                    type: 'select',
                    dbField: 'Recurrent_OrderStep.IdDriver',
                    options: this.driverOptions,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.recurrentActionAccess.update
                    ),
                    // type: 'search',
                    // dbField: 'OrderSteps.Driver.LastName',
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'recurrence_end',
                    filtrable: false,
                    // dbField: 'DateEnd',
                    // type: 'search',
                    // sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                // {
                //     name: 'days',
                //     filtrable: false,
                //     dbField: 'Repetition',
                //     // type: 'search',
                //     // sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C
                //         : this.$cookies.get('is_B2C') === 'true',
                // },
                // {
                //     name: 'occ_count_to_do',
                //     filtrable: false,
                //     dbField: 'OccurrenceNb',
                //     // type: 'search',
                //     // sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C
                //         : this.$cookies.get('is_B2C') === 'true',
                // },
                // {
                //     name: 'occ_count_done',
                //     filtrable: false,
                //     dbField: 'OccurrenceCount',
                //     // type: 'search',
                //     // sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C
                //         : this.$cookies.get('is_B2C') === 'true',
                // },
                {
                    name: 'additional_info',
                    recurrent: true,
                    filtrable: false,
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },

                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                    hide: false,
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.recurrentActionAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.recurrentActionAccess.create)
            ) {
                actions.push({
                    name: 'duplicate',
                    action: 'duplicate',
                    icon: 'copy',
                })
            }
            if (
                checkAccess(this.userStore, this.recurrentActionAccess.activate)
            ) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                })
            }
            return actions
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.recurrentActionAccess.activate)
            ) {
                return [
                    {
                        name: 'deactivate_selection',
                        action: 'deactivateAll',
                        icon: 'times',
                    },
                    {
                        name: 'activate_selection',
                        action: 'activateAll',
                        icon: 'check',
                    },
                ]
            }
            return []
        },

        ...mapState(useRecurrentOrderStore, {
            recurrents: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
            currentRecurrent: 'current',
            fetchRecurrentGroups: 'fetchRecurrentGroups',
            updateRecurrentGroup: 'updateRecurrentGroup',
        }),
        ...mapStores(useUserStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        async updateGroup(groupForm) {
            this.updateRecurrentGroup(groupForm)
                .then((res) => {
                    this.$toast.success(this.$t('update_ok'))
                    this.openOrderGroupModal()

                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('error_occured'))
                })
        },
        searchCustomUniverses(clear = false) {
            clearInterval(this.recurrentsInterval)
            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.recurrentsInterval)

            this.searchCustomUniverses()
        },
        duplicateOne(id) {
            clearInterval(this.recurrentsInterval)

            this.$emit('duplicateRecurrent', id)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.recurrentActionAccess.create
            )
        },
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
        ]),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useRecurrentOrderStore, [
            'searchRecurrentOrders',
            'deactivateRecurrent',
            'updateRecurrentOrder',
            'duplicateRecurrentOrder',
            'updateRecurrentStep',
            'fetchOneRecurrent',
            'updateRecurrentGroup',
        ]),
        // changeSearch() {
        //     this.searchByName = !this.searchByName
        //     const searchFilters = JSON.parse(JSON.stringify(this.search))
        //     searchFilters.forEach((filter, index) => {
        //         if (filter.dbField === 'Client.Name') {
        //             this.search[index].dbField = 'OrderClient.Reference'
        //         } else if (filter.dbField === 'OrderClient.Reference') {
        //             this.search[index].dbField = 'Client.Name'
        //         }
        //     })
        //     this.setResults()
        // },
        goToUpdateOne(id) {
            clearInterval(this.recurrentsInterval)

            this.$emit('goToUpdateRecurrent', id)
        },

        upFilter(event) {
            clearInterval(this.recurrentsInterval)
            this.updateFilter(event)
        },

        async deactivateOne(id) {
            this.searchingCursor = true

            const response = await this.deactivateRecurrent(id)
            if (response.status === 200) {
                this.setResults()
                this.searchingCursor = false

                this.$toast.success(this.$t('recurrent_deactivate_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('recurrent_deactivate_ko'))
            }
        },
        deactivateAll(elementsToDeactivate) {
            this.searchingCursor = true

            elementsToDeactivate.forEach((el, index) => {
                this.deactivateRecurrent(el).then((res) => {
                    if (index === elementsToDeactivate.length - 1) {
                        this.setResults()
                        this.searchingCursor = false
                    }
                })
                // if (index === elementsToDeactivate.length - 1) {
                //     this.setResults()
                //     this.searchingCursor = false
                // }
            })
        },
        activateAll(elementsToActivate) {
            this.searchingCursor = true

            elementsToActivate.forEach((el, index) => {
                this.activateOne(el, index === elementsToActivate.length - 1)
                // if (index === elementsToActivate.length - 1) {
                //     this.setResults()
                //     this.searchingCursor = false
                // }
            })
        },
        activateOne(id, last = true) {
            this.searchingCursor = true

            this.fetchOneRecurrent(id).then((res) => {
                const recurrentInterval = setInterval(() => {
                    if (this.currentRecurrent !== null) {
                        clearInterval(recurrentInterval)

                        const form = {
                            id,
                            recurrentForm: {
                                Id: res.data.Id,
                                IdExternal: res.data.IdExternal,
                                IdClient: res.data.IdClient,
                                IdFacturationClient:
                                    res.data.IdFacturationClient,
                                IdUserContact: +res.data.IdUserContact,
                                Name: res.data.Name,
                                Method: res.data.Method,
                                CommentInternal: res.data.CommentInternal,
                                Repetition: res.data.Repetition,
                                OccurrenceNb: res.data.OccurrenceNb,
                                IdUniverse: res.data.IdUniverse,
                                OccurrenceFrequency:
                                    res.data.OccurrenceFrequency,
                                RepetitionFrequency:
                                    res.data.RepetitionFrequency,
                                DateEndRecurrent: res.data.DateEndRecurrent,
                                DateStartRecurrent: res.data.DateStartRecurrent,
                                Active: 1,
                                CreationUserId: res.data.CreationUserId,
                                ModificationUserId: this.userStore.current.id,
                                OrderPackages: [res.data.OrderPackages[0]],
                                OrderSteps: res.data.OrderSteps,
                            },
                        }
                        this.updateRecurrentOrder(form)
                            .then((response) => {
                                if (last === true) {
                                    if (response.status === 200) {
                                        this.setResults()
                                        this.searchingCursor = false

                                        this.$toast.success(
                                            this.$t('recurrent_activate_ok')
                                        )
                                    } else {
                                        this.searchingCursor = false

                                        this.$toast.error(
                                            this.$t('recurrent_activate_ko')
                                        )
                                    }
                                }
                            })
                            .catch((err) => {
                                if (last === true) {
                                    this.searchingCursor = false

                                    this.$toast.error(
                                        this.$t('recurrent_activate_ko')
                                    )
                                }
                            })
                    }
                }, 20)
            })
        },
        updateStepsDriver(infos) {
            this.searchingCursor = true
            clearInterval(this.recurrentsInterval)
            infos.steps.forEach((step, index) => {
                step.IdDriver = infos.newDriver
                const payload = {
                    id: step.Id,
                    form: step,
                }
                this.updateRecurrentStep(payload)
                    .then((res) => {
                        if (index === infos.steps.length - 1) {
                            this.setResults()
                        }
                    })
                    .catch((err) => {
                        this.searchingCursor = false
                        this.$toast.error(this.$t('error_occured'))
                    })
            })
        },
        setPage(isNext) {
            clearInterval(this.recurrentsInterval)
            this.$cookies.remove('recurrentFiltered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.recurrentsInterval)
            this.$cookies.remove('recurrentFiltered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            // const query = search.query

            this.$cookies.set('recurrentFiltered', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''

            this.searchRecurrentOrders(search)
            this.searchingCursor = false
            clearInterval(this.recurrentsInterval)
            this.recurrentsInterval = setInterval(() => {
                this.searchRecurrentOrders(search)
            }, 20000)

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.recurrentsInterval)

            this.$cookies.remove('recurrentFiltered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.recurrentsInterval)

            const search = {
                query: this.search,
                clientId:
                    this.userStore.isB2C === true
                        ? this.userStore.current.idClient
                        : '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            if (this.userStore.isB2C === false) {
                search.deliveryTodayOnly = this.deliveryTodayOnly
                search.pickupTodayOnly = this.pickupTodayOnly
                search.dateFilter = this.dateFilter
            }
            const uniFilter = this.filter.find(
                (filt) => filt.dbField === 'IdUniverse'
            )
            const universes = []
            uniFilter.value.forEach((uni) => {
                universes.push(uni.value)
            })
            const drPayload = {
                query: [],
                filter: [
                    {
                        dbField: 'Active',
                        value: 1,
                    },
                    {
                        dbField: 'UserUniverse.IdUniverse',
                        value: universes,
                    },
                ],
                perPage: 10000,
                page: 1,
                sortField: 'LastName',
                sortDirection: 'ASC',
            }
            this.driverOptions = []
            this.searchDrivers(drPayload).then((resDrivers) => {
                resDrivers.data.Records.forEach((driver) => {
                    const name = `${driver.FirstName} ${driver.LastName}`
                    const option = {
                        name,
                        value: +driver.Id,
                        dbField: 'Recurrent_OrderStep.IdDriver',
                    }
                    this.driverOptions.push(option)
                })
            })
            this.displaySearchResults(search)
        },
    },
}
</script>
