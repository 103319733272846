<template>
    <div>
        <!-- COMMEnce ICI pour le formulaire avec des options-->
        <!--TOUJOURS LE MS ET PLUS LES BOUTONS-->

        <span>
            <div v-if="pack.Reference === undefined && pack.Reference === null">
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-if="pack.packageTypeErrors.length > 0"
                >
                    <div class="error-msg">
                        {{ $t('no_package_type_error') }}
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-4 gap-1">
                <div class="items-center col-span-2">
                    <Multiselect
                        :key="refreshMS"
                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                        v-model="pickedContainerId"
                        :options="containerOptions"
                        :loading="containerOptions.length === 0"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :placeholder="$t('select_package_format')"
                        :allow-empty="false"
                        :can-clear="false"
                        @select="packsLength === 0 ? addPackage() : ''"
                    ></Multiselect>
                    <div class="text-xs italic" v-if="pack">
                        {{
                            `${pack.Length}cm x ${pack.Width}cm x ${pack.Height}cm`
                        }}
                    </div>
                </div>
                <div class="flex mb-4 items-center col-span-2">
                    <Multiselect
                        :key="refreshMS"
                        id="sort-select"
                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                        v-model="selectedTemp"
                        :options="temperatureOptions"
                        label="name"
                        track-by="name"
                        :searchable="false"
                        :object="true"
                        :can-clear="false"
                        :can-deselect="false"
                        :placeholder="$t('temperature')"
                    >
                        <template #singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <div
                                    class="select-label-icon-wrap whitespace-nowrap overflow-y-auto"
                                >
                                    <fa-layer
                                        v-if="value.noTemp"
                                        class="fa-md mr-2"
                                    >
                                        <fa-icon
                                            icon="ban"
                                            style="color: grey"
                                        ></fa-icon>
                                        <fa-icon
                                            :icon="value.icon"
                                            transform="shrink-6"
                                        ></fa-icon>
                                    </fa-layer>
                                    <span
                                        v-else
                                        class="mr-2"
                                        :style="`${value.color}`"
                                    >
                                        <fa-icon
                                            :icon="value.icon"
                                            class=""
                                        ></fa-icon> </span
                                    ><span class="text-sm"
                                        >{{ $t(value.name) }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #option="{ option }">
                            <div class="select-option-icon-wrap">
                                <fa-layer
                                    v-if="option.noTemp"
                                    class="fa-xl mr-2"
                                >
                                    <fa-icon
                                        icon="ban"
                                        style="color: grey"
                                    ></fa-icon>
                                    <fa-icon
                                        :icon="option.icon"
                                        transform="shrink-6"
                                    ></fa-icon>
                                </fa-layer>
                                <span
                                    v-else
                                    class="mr-2"
                                    :style="`${option.color}`"
                                >
                                    <fa-icon
                                        :icon="option.icon"
                                        class=""
                                    ></fa-icon> </span
                                >{{ $t(option.name) }}
                            </div>
                        </template></Multiselect
                    >
                </div>
            </div>
            <div v-if="packsLength > 0">
                <div
                    class="grid gap-2 mt-2"
                    :class="
                        currentContainer && +currentContainer.Height === 0
                            ? 'grid-cols-2'
                            : 'grid-cols-3'
                    "
                >
                    <div
                        v-if="
                            currentContainer && +currentContainer.Height === 0
                        "
                        class="col-span-1 items-center"
                    >
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('height') + ' *' }}</label
                        >
                        <input
                            type="number"
                            step="0.01"
                            min="0.01"
                            v-model="packageHeight"
                            class="border rounded px-3 py-2 mt-1 w-28"
                            :class="
                                pack.packageHeightErrors.length > 0
                                    ? 'border-red-600 text-red-600'
                                    : ''
                            "
                        />
                    </div>
                    <div class="col-span-1 items-center">
                        <label
                            class="font-semibold whitespace-nowrap basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('unit_weight') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="0.01"
                            step="0.01"
                            v-model="pack.Weight"
                            @blur="$emit('setPack', { index: packIndex, pack })"
                            class="border rounded px-3 py-2 mt-1 w-28"
                            :class="
                                pack.packageWeightErrors.length > 0
                                    ? 'border-red-600 text-red-600'
                                    : ''
                            "
                        />
                    </div>
                    <div class="col-span-1 items-center">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('quantity') + ' *' }}</label
                        >
                        <input
                            type="number"
                            min="1"
                            v-model="packageQuantity"
                            class="border rounded px-3 py-2 mt-1 w-28"
                        />
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-4"
                        v-if="pack.packageQuantityErrors.length > 0"
                    >
                        <div class="error-msg">
                            {{ $t('value_required') }}
                        </div>
                    </div>
                    <div class="col-span-1 items-center">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                            >{{ $t('worth') }}</label
                        >
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            v-model="pack.Value"
                            class="border rounded px-3 py-2 mt-1 w-28"
                            @blur="$emit('setPack', { index: packIndex, pack })"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2 mt-3">
                    <div class="col-span-1 items-center">
                        <label class="font-semibold text-gray-600 pb-1 mr-2">{{
                            $t('seal_number')
                        }}</label>
                        <input
                            type="text"
                            v-model="pack.PlombNumber"
                            class="border rounded px-3 py-2 mt-1"
                            @blur="$emit('setPack', { index: packIndex, pack })"
                        />
                    </div>

                    <div class="col-span-1 items-center">
                        <label class="font-semibold text-gray-600 pb-1 mr-2">{{
                            $t('external_reference')
                        }}</label>
                        <input
                            type="text"
                            v-model="pack.ReferenceExternal"
                            class="border rounded px-3 py-2 mt-1"
                            @blur="$emit('setPack', { index: packIndex, pack })"
                        />
                    </div>
                </div>
            </div>

            <div class="grid gap-1 grid-cols-6">
                <span
                    v-if="packIndex === packsLength - 1"
                    class="col-span-2 flex justify-center"
                    :class="
                        createMultipacks && packsLength === 1
                            ? 'col-start-3'
                            : createMultipacks
                              ? ''
                              : 'col-start-5'
                    "
                >
                    <button
                        @click.stop="$emit('newContainer', packIndex)"
                        class="themed-button items-center my-3 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                    >
                        <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-xs rounded text-gray-500" -->
                        <span class="inline-block ml-1 font-bold">{{
                            $t('create_container')
                        }}</span>
                    </button>
                </span>
                <span
                    v-if="createMultipacks && packIndex === packsLength - 1"
                    class="col-span-2 flex justify-center"
                >
                    <button
                        @click.stop="addPackage()"
                        class="themed-button items-center my-3 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                    >
                        <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-xs rounded text-gray-500" -->
                        <span class="inline-block ml-1 font-bold">{{
                            $t('add_different_package_type')
                        }}</span>
                    </button>
                </span>
                <span
                    v-if="deleteMultipacks && packsLength > 1"
                    :class="
                        packIndex !== packsLength - 1
                            ? 'col-start-5 col-span-2 flex justify-center'
                            : 'col-span-2 flex justify-center'
                    "
                >
                    <button
                        @click.stop="$emit('removePackage', packIndex)"
                        class="bg-red-500 items-center my-3 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                    >
                        <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-xs rounded text-gray-500" -->
                        <fa-icon icon="trash-can" class="mr-2"></fa-icon>
                        <span class="inline-block ml-1 font-bold">{{
                            $t('remove_package')
                        }}</span>
                    </button>
                </span>
            </div>
            <hr v-if="packIndex !== packsLength - 1" class="mb-2" />
        </span>
    </div>
</template>
<script>
import copyToClipboard from '@/mixins/copyToClipboard.js'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapState, mapStores, mapWritableState } from 'pinia'

export default {
    name: 'PackageForm',
    components: {
        Multiselect,
    },
    mixins: [copyToClipboard],

    data() {
        return {
            pack: structuredClone(this.packToEdit),
            // packageTypeErrors: [],
            // packageReferenceErrors: [],
            // packageValueErrors: [],
            // packageWeightErrors: [],
            // packageHeightErrors: [],
            // packageWidthErrors: [],
            // packageLengthErrors: [],
            // packageQuantityErrors: [],
            packageHeight: 0,
            pickedContainerId: '',
            packageQuantity: 1,
            // containerForAll: false,
            // containerCreation: false,
            // containerNameError: false,
            refreshMS: this.globalRefresh,
            isDefault: false,
            containerInterval: null,
            containerName: '',
            isEditPackage: false,
            temperatureOptions: [
                {
                    name: 'no_temp_specified',
                    value: 'none',
                    icon: 'temperature-half',
                    noTemp: true,
                },
                {
                    name: 'cool',
                    value: 'fresh',
                    icon: 'temperature-low',
                    color: 'color:dodgerblue;',
                },
                {
                    name: 'frozen',
                    value: 'frozen',
                    icon: 'temperature-arrow-down',
                    color: 'color:cyan;',
                },
                {
                    name: 'hot',
                    value: 'warm',
                    icon: 'temperature-high',
                    color: 'color:firebrick;',
                },
            ],
            selectedTemp: {
                name: 'no_temp_specified',
                value: 'none',
                icon: 'temperature-half',
                noTemp: true,
            },
        }
    },
    props: {
        packToEdit: {
            type: Object,
            required: true,
        },
        containerOptions: {
            type: Array,
            required: true,
        },
        packIndex: {
            type: Number,
            required: true,
        },
        idClient: {
            type: Number,
            required: true,
        },
        packsLength: {
            type: Number,
            required: true,
        },
        isDuplicate: {
            type: Boolean,
            required: false,
        },
        globalRefresh: {
            type: Number,
            required: true,
        },
        idContDefault: {
            type: Number,
            required: true,
        },
        isUpdate: {
            type: Boolean,
            required: true,
        },
        // checkPackageForm: {
        //     type: Boolean,
        //     required: true,
        // },
    },
    computed: {
        ...mapStores(useClientStore, useUserStore),
        ...mapWritableState(useClientStore, {
            currentContainer: 'currentContainer',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        createMultipacks() {
            return checkAccess(
                this.userStore,
                actionsAccess.orders.createMultipacks,
            )
        },
        deleteMultipacks() {
            return checkAccess(
                this.userStore,
                actionsAccess.orders.deleteMultipacks,
            )
        },
    },
    mounted() {
        let idToUse = this.idContDefault
        this.pack = structuredClone(this.packToEdit)
        // this.containerInterval = setInterval(() => {
        //     if (idToUse !== null) {
        //         clearInterval(this.containerInterval)
        if (this.isDuplicate === false && +idToUse > 0) {
            this.clientStore.fetchOneContainer(idToUse).then((res) => {
                if (res.data !== null) {
                    this.containerName = res.data.Name
                    if (!this.$route.params.id) {
                        this.pickedContainerId = idToUse
                        this.setPackageContainer(res.data)
                    }
                    this.refreshMS += 1
                } else {
                    this.containerName = ''
                }
            })
        }
        //     }
        // }, 100)
        if (this.$route.params.id) {
            this.isEditPackage = true
        }

        this.pickedContainerId = this.pack.IdContainer

        idToUse = this.pack.IdContainer

        this.packageHeight = +this.pack.Height

        this.packageQuantity = this.pack.Quantity
        let temp = {
            name: 'no_temp_specified',
            value: 'none',
            icon: 'temperature-half',
            noTemp: true,
        }
        if (this.pack.TemperatureOption) {
            if (this.pack.TemperatureOption === 'fresh') {
                temp = {
                    name: 'cool',
                    value: 'fresh',
                    icon: 'temperature-low',
                    color: 'color:dodgerblue;',
                }
            } else if (this.pack.TemperatureOption === 'frozen') {
                temp = {
                    name: 'frozen',
                    value: 'frozen',
                    icon: 'temperature-arrow-down',
                    color: 'color:cyan;',
                }
            } else if (this.pack.TemperatureOption === 'warm') {
                temp = {
                    name: 'hot',
                    value: 'warm',
                    icon: 'temperature-high',
                    color: 'color:firebrick;',
                }
            }
        }
        this.pack.Length = +this.pack.Length
        this.pack.Width = +this.pack.Width
        this.pack.Weight = +this.pack.Weight
        this.pack.Value = !Number.isNaN(+this.pack.Value) ? +this.pack.Value : 0
        this.selectedTemp = temp

        this.refreshMS += 1
    },
    watch: {
        selectedTemp() {
            this.pack.TemperatureOption = this.selectedTemp.value
            this.$emit('setPack', { index: this.packIndex, pack: this.pack })
            this.refreshMS += 1
        },
        packageQuantity() {
            this.pack.Quantity = this.packageQuantity
            this.$emit('setPack', { index: this.packIndex, pack: this.pack })
            this.refreshMS += 1
        },
        packageHeight() {
            this.pack.Height = this.packageHeight
            this.$emit('setPack', { index: this.packIndex, pack: this.pack })
            this.refreshMS += 1
        },

        pickedContainerId(oldVal, newVal) {
            if (
                this.pickedContainerId &&
                this.pickedContainerId !== '' &&
                +this.pickedContainerId !== 0 &&
                +newVal !== +oldVal
            ) {
                this.clientStore
                    .fetchOneContainer(this.pickedContainerId)
                    .then((res) => {
                        if (res.data) {
                            this.containerName = res.data.Name

                            this.setPackageContainer(res.data)
                            // this.refreshMS += 1
                        } else {
                            this.containerName = ''
                        }
                        this.refreshMS += 1
                    })
            } else {
                this.containerName = ''
            }
        },
    },

    methods: {
        addPackage() {
            this.$emit('addPackage')
        },
        setPackageContainer(container) {
            let weight = +container.Weight
            let id = container.Id
            let length = +container.Length
            let width = +container.Width
            let value = !Number.isNaN(+container.Value) ? +container.Value : 0
            if (+container.Height !== 0) {
                this.packageHeight = +container.Height ?? 0
            }
            if (
                this.isEditPackage === false &&
                this.packToEdit &&
                +this.packToEdit.IdContainer !== +container.Id
            ) {
                this.packageHeight = +container.Height
                id = container.Id
                length = +container.Length
                width = +container.Width
            }
            if (
                this.isEditPackage === true &&
                this.packToEdit &&
                +this.packToEdit.IdContainer === +container.Id
            ) {
                this.packageHeight = +this.packToEdit.Height
                value = !Number.isNaN(+this.packToEdit.Value)
                    ? +this.packToEdit.Value
                    : 0
                weight = +this.packToEdit.Weight
            }

            this.pack.IdContainer = id
            this.pack.Width = width
            this.pack.Length = length
            this.pack.Weight = weight
            this.pack.Value = value
            this.pack.Name = this.containerName
            this.pack.Quantity = this.packageQuantity
            // this.pickedContainerId = id
            this.pack.Height = this.packageHeight
            this.isEditPackage = false
            let temp = {
                name: 'no_temp_specified',
                value: 'none',
                icon: 'temperature-half',
                noTemp: true,
            }
            if (this.pack.TemperatureOption) {
                if (this.pack.TemperatureOption === 'fresh') {
                    temp = {
                        name: 'cool',
                        value: 'fresh',
                        icon: 'temperature-low',
                        color: 'color:dodgerblue;',
                    }
                } else if (this.pack.TemperatureOption === 'frozen') {
                    temp = {
                        name: 'frozen',
                        value: 'frozen',
                        icon: 'temperature-arrow-down',
                        color: 'color:cyan;',
                    }
                } else if (this.pack.TemperatureOption === 'warm') {
                    temp = {
                        name: 'hot',
                        value: 'warm',
                        icon: 'temperature-high',
                        color: 'color:firebrick;',
                    }
                }
            }
            this.selectedTemp = temp
            this.$emit('setPack', { index: this.packIndex, pack: this.pack })
            this.refreshMS += 1
        },
    },
}
</script>
