export default {
    data() {
        return {
            viewMenu: null,
            top: '0px',
            left: '0px',
        }
    },
    methods: {
        closeMenu(itemId) {
            this.viewMenu = null
        },

        openMenu(e, routeName, itemId) {
            if (routeName) {
                this.viewMenu = `right-click-menu-${itemId}`

                e.preventDefault()
            }
        },
        goToNewTab(itemId, itemRouteName) {
            const routeData = this.$router.resolve({
                name: itemRouteName,
                params: { id: itemId },
            })
            window.open(routeData.href, '_blank')
        },
    },
}
