const keys = {
    becycle: 'AIzaSyDIF9MjPETCxmrFe86TygiDmCHd3e-_KaA',
    izzytown: 'AIzaSyDIF9MjPETCxmrFe86TygiDmCHd3e-_KaA',

    localhost: 'AIzaSyAGC9Senb1D0bN3Mbsi0V-pLO46lgx6v60',
    dev: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM',
    devcec: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM',
    client: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM',

    devfred: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM',
    preprod: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM', // TODO changer ?
    demo01: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM', // TODO changer ?

    solutrans: 'AIzaSyCtp0SD0MnEJLx3iUQpPUiLSpdzDy6NAqM', // TODO changer ?

    annecy: 'AIzaSyBdyYpAtvuphaYYax8DH7WVRnUJrUvufnQ',
    lium: 'AIzaSyCfzxDd8GJxRA_eTYxPE9jUsrA6wA51_lk',
    bft: 'AIzaSyBAu5AlmKyC_l9_hr-mHeJ0DzTkrO_cVHk',

    beaujolyon: 'AIzaSyCfzxDd8GJxRA_eTYxPE9jUsrA6wA51_lk',
    rushlogistic: 'AIzaSyBAu5AlmKyC_l9_hr-mHeJ0DzTkrO_cVHk',
}

export default keys
